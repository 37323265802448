// import React, { useState, useEffect, useRef } from "react";
// import { API, graphqlOperation } from "aws-amplify";
// import { listCandidateProfiles } from "../../graphql/queries";
// import { useNavigate } from "react-router-dom";
// import Header from "../../components/header/Header";

// const InterviewSlotBook = () => {
//   const [candidateProfile, setCandidateProfile] = useState([]);
//   const [nextToken, setNextToken] = useState(null);
//   const navigate = useNavigate();
//   const containerRef = useRef(null);

//   useEffect(() => {
//     fetchInterviewSlots();
//   }, []);

//   const fetchInterviewSlots = async () => {
//     try {
//       const CandidateInfo = await API.graphql(
//         graphqlOperation(listCandidateProfiles, {
//           limit: 100, // Fetch 10 items at a time
//           nextToken: nextToken,
//         })
//       );
//       const { items, nextToken: newToken } =
//         CandidateInfo.data.listCandidateProfiles;
//       setCandidateProfile((prevProfile) => [...prevProfile, ...items]);
//       setNextToken(newToken);
//     } catch (error) {
//       console.error("Error fetching interview slots: ", error);
//     }
//   };

//   const handleLoadMore = () => {
//     fetchInterviewSlots();
//   };

//   const handleScroll = () => {
//     if (containerRef.current) {
//       const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
//       if (scrollHeight - scrollTop === clientHeight) {
//         handleLoadMore();
//       }
//     }
//   };



//   return (
//     <div className="container-fluid" onScroll={handleScroll} ref={containerRef}>
//       <div className="row">
//         <div className="col py-3">
//           <Header />
//           <div className="flex justify-between gap-64 mt-8">
//             <h2 className="text-2xl font-semibold mb-4 text-center">
//               Candidate Information
//             </h2>
//             {/* <button
//               onClick={() => navigate("/CandidateInterviewSlotList")}
//               className="text-1xl font-semibold mb-4 border-2 border-red-500 px-2 py-1"
//             >
//               Candidate Interview
//             </button> */}
//           </div>

//           <div className="overflow-x-auto overflow-y-auto">
//             <table className="table-auto w-full border-collapse">
//               <thead>
//                 <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
//                   <th className="py-3 px-6 text-left">Candidate Full Name</th>
//                   <th className="py-3 px-6 text-left">Candidate Email</th>
//                   <th className="py-3 px-6 text-left">Candiadte Resume Url</th>
//                 </tr>
//               </thead>
//               <tbody className="text-gray-600 text-sm font-light">
//                 {candidateProfile.map((candidate) => (
//                   <tr
//                     key={candidate.id}
//                     className="border-b border-gray-200 hover:bg-blue-800"
//                   >
//                     <td
//                       className="py-3 px-6 text-left whitespace-nowrap"
//                       style={{ color: "#fff" }}
//                     >
//                       {candidate.fullName}
//                     </td>
//                     <td
//                       className="py-3 px-6 text-left"
//                       style={{ color: "#fff" }}
//                     >
//                       {candidate.email}
//                     </td>
//                     <td className="py-3 px-6 text-left text-white">
//                       <a
//                         href={candidate.resumeURL}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                       >
//                         {candidate.resumeURL}
//                       </a>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           {nextToken && (
//             <div className="text-center mt-4">
//               <button
//                 onClick={handleLoadMore}
//                 className="text-1xl font-semibold mb-4 border-2 border-red-500 px-2 py-1"
//               >
//                 Load More
//               </button>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default InterviewSlotBook;


// original code above 

// new code start

import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listCandidateProfiles, listInterviewEvaluations } from "../../graphql/queries";
import Header from "../../components/header/Header";
import profile from "../../assets/profile.png"
import { FaCopy } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InterviewSlotBook = () => {
  const [candidateProfile, setCandidateProfile] = useState(null);
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  // send mail original code start
  // const [emailSent, setEmailSent] = useState(false);
  // const [captchaCode, setCaptchaCode] = useState('');
  // const [enteredCode, setEnteredCode] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  //  send mail original code end


  // send mail test1 code start
  const [emailType, setEmailType] = useState(''); // 'rejection' or 'selected'
  const [emailSent, setEmailSent] = useState(false);
  const [captchaCode, setCaptchaCode] = useState('');
  const [enteredCode, setEnteredCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading,setLoading]=useState(false)
  const [rejectEmail,setRejectEmail]=useState(false)
  const [sendUpdate,setSendUpdate]=useState(false)

  //  send mail test1 code end


  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setCopied(false);
  //   }, 3000); // Reset copied state after 3 seconds
  //   return () => clearTimeout(timer);
  // }, [copied]);

  const fetchData = async () => {
    setIsLoading(true);
    let nextToken = null;
    let candidateProfileItem = null;

    try {
      do {
        const candidateProfileResponse = await API.graphql(
          graphqlOperation(listCandidateProfiles, {
            filter: { email: { eq: email } },
            limit: 100,
            nextToken: nextToken,
          })
        );

        const items = candidateProfileResponse.data.listCandidateProfiles.items;

        if (items.length > 0) {
          candidateProfileItem = items[0];
          break;
        } else {
          nextToken = candidateProfileResponse.data.listCandidateProfiles.nextToken;
        }
      } while (nextToken);

      setCandidateProfile(candidateProfileItem);

      // original code start
      // const interviewEvaluationsResponse = await API.graphql(
      //   graphqlOperation(listInterviewEvaluations, {
      //     filter: { candidateEmail: { eq: email } },
      //     limit: 100,
      //     nextToken: null,
      //   })
      // );
      // setInterviewEvaluations(interviewEvaluationsResponse.data.listInterviewEvaluations.items);
      // original code end

      // testing code start

      nextToken = null;
      let interviewEvaluationsItems = [];
      do {
        const interviewEvaluationsResponse = await API.graphql(
          graphqlOperation(listInterviewEvaluations, {
            filter: {
              candidateEmail: { eq: email },
            },
            limit: 100, // Adjust the limit as per your requirement
            nextToken: nextToken,
          })
        );
        interviewEvaluationsItems = [
          ...interviewEvaluationsItems,
          ...interviewEvaluationsResponse.data.listInterviewEvaluations.items,
        ];
        nextToken =
          interviewEvaluationsResponse.data.listInterviewEvaluations.nextToken;
      } while (nextToken);
      setInterviewEvaluations(interviewEvaluationsItems);

      // testing code end

      setIsLoading(false);
      // setEmail("");
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  // const handleShareButtonClick = () => {
  //   // Navigate to the "ShowMySkills" route with the candidate's email as a query parameter
  //   window.location.href = `/ShowMySkills?email=${email}`;
  // };

  // const handleShare = () => {
  //   const encodedEmail = encodeURIComponent(email);
  //   const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
  //   navigator.clipboard.writeText(shareableURL);
  //   setCopied(true);

  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 5000); // 5000 milliseconds = 5 seconds
  // };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 3000); // Reset copied state after 3 seconds
    return () => clearTimeout(timer);
  }, [copied]);

  const handleShare = () => {
    if (email.trim() !== "") {
      const encodedEmail = encodeURIComponent(email);
      const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
      navigator.clipboard.writeText(shareableURL);
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    } else {
      // Handle case where email is empty
      console.error("Email is empty");
    }
  };
  console.log(",.,.,.,.,.,.,.,.,....,.,....,.,.,..,.,.,.,.,...",candidateProfile)


  const handleSendEmail = async (value) => {

    // console.log("*************value***************",value)
    setLoading(true)
    try {

      let emailContent = '';
      let candidateName = candidateProfile.fullName;
      let candidateEmail = candidateProfile.email;
      let subject = ``;
      if (value === 'rejection') {
        // var candidateName = candidateProfile.fullName;
        //  var candidateEmail = candidateProfile.email;
        subject = `Update on Your Application with Varahi Technologies`;
       emailContent = `
        <html>
          <body>
            <p>Dear ${candidateName},</p>
            <p>Thank you for your interest in Varahi technologies and for taking the time to apply for various profiles with us.</p>
            <p>After careful consideration, we regret to inform you that we will not be moving forward with your application at this time. This decision was based on several factors, including the competitive nature of the candidate pool and specific requirements for the roles.</p>
            <p>We appreciate the effort you put into the application process and encourage you to consider enhancing your skills and experiences in the areas relevant to the roles you are interested in. We value your interest in Varahi technologies, and we believe that with further development, you could be a strong fit for future opportunities with us.</p>
            <p>You can rebook yourself on GetScreened and share your updated profile with our recruitment team. This will ensure that your enhanced qualifications are taken into account for future openings.</p>
            <p>Please feel free to reapply for any suitable positions that match your updated qualifications. We wish you the best in your career endeavors and hope to see your application again in the future.</p>
            <p>Thank you once again for your interest in Varahi technologies</p>
          </body>
        </html>
      `;
      } else if (value === 'selected') {
        subject = `Your GetScreened Interview Evaluation Results Are Available`;
        emailContent = `
        <html>
          <body>
            <p>Dear Candidate,</p>
            <p>We are pleased to announce that your GetScreened interview evaluation results are now available. Log in and access your scores and evaluation here: <a href="https://www.app.getscreened.in/">https://www.app.getscreened.in/</a></p>
            <p>For assistance, please refer to this video: <a href="https://youtu.be/YAobc3sGLM0">https://youtu.be/YAobc3sGLM0</a></p>
            <p>Showcase your skills and expertise by sharing your interview link with your professional network, including friends, colleagues, and recruiters.</p>
            <p>Your feedback is invaluable in helping us improve our platform. Share your thoughts with us: <a href="https://docs.google.com/forms/d/e/1FAIpQLScmRBD3N0Wblz9irjO5hDbSfS214vlrDbWIKJbOOG8Uc-acog/viewform?usp=sf_link">https://docs.google.com/forms/d/e/1FAIpQLScmRBD3N0Wblz9irjO5hDbSfS214vlrDbWIKJbOOG8Uc-acog/viewform?usp=sf_link</a></p>
            <p>Thank you for participating in the GetScreened interview process. We wish you the best of luck in your job search and professional endeavors.</p>
            <p>Best regards,</p>
            <p>GetScreened Team</p>
            <p><a href="https://www.app.getscreened.in/">GetScreened</a></p>
          </body>
        </html>
      `;
      }

       
      
    const myHeaders = new Headers();
    // myHeaders.append("X-Api-Key", process.env.REACT_APP_API_KEY);
    myHeaders.append("X-Api-Key", "abce-123");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        subject: subject,
        body: emailContent,
        to: candidateEmail,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    const response = await fetch("https://pharmaclick.co.in/RazorPay/WeatherForecast/sendEmailSES", requestOptions);
    const result = await response.json(); // Parse the response as JSON

    if (result.message === "Invalid API Key") {
        console.error("Invalid API Key");
        return false;
    }

    if(result.message === "The SMTP server requires a secure connection or the client was not authenticated. The server response was: 5.7.0 Authentication Required. For more information, go to")
    {
      console.log("SMTP Problem")
      return false;
    }

    // console.log(result);

    //    console.log("**************emailContent******************",emailContent)
    //    console.log("*************email content******************",candidateName)
    //    console.log("*************email content******************",candidateEmail)
    //    console.log("*************email content******************",subject)

      if(value === 'rejection'){

        setTimeout(() => {
          setRejectEmail(true)
        }, 0);

      }else if(value === 'selected'){

        setTimeout(() => {
          setSendUpdate(true)
        }, 0);

      }

      setTimeout(() => {
        window.location.reload();
      },3000)

      
      
    } catch (error) {
      console.error('Error sending email:', error);
      setErrorMessage('Failed to send email. Please try again later.');
      setLoading(false)
    }

  };

  // const handleChangeCode = (event) => {
  //   setEnteredCode(event.target.value);
  // };

  //  send mail test1 code end

  // console.log("11111111111111", emailType)

  return (
    // original code start
    // <div>
    //   <Header />
    //   <div className="container mx-auto px-4 py-8">
    //     <div className=" md:flex-row w-[600px] ">
    //       <div className="">
    //         <input
    //           type="email"
    //           placeholder="Enter Email"
    //           value={email}
    //           onChange={handleChange}
    //           className="w-full border border-gray-300 rounded-md py-2 px-4 mb-4 text-stone-900"
    //         />
    //         <button
    //           onClick={fetchData}
    //           className={`bg-blue-500 text-white py-2 px-4 rounded-md items-center text-center ${!email && 'opacity-50 cursor-not-allowed'}`}
    //           disabled={!email}
    //         >
    //           Get Data
    //         </button>
    //         {isLoading && <p className="mt-4">Loading...</p>}
    //       </div>

    //       <div className="w-full flex flex-col">
    //         {candidateProfile && (
    //           <div className="border border-gray-300 rounded-md p-4 mb-4">
    //             <img src={candidateProfile.photoURL || profile} alt="Candidate Photo" className="mb-4 rounded-full h-[200px] ww-[200px] text-center" />
    //             <h2 className="text-xl font-bold mb-4">Candidate Profile:</h2>
    //             <p><strong>Name:</strong> {candidateProfile.fullName}</p>
    //             <p><strong>Email:</strong> {candidateProfile.email}</p>
    //             <p><strong>Gender:</strong> {candidateProfile.gender}</p>
    //             <p><strong>Mobile Number:</strong> {candidateProfile.mobileNumber}</p>
    //             <p><strong>Description:</strong> {candidateProfile.description}</p>
    //             <p><strong>Matched Percentage:</strong> {candidateProfile.matchedPercentage}</p>
    //             <p><strong>Work Status:</strong> {candidateProfile.workStatus}</p>
    //             <p><strong>Current City:</strong> {candidateProfile.currentCity}</p>
    //             <p><strong>Education Details:</strong> {candidateProfile.educationDetails}</p>
    //             <p><strong>Key Skills:</strong> {candidateProfile.keySkills}</p>
    //             <p><strong>Work Preference:</strong> {candidateProfile.workPreference}</p>
    //             <p><strong>Preferred Salary:</strong> {candidateProfile.preferredSalary}</p>
    //             <p><strong>Role Apply For:</strong> {candidateProfile.roleApplyFor}</p>
    //             <p><strong>Address:</strong> {candidateProfile.address}</p>
    //             <p><strong>Work Mode:</strong> {candidateProfile.workMode}</p>
    //             <p><strong>Work Category:</strong> {candidateProfile.workCategory}</p>
    //             {/* Add more profile fields as needed */}
    //           </div>
    //         )}
    //         <div className="border border-gray-300 rounded-md p-4">
    //           <h2 className="text-xl font-bold mb-4">Interview Evaluations:</h2>
    //           <ul>
    //             {interviewEvaluations.map((evaluation) => (
    //               <li key={evaluation.id}>
    //                 {evaluation.videoURL && (
    //                   <div>
    //                     <strong>Video:</strong>
    //                     <video controls width="320" height="240">
    //                       <source src={evaluation.videoURL} type="video/mp4" />
    //                       Your browser does not support the video tag.
    //                     </video>
    //                   </div>
    //                 )}
    //                 <p><strong>Round Number:</strong> {evaluation.roundNumber}</p>
    //                 <p><strong>Interview Slot ID:</strong> {evaluation.interviewSlotID}</p>
    //                 <p><strong>Reappear Round Number:</strong> {evaluation.reappearRoundNumber}</p>
    //                 <p><strong>Feedback:</strong> {evaluation.feedbackForAI}</p>
    //                 {/* <p><strong>Transcript:</strong> {evaluation.transcript}</p> */}
    //                 <div className="transcript-container">
    //                   {evaluation.evaluation &&
    //                     Object.entries(JSON.parse(evaluation.evaluation)).map(
    //                       ([criterion, data], idx) => (
    //                         <div key={idx}>
    //                           <p>
    //                             <strong>{criterion}:</strong>{" "}
    //                             {data.score ? data.score : "N/A"} /{" "}
    //                             {data.max_score ? data.max_score : "N/A"}
    //                           </p>
    //                           <p>
    //                             <strong>Comments:</strong>{" "}
    //                             {data.comments ? data.comments : "N/A"}
    //                           </p>
    //                         </div>
    //                       )
    //                     )}
    //                   {/* Add more evaluation criteria as needed */}
    //                 </div>
    //                 <p><strong>Candidate Email:</strong> {evaluation.candidateEmail}</p>
    //                 <p><strong>Created At:</strong> {evaluation.createdAt}</p>
    //                 <p><strong>Updated At:</strong> {evaluation.updatedAt}</p>
    //                 {/* Add more evaluation fields as needed */}
    //               </li>
    //             ))}


    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // original code end

    // test code start

    // <div className="bg-gray-100 min-h-screen">
    //   <Header />
    //   <div className="container mx-auto py-8">
    //     <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
    //       <div>
    //         <div className="bg-white rounded-lg shadow-md p-8">
    //           <input
    //             type="email"
    //             placeholder="Enter Email"
    //             value={email}
    //             onChange={handleChange}
    //             className="w-full border border-gray-300 rounded-md py-2 px-4 mb-4 focus:outline-none focus:border-blue-500"
    //           />
    //           <button
    //             onClick={fetchData}
    //             className={`w-full bg-blue-500 text-white py-2 px-4 rounded-md text-center ${!email && 'opacity-50 cursor-not-allowed'}`}
    //             disabled={!email}
    //           >
    //             Get Data
    //           </button>
    //           {isLoading && <p className="mt-4 text-center">Loading...</p>}
    //         </div>
    //       </div>
    //       <div>
    //         {candidateProfile && (
    //           <div className="bg-white rounded-lg shadow-md p-8">
    //             <img src={candidateProfile.photoURL || profile} alt="Candidate Photo" className="mx-auto rounded-full h-32 w-32 mb-4" />
    //             <h2 className="text-2xl font-bold mb-4 text-center">Candidate Profile</h2>
    //             <div className="grid grid-cols-2 gap-4">
    //               <div><strong>Name:</strong></div><div>{candidateProfile.fullName}</div>
    //               <div><strong>Email:</strong></div><div>{candidateProfile.email}</div>
    //               <div><strong>Gender:</strong></div><div>{candidateProfile.gender}</div>
    //               <div><strong>Mobile Number:</strong></div><div>{candidateProfile.mobileNumber}</div>
    //               <div><strong>Description:</strong></div><div>{candidateProfile.description}</div>
    //               {/* Add more profile fields as needed */}
    //             </div>
    //           </div>
    //         )}
    //         <div className="bg-white rounded-lg shadow-md p-8 mt-8">
    //           <h2 className="text-2xl font-bold mb-4 text-center">Interview Evaluations</h2>
    //           <ul className="space-y-4">
    //             {interviewEvaluations.map((evaluation) => (
    //               <li key={evaluation.id}>
    //                 <div className="border-b border-gray-300 pb-4">
    //                   <p><strong>Round Number:</strong> {evaluation.roundNumber}</p>
    //                   <p><strong>Interview Slot ID:</strong> {evaluation.interviewSlotID}</p>
    //                   <p><strong>Reappear Round Number:</strong> {evaluation.reappearRoundNumber}</p>
    //                   <p><strong>Feedback:</strong> {evaluation.feedbackForAI}</p>
    //                   {/* Add more evaluation fields as needed */}
    //                 </div>
    //               </li>
    //             ))}
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // test code end

    //test 1 code start

    // <div className="bg-red-500 ">
    //   <Header />
    //   <div className="container mx-auto py-8">
    //     <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
    //       <div>
    //         <div className=" rounded-lg shadow-md p-8">
    //           <input
    //             type="email"
    //             placeholder="Enter Email"
    //             value={email}
    //             onChange={handleChange}
    //             className="w-full border border-gray-300 rounded-md py-2 px-4 mb-4 focus:outline-none focus:border-blue-500"
    //           />
    //           <button
    //             onClick={fetchData}
    //             className={`w-full bg-blue-500 text-white py-2 px-4 rounded-md text-center ${!email && 'opacity-50 cursor-not-allowed'}`}
    //             disabled={!email}
    //           >
    //             Get Data
    //           </button>
    //           {isLoading && <p className="mt-4 text-center">Loading...</p>}
    //         </div>
    //       </div>
    //       <div>
    //         {candidateProfile && (
    //           <div className="rounded-lg shadow-md p-8">
    //             <img src={candidateProfile.photoURL || profile} alt="Candidate Photo" className="mx-auto rounded-full h-32 w-32 mb-4" />
    //             <h2 className="text-2xl font-bold mb-4 text-center">Candidate Profile</h2>
    //             <div className="grid grid-cols-2 gap-4">
    //               <div><strong>Name:</strong></div><div>{candidateProfile.fullName}</div>
    //               <div><strong>Email:</strong></div><div>{candidateProfile.email}</div>
    //               <div><strong>Gender:</strong></div><div>{candidateProfile.gender}</div>
    //               <div><strong>Mobile Number:</strong></div><div>{candidateProfile.mobileNumber}</div>
    //               <div><strong>Description:</strong></div><div>{candidateProfile.description}</div>
    //               <div><strong>Matched Percentage:</strong></div><div>{candidateProfile.matchedPercentage}</div>
    //               <div><strong>Work Status:</strong></div><div>{candidateProfile.workStatus}</div>
    //               <div><strong>Current City:</strong></div><div>{candidateProfile.currentCity}</div>
    //               <div><strong>Education Details:</strong></div><div>{candidateProfile.educationDetails}</div>
    //               <div><strong>Key Skills:</strong></div><div>{candidateProfile.keySkills}</div>
    //               <div><strong>Work Preference:</strong></div><div>{candidateProfile.workPreference}</div>
    //               <div><strong>Preferred Salary:</strong></div><div>{candidateProfile.preferredSalary}</div>
    //               <div><strong>Role Apply For:</strong></div><div>{candidateProfile.roleApplyFor}</div>
    //               <div><strong>Address:</strong></div><div>{candidateProfile.address}</div>
    //               <div><strong>Work Mode:</strong></div><div>{candidateProfile.workMode}</div>
    //               <div><strong>Work Category:</strong></div><div>{candidateProfile.workCategory}</div>
    //               {/* Add more profile fields as needed */}
    //             </div>
    //           </div>
    //         )}
    //         <div className=" rounded-lg shadow-md p-8 mt-8">
    //           <h2 className="text-2xl font-bold mb-4 text-center">Interview Evaluations</h2>
    //           <ul className="space-y-4">
    //             {interviewEvaluations.map((evaluation) => (
    //               <li key={evaluation.id}>
    //                 <div className="border-b border-gray-300 pb-4">
    //                   <p><strong>Round Number:</strong> {evaluation.roundNumber}</p>
    //                   <p><strong>Interview Slot ID:</strong> {evaluation.interviewSlotID}</p>
    //                   <p><strong>Reappear Round Number:</strong> {evaluation.reappearRoundNumber}</p>
    //                   <p><strong>Feedback:</strong> {evaluation.feedbackForAI}</p>
    //                   <div className="transcript-container">
    //                     {evaluation.evaluation &&
    //                       Object.entries(JSON.parse(evaluation.evaluation)).map(([criterion, data], idx) => (
    //                         <div key={idx}>
    //                           <p><strong>{criterion}:</strong> {data.score ? data.score : "N/A"} / {data.max_score ? data.max_score : "N/A"}</p>
    //                           <p><strong>Comments:</strong> {data.comments ? data.comments : "N/A"}</p>
    //                         </div>
    //                       ))}
    //                     {/* Add more evaluation criteria as needed */}
    //                   </div>
    //                   <p><strong>Candidate Email:</strong> {evaluation.candidateEmail}</p>
    //                   <p><strong>Created At:</strong> {evaluation.createdAt}</p>
    //                   <p><strong>Updated At:</strong> {evaluation.updatedAt}</p>
    //                   {/* Add more evaluation fields as needed */}
    //                 </div>
    //               </li>
    //             ))}
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    //test 1 code end

    //test 2 code start

    <div className="bg-red-500 " style={{ color: "#000", backgroundColor: "#fff" }}>
      <Header />
      <div className="container  mx-auto py-8" style={{ color: "#000", backgroundColor: "#fff" }} >
        <div className="rounded-lg shadow-md p-8 bg-gray-700">
          <input
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={handleChange}
            className="w-full border bg-transparent text-white border-gray-300 rounded-md py-2 px-4 mb-4 focus:outline-none focus:border-blue-500"
          />
          <button
            onClick={fetchData}
            className={`w-full bg-green-400 text-white py-2 px-4 rounded-md text-center ${!email && 'opacity-50 cursor-not-allowed'}`}
            disabled={!email}
          >
            Get Data
          </button>
          <div className="mt-3">
            <button
              onClick={() => {
                setEmail("");
                // window.location.reload();

              }}
              className={`w-full bg-red-600 text-white py-2 px-4 rounded-md text-center ${!email && 'opacity-50 cursor-not-allowed '}`}

            >
              Clear Email
            </button>
          </div>
          {isLoading && <p className="mt-4 text-center">Loading...</p>}


          {/* {candidateProfile && (
            <div>
            <button onClick={handleShare}>
              {copied ? (
                "✓ Copied Link!"
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={FaCopy}
                    style={{ cursor: "pointer" }}
                  />
                  {" GetScreened Link"}
                </>
              )}
            </button>
          </div>
           )}  */}

          {/* <div>
            <button onClick={handleShare}>
              {copied ? (
                "✓ Copied Link!"
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={FaCopy}
                    style={{ cursor: "pointer" }}
                  />
                  {" GetScreened Link"}
                </>
              )}
            </button>
          </div> */}

        </div>
        {candidateProfile && (
          <div className="rounded-lg shadow-md w-[900px] p-10 mt-8">


            <div className="flex justify-between ">
              <h2 className="text-2xl font-bold mb-4 text-center">Candidate Profile</h2>

              {candidateProfile && (
                <div>
                  <button onClick={handleShare}>
                    {copied ? (
                      "✓ Copied Link!"
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={FaCopy}
                          style={{ cursor: "pointer" }}
                        />
                        {" GetScreened Link"}
                      </>
                    )}
                  </button>
                </div>
              )}


            </div>

            <img src={candidateProfile.photoURL || profile} alt="Candidate Photo" className="mx-auto rounded-full h-32 w-32 mb-4" />


            <div className="grid grid-cols-2 gap-4">
              <div><strong>Name:</strong></div><div>{candidateProfile.fullName}</div>
              <div><strong>Email:</strong></div><div>{candidateProfile.email}</div>
              <div><strong>Gender:</strong></div><div>{candidateProfile.gender}</div>
              <div><strong>Mobile Number:</strong></div><div>{candidateProfile.mobileNumber}</div>
              <div><strong>Description:</strong></div><div>{candidateProfile.description}</div>
              <div><strong>Matched Percentage:</strong></div><div>{candidateProfile.matchedPercentage}</div>
              <div><strong>Work Status:</strong></div><div>{candidateProfile.workStatus}</div>
              <div><strong>Current City:</strong></div><div>{candidateProfile.currentCity}</div>
              <div><strong>Education Details:</strong></div><div>{candidateProfile.educationDetails}</div>
              <div><strong>Key Skills:</strong></div><div>{candidateProfile.keySkills}</div>
              <div><strong>Work Preference:</strong></div><div>{candidateProfile.workPreference}</div>
              <div><strong>Preferred Salary:</strong></div><div>{candidateProfile.preferredSalary}</div>
              <div><strong>Role Apply For:</strong></div><div>{candidateProfile.roleApplyFor}</div>
              <div><strong>Address:</strong></div><div>{candidateProfile.address}</div>
              <div><strong>Work Mode:</strong></div><div>{candidateProfile.workMode}</div>
              <div><strong>Work Category:</strong></div><div>{candidateProfile.workCategory}</div>
              <div><strong>Resume:</strong></div><div>{candidateProfile.resumeURL}</div>
              {/* Add more profile fields as needed */}
            </div>
          </div>
        )}
        <div className="rounded-lg shadow-md w-[900px] p-10 mt-8">
          <h2 className="text-2xl font-bold mb-4 text-center">Interview Evaluations</h2>
          <ul className="space-y-4">
            {interviewEvaluations.map((evaluation) => (
              <li key={evaluation.id}>
                <div className="border-b border-gray-300 pb-4">
                  {evaluation.videoURL && (
                    <div>
                      <strong>Video:</strong>
                      <video controls
                        width="320"
                        height="240"
                        controlsList="nodownload"
                      >
                        <source src={evaluation.videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                  <p><strong>Round Number:</strong> {evaluation.roundNumber}</p>
                  <p><strong>Interview Slot ID:</strong> {evaluation.interviewSlotID}</p>
                  <p><strong>Reappear Round Number:</strong> {evaluation.reappearRoundNumber}</p>
                  <p><strong>Feedback:</strong> {evaluation.feedbackForAI}</p>
                  <h1>Transcript:</h1>
                  <div className="transcript-container text-black">
                    {evaluation.transcript ? (
                      <p>{evaluation.transcript}</p>
                    ) : (
                      <p>Transcript is not Availble</p>
                    )}
                  </div>
                  <h1 className="mt-2">Evaluation : </h1>
                  <div className="transcript-container text-black">
                    {evaluation.evaluation && (() => {
                      const parsedEvaluation = JSON.parse(evaluation.evaluation);
                      const totalScore = Object.values(parsedEvaluation).reduce((sum, data) => sum + (data.score || 0), 0);
                      const totalMaxScore = Object.values(parsedEvaluation).reduce((sum, data) => sum + (data.max_score || 0), 0);
                      const overallRating = totalMaxScore ? ((totalScore / totalMaxScore) * 10).toFixed(2) : "N/A";

                      return (
                        <>
                          <h1><strong>Overall Rating:</strong> {overallRating}</h1>
                          {Object.entries(parsedEvaluation).map(([criterion, data], idx) => (
                            <div key={idx}>
                              <p><strong>{criterion}:</strong> {data.score ? data.score : "N/A"} / {data.max_score ? data.max_score : "N/A"}</p>
                              <p><strong>Comments:</strong> {data.comments ? data.comments : "N/A"}</p>
                            </div>
                          ))}
                          <div>
                            <p><strong>Overall Rating:</strong> {overallRating}%</p>
                          </div>
                        </>
                      );
                    })()}
                  </div>

                  <p><strong>Candidate Email:</strong> {evaluation.candidateEmail}</p>
                  <p><strong>Created At:</strong> {evaluation.createdAt}</p>
                  <p><strong>Updated At:</strong> {evaluation.updatedAt}</p>
                  {/* Add more evaluation fields as needed */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>


      {/* send mail original code start */}

{
  // candidateProfile && (
  //   <div className="flex items-center justify-center ">
  //       <div className="w-[900px] p-8 bg-white rounded-lg shadow-lg ">
  //         {/* Your existing JSX code for candidate details */}
  //         <div className="text-center">
  //           {/* Example: Display candidate name */}
  //           {candidateProfile && <p><strong>Captcha</strong> {captchaCode}</p>}
  //         </div>

  //         {/* CAPTCHA input and buttons */}
  //         <div className="mb-4">
  //           <label className="block mb-1">Enter CAPTCHA Code:</label>
  //           <input
  //             type="text"
  //             className="w-full px-3 py-2 border rounded-md outline-none focus:border-blue-500"
  //             value={enteredCode}
  //             onChange={handleChangeCode}
  //           />
  //           {errorMessage && <p className="text-red-500 text-sm mt-1">{errorMessage}</p>}
  //         </div>

  //         <div className="flex justify-center mb-4">
  //           <button
  //             className="bg-blue-500 text-white py-2 px-4 rounded-md mr-2 hover:bg-blue-600"
  //             onClick={handleGenerateCaptcha}
  //           >
  //             Generate CAPTCHA Code
  //           </button>
  //           <button
  //             className="bg-red-700 text-white py-2 px-4 rounded-md hover:bg-red-600"
  //             onClick={handleSendEmail}
  //             disabled={!captchaCode || emailSent}
  //           >
  //             Rejection Email
  //           </button>
  //         </div>

  //         {emailSent && <p className="text-green-500 text-center">Email sent successfully!</p>}
  //       </div>
  //     </div>
  // )
}
      
      {/* send code original mail end */}

      {/* send code test1 mail start  */}

      {candidateProfile && (
          <div className="flex items-center justify-center mt-8">
            <div className="w-[900px] p-8 bg-white rounded-lg shadow-lg">
              {/* <div className="text-center">
                <p><strong>CAPTCHA:</strong> {captchaCode}</p>
              </div> */}
              {/* <div className="mb-4">
                <label className="block mb-1">Enter CAPTCHA Code:</label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded-md outline-none focus:border-blue-500"
                  value={enteredCode}
                  onChange={handleChangeCode}
                />
                {errorMessage && <p className="text-red-500 text-sm mt-1">{errorMessage}</p>}
              </div> */}
              <div className="flex justify-center mb-4">
                {/* <button
                  className="bg-blue-500 text-white py-2 px-4 rounded-md mr-2 hover:bg-blue-600"
                  onClick={handleGenerateCaptcha}
                >
                  Generate CAPTCHA Code
                </button> */}
                <button
                  className="bg-red-700 text-white py-2 px-4 rounded-md hover:bg-red-600"
                  onClick={() => {
                    // setEmailType('rejection');
                    handleSendEmail("rejection");
                  }}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Send Reject Mail"}
                </button>
                <button
                  className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 ml-2"
                  onClick={() => {
                    // setEmailType('selected');
                    handleSendEmail("selected");
                  }}
                  disabled={loading}
                >
                  {loading ? "Loading..." :"Send Interview Update"}
                </button>
              </div>
              {/* {emailSent && <p className="text-green-500 text-center">Email sent successfully!</p>} */}
              {rejectEmail && <p className="text-red-500 text-center">Reject Email sent successfully!</p>}
              {sendUpdate && <p className="text-green-500 text-center">Email sent successfully!</p>}
            </div>
          </div>
        )}

      {/* send code test1 mail end */}
      
    </div>


    //test 2 code end

  );
};

export default InterviewSlotBook;



// neww code end
