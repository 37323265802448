
import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { useCalendlyEventListener, PopupWidget, PopupButton } from "react-calendly";
import { createInterviewSlot } from "../graphql/mutations"; // Corrected import statement
import logo from "../assets/logo.svg"; // Adjusted import path for logo.svg
import axios from "axios"; // Import axios here
import { Link, useNavigate } from "react-router-dom";
import { listInterviewSlots } from "../graphql/queries";
import Header from "../components/header/Header";
import { RiseLoader } from "react-spinners";
function CandidateSchedule() {
  // Create a ref to hold the root element
  const navigate = useNavigate();
  const rootElementRef1 = useRef(null);
  const rootElementRef2 = useRef(null);

  const [isMounted, setIsMounted] = useState(false);
  const [hasScheduledInterview, setHasScheduledInterview] = useState(false);

  const [loading, setLoading] = useState(true); // Add loading state
  const [userData, setUserData] = useState(null); // Add state to store user data
  const [clickedButton, setClickedButton] = useState(""); // State to track clicked button
  console.log(clickedButton,`clickedButton`);
  useEffect(() => {
    // After the component mounts, setIsMounted is set to true
    console.log(clickedButton,`clickedButton2`);
    setIsMounted(true);
  }, []);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {
    const fetchData = async () => {
      const userInfo = await Auth.currentAuthenticatedUser();
      const userId = userInfo.attributes.sub;
      let nextToken = null;
      const candidateEmail = localStorage.getItem("candidateProfileEmailID");

      if (candidateEmail) {
        nextToken = null;
        let interviewSlots = [];
        do {
          const interviewSlotsResponse = await API.graphql(
            graphqlOperation(listInterviewSlots, {
              filter: { candidateEmail: { eq: candidateEmail } },
              limit: 100,
              nextToken: nextToken,
            })
          );

          console.log(
            "hasScheduledInterview from candidateSchdule ",
            interviewSlotsResponse
          );

          interviewSlots.push(
            ...interviewSlotsResponse.data.listInterviewSlots.items
          );
          nextToken = interviewSlotsResponse.data.listInterviewSlots.nextToken;
        } while (nextToken);

        setHasScheduledInterview(interviewSlots.length > 0);
        setLoading(false); // Set loading to false when data is fetched

        console.log(
          "hasScheduledInterview from candidateSchdule ",
          hasScheduledInterview
        );
      }

      // console.log("hasScheduledInterview from candidateSchdule ",interviewSlotsResponse)
    };

    fetchData();
  }, []);

  const userEmail = localStorage.getItem("candidateProfileEmailID");
  const candidateProfile = localStorage.getItem("candidateProfile");

  const profileData = JSON.parse(candidateProfile);
  const fullName = profileData.fullName;
  console.log(fullName); // This will log "Atul Nagose" to the console

  console.log("Candidate profile data not found in localStorage");

  console.log(userEmail);

  const [state] = useState();

  useCalendlyEventListener({
    
    onProfilePageViewed: () => console.log("onProfilePageViewed"),

    onDateAndTimeSelected: (event) => {
      
      console.log("onDateAndTimeSelected", event);
      console.log(clickedButton,`clickedButton`);
      // Assuming `event.data` contains the date and time selected by the user.
      // The exact structure of `event.data` might need adjustment based on Calendly's event data format.
      const selectedDate = event.data?.start_time;
      const selectedEndTime = event.data?.end_time;
      console.log(`Selected start time: ${selectedDate}`);
      console.log(`Selected end time: ${selectedEndTime}`);
    },
    onEventTypeViewed: () => {
      console.log("onEventTypeViewed");
    },

    onEventScheduled: async (e) => {
      const eventData = e.data.payload;
      console.log("Event Data:", eventData);

      const candidateProfileID = localStorage.getItem("candidateProfileId");
      const candidateProfileEmailId = localStorage.getItem(
        "candidateProfileEmailID"
      );
      console.log("Candidate Profile ID:", candidateProfileID);
      const scheduled = true;

  
      const input = {
        date: eventData.event.start_time,
        startTime: eventData.event.start_time, // assuming you want the start_time of the event
        endTime: eventData.event.end_time, // assuming you want the end_time of the event
        meetingLinkURL: eventData.event.uri,
        candidateProfileID: candidateProfileID, // Ensure this is previously stored
        candidateEmail: candidateProfileEmailId,
        // meetingConfig: clickedButton === "slot1" ? JSON.stringify({ type: "Config1" }) : JSON.stringify({ type: "Config2" })
        // meetingConfig: JSON.stringify({
        //   type: clickedButton === "slot1" ? "Config1" : "Config2"
        // })
        // meetingConfig: clickedButton === "slot1" ? "Config1" : "Config2"
        // meetingConfig: "Config2"
        // Add other necessary fields based on your schema
      };

      

      try {
        const response = await API.graphql(
          graphqlOperation(createInterviewSlot, { input })
        );
        console.log("GraphQL Response:", response);
        console.log("Interview slot created successfully");
        window.location.href = "/CandidateDashboard";
      } catch (error) {
        console.error("Error creating interview slot:", error);
      }
    },
  });

  return (
   

    (!hasScheduledInterview) ? (
      <div>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <div className="col py-3">
              {/* Full width header */}

              

              {/* Centered content */}
              <div className="d-flex flex-column align-items-center justify-content-center py-3">
                {/* Div for Schedule button */}


                
                {/* 2 Bot integartion */}
                {/* <div>
                  <div style={{ marginBottom: "20px" }}>
                    <PopupButton
                      url="https://calendly.com/getscreened"
                      rootElement={document.getElementById("root")}
                      text="Book Slot 1"
                      textColor="#FFFFFF"
                      color="#00A2FF"
                    />
                  </div>
                  <div>
                    <PopupButton
                      url="https://calendly.com/connect-getscreened/getscreened-bot"
                      rootElement={document.getElementById("root")}
                      text="Book Slot 2"
                      textColor="#FFFFFF"
                      color="#00A2FF"
                    />
                  </div>
                </div> */}
                {/* 2 bot intergation */}


                <div className="mb-4">
                  {/* Use the ref to specify the root element */}
                  {/* <div ref={rootElementRef2}>
          {isMounted && (
            <PopupWidget
              url="https://calendly.com/getscreened"
              prefill={{
                name: fullName,
                email: userEmail,
              }
            
            }
              textColor="#FFFFFF"
              color="#00A2FF"
              branding={false}
              style={{
                width: "auto",
                cursor: "pointer",
                position: "fixed",
                left: "20px !important",
                bottom: "50% !important",
                zIndex: "9998 !important",
                marginLeft: "55%"  // Adjusted marginLeft
              }}
               className="mt-10"
              rootElement={rootElementRef2.current}
              text="Book slot with Bot 2"
            />
          )}
        </div> */}

                </div>
                <div className="mb-4">
                  {/* Use the ref to specify the root element */}
                  {/* <div ref={rootElementRef1}>
          {isMounted && (
            <PopupWidget
              url="https://calendly.com/getscreened"
              prefill={{
                name: fullName,
                email: userEmail,
              }}
              textColor="#FFFFFF"
              color="#00A2FF"
              branding={false}
              style={{
                width: "auto",
                cursor: "pointer",
                position: "fixed",
                left: "80px !important",
                bottom: "50% !important",
                zIndex: "9998 !important",
                marginLeft: "25%"  // Adjusted marginLeft
              }}
              rootElement={rootElementRef1.current}
              text="Book slot with Bot 1"
            />
          )}
        </div> */}
                </div>

                {/* Div for benefit and instruction information */}
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="p-3 border rounded">
                      <h3 className="text-center font-weight-bold mb-3">
                        Benefits:
                      </h3>
                      <p>
                        1.Experience fair and unbiased AI-powered interviews that
                        focus on your skill set.
                      </p>
                      <p>
                        2. Skill Assessment: Get evaluated on your true abilities,
                        not just your resume.
                      </p>
                      <p>
                        3. Instant Feedback: Receive immediate insights to improve
                        your interview skills.
                      </p>
                      <p>
                        4. Exposure to Top Companies: Your profile is accessible to
                        a wide array of employers seeking your expertise.
                      </p>
                      <p>
                        5. Job Matching: AI algorithms match you with job
                        opportunities that fit your unique skills and potential.
                      </p>
                      <p>
                        6. Receive immediate insights after each interview session,
                        helping you understand your strengths and areas for
                        improvement.
                      </p>
                      <p>
                        7. Your profile is showcased to a wide range of reputable
                        employers actively seeking candidates with your expertise.
                      </p>
                      <p>
                        8. Receive ongoing feedback to refine your interview skills
                        and stay adaptable to changes in the industry and job market
                        demands
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="p-3 border rounded">
                      <h3 className="text-center font-weight-bold mb-3">
                        Instructions:
                      </h3>
                      <p>
                        1. Instruct candidates to maintain a professional demeanor
                        throughout the interview.
                      </p>
                      <p>
                        2. Emphasize the importance of clear communication and
                        active listening.
                      </p>
                      <p>
                        3. Require candidates to position their camera in a way that
                        their face is clearly visible throughout the interview.
                      </p>
                      <p>
                        4. Encourage candidates to test their internet connection
                        prior to the interview to ensure stability.
                      </p>
                      <p>
                        5. Utilize AI-powered proctoring software to monitor the
                        candidate's behavior and detect any suspicious activities,
                        such as looking off-screen or using external devices.
                      </p>
                      <p>
                        6. we can target specific skills or experiences needed for
                        the role, helping recruiters find the best match for their
                        positions.
                      </p>
                      <p>
                        7. Our AI interview bot is programmed to minimize bias in
                        the recruitment process by evaluating candidates solely
                        based on their qualifications, skills, and responses to
                        interview questions. This helps ensure fair and objective
                        assessments, promoting diversity and inclusion within the
                        workforce.
                      </p>
                    </div>
                  </div>
                </div>
{/* As of Now , Availibily more in Bot2  */}
               {/* live production book the slot code start */}
                <div className="flex gap-4 ">
                  {/* <div style={{ marginBottom: "20px" }} className="bg-[#9900FF] px-5 py-2 rounded-lg">
                    <PopupButton
                      url="https://calendly.com/getscreened"
                      rootElement={document.getElementById("root")}
                      text="Schedule Interview with Bot 1"
                      textColor="#FFFFFF"
                      color="#00A2FF"
                      prefill={{
                        name: fullName,
                        email: userEmail,
                      }}
                      onClick={() => setClickedButton("slot1")}
                    />
                  </div> */}
                  <div style={{ marginBottom: "20px" }} className="bg-[#9900FF] px-5 py-2 rounded-lg">
                    <PopupButton
                      url="https://calendly.com/connect-getscreened/getscreened-bot"
                      rootElement={document.getElementById("root")}
                      text="Schedule Interview with Bot 2"
                      textColor="#FFFFFF"
                      color="#00A2FF"
                      prefill={{
                        name: fullName,
                        email: userEmail,
                      }}
                      onClick={() => setClickedButton("slot2")} 
                    />
                  </div>
                </div>

                {/* live production of book the slot code end */}

              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <Header />
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>

          <div style={{ zIndex: "1", textAlign: "center" }}>
            <h2 style={{ fontStyle: "italic" }} className="text-center">
              Congratulations!
            </h2>
            <p style={{ fontStyle: "italic" }} className="text-center">
              Your interview slot is booked. Scheduled
              interview details have been sent to your
              email. Please kindly check your email and join
              on time.
            </p>
            <p style={{ fontStyle: "italic" }} className="text-center">
              We are currently working on some other
              features, which will be available shortly.
            </p>
            <div style={{ marginTop: "20px" }}> {/* Adjust margin as needed */}
              <Link to={'/'}><button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md mt-4 ml-7">Back</button></Link>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default CandidateSchedule;
