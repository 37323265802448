import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import "./profilePage.css";
import img from "../assets/Frame 6.png";
import Whatsappimg from "../assets/whatsapp.png";
import Shear from "../assets/shear.png";
import Linkdin from "../assets/linkdin.png";
import Ellipse from "../assets/Ellipse 2.png";
import Profileimg from "../assets/Ellipse 1.png";
import Header from "../components/header/Header"
import { listAdminAddCandidateContexts, listCandidateProfiles, listInterviewEvaluations } from "../graphql/queries";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { updateCandidateProfile } from "../graphql/mutations";
import Insta from "../assets/insta.png";
import facebook from "../assets/facebook.png";
import Copy from "../assets/copy.png";
import CloseIcon from "../assets/path-to-close-icon.svg";
import CoverImage1 from "../assets/CoverImage1.png"



function ProfilePage() {
  const [activeSection, setActiveSection] = useState("video");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const candidateProfileEmailId = localStorage.getItem(
    "candidateProfileEmailID"
  );
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [candidateProfile, setCandidateProfile] = useState(null);
  const [privateCandidate, setPrivateCandidate] = useState(false);
  const [privacy, setPrivacy] = useState(null)
  const [gettingdata, setGettingdata] = useState(false);
  const [updateprivate, setUpdateprivate] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copyURL, setCopyURL] = useState("")
  const [profileData, setProfileData] = useState([]);

  // test1 upload start
  const [isOpen, setIsOpen] = useState(false);
  // const [currentImage, setCurrentImage] = useState(candidateProfile.photoURL || Profileimg);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageProfilePreview, setImageProfilePreview] = useState(null);
  // test1 upload end

  // test 2 upload start
  const [isCoverOpen, setIsCoverOpen] = useState(false);
  // const [currentImage, setCurrentImage] = useState(candidateProfile.photoURL || Profileimg);
  const [selectedCoverFile, setSelectedCoverFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // State to hold image preview URL
  // const [coverImage, setCoverImage] = useState(null);
  // test 2 upload start

  const maxLength = 30; // Adjust the maximum length as per your requirement
  const shortURL = copyURL.length > maxLength ? `${copyURL.substring(0, maxLength)}...` : copyURL;




  useEffect(() => {

    const fetchDataFromAPIs = async () => {

      try {
        // Fetch data from the first API (listAdminAddCandidateContexts)
        let nextToken = null;
        let adminContextItems = [];
        do {
          const adminContextResponse = await API.graphql(
            graphqlOperation(listAdminAddCandidateContexts, {
              filter: {
                candidateEmail: { eq: candidateProfileEmailId },
              },
              limit: 100, // Adjust the limit as per your requirement
              nextToken: nextToken,
            })
          );
          adminContextItems = [
            ...adminContextItems,
            ...adminContextResponse.data.listAdminAddCandidateContexts.items,
          ];
          nextToken =
            adminContextResponse.data.listAdminAddCandidateContexts.nextToken;
        } while (nextToken);
        const filteredProfiles = adminContextItems;
        setProfiles(filteredProfiles);

        // Fetch data from the second API (listInterviewEvaluations)
        nextToken = null;
        let interviewEvaluationsItems = [];
        do {
          const interviewEvaluationsResponse = await API.graphql(
            graphqlOperation(listInterviewEvaluations, {
              filter: {
                candidateEmail: { eq: candidateProfileEmailId },
              },
              limit: 100, // Adjust the limit as per your requirement
              nextToken: nextToken,
            })
          );
          interviewEvaluationsItems = [
            ...interviewEvaluationsItems,
            ...interviewEvaluationsResponse.data.listInterviewEvaluations.items,
          ];
          nextToken =
            interviewEvaluationsResponse.data.listInterviewEvaluations.nextToken;
        } while (nextToken);
        setInterviewEvaluations(interviewEvaluationsItems);

        // Fetch candidate profile data (listCandidateProfiles)
        nextToken = null;
        let candidateProfileItem = null;
        do {
          const candidateProfileResponse = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              filter: {
                email: { eq: candidateProfileEmailId },
              },
              limit: 100, // Adjust the limit as per your requirement
              nextToken: nextToken,
            })
          );
          const items = await candidateProfileResponse.data.listCandidateProfiles.items;

          if (items.length > 0) {
            candidateProfileItem = items[0];
            break; // Exit the loop since a profile is found
          } else {
            // Continue to next iteration if there are more records to fetch
            nextToken =
              candidateProfileResponse.data.listCandidateProfiles.nextToken;
          }
        } while (nextToken);

        setCandidateProfile(candidateProfileItem);
        setPrivateCandidate(candidateProfileItem.private_Candidate);
        setPrivacy(candidateProfileItem.isPublic)
      } catch (error) {
        console.error("Error fetching data:", error);
      }


    }

    fetchDataFromAPIs()

    const getcopyUrl = () => {
      const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodeURIComponent(candidateProfileEmailId)}`;
      // navigator.clipboard.writeText(shareableURL);
      setCopyURL(shareableURL)
    }

    getcopyUrl()

  }, [candidateProfileEmailId, updateprivate, gettingdata])

  useEffect(() => {
    // Fetch data for all profiles when component mounts
    const fetchDataForProfiles = async () => {
      try {
        const updatedProfiles = await Promise.all(
          profiles.map(async (profile) => {
            const response = await fetch(profile.candidateContextTextFile);
            const text = await response.text();
            const start = text.indexOf("Full Interview TranScript:");
            const end =
              text.indexOf(
                "******************************************Candidate Reting:***"
              ) || text.length;
            const filteredText = text.substring(start, end);
            const ratingStart = text.indexOf(
              "******************************************Candidate Reting:***"
            );
            const candidateRating =
              ratingStart !== -1 ? text.substring(ratingStart) : '';

            return {
              ...profile,
              filteredText,
              candidateRating,
            };
          })
        );

        setProfileData(updatedProfiles);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchDataForProfiles();
  }, [profiles]);

  console.log("***********************candidateProfileEmailId********************", candidateProfileEmailId)
  console.log("**********************profiles*****************************", profiles)
  console.log("*************************candidateProfile***********************", candidateProfile)
  console.log("*****************************privateCandidate***********************", privateCandidate)
  console.log("**********************interviewEvaluation************************", interviewEvaluations)

  const interviewVideo = () => {
    setActiveSection("video");
  };

  const interviewTranscript = () => {
    setActiveSection("transcript");
  };

  const interviewEvaluation = () => {
    setActiveSection("evaluation");
  };

  const handleOpenPopup = () => {
    console.log("pratik");
    setIsPopupVisible(true);
  };
  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const changestate = () => {
    console.log(
      "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",
      candidateProfile.isPublic
    );
    const change = !privacy;
    setGettingdata(change);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", change);
    updateIsPublicInDatabase(change);
  };

  const updateIsPublicInDatabase = async (isPublicValue) => {
    try {
      const input = {
        id: candidateProfile.id,
        isPublic: isPublicValue,
      };

      setUpdateprivate(true);

      const result = await API.graphql(
        graphqlOperation(updateCandidateProfile, { input })
      );

      console.log(
        "Updated isPublic:",
        result.data.updateCandidateProfile.isPublic
      );
    } catch (error) {
      console.error("Error updating isPublic:", error);
      updateprivate(false);
    }
  };


  const handleShareVia = (platform) => {
    const encodedEmail = encodeURIComponent(candidateProfileEmailId);
    const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
    let shareURL = "";

    switch (platform) {
      case "whatsapp":
        shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareableURL)}`;
        break;
      case "linkedin":
        shareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareableURL)}`;
        break;
      case "facebook":
        shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareableURL)}`;
        break;
      case "instagram":
        shareURL = `https://www.instagram.com/share?url=${encodeURIComponent(shareableURL)}`;
        break;
      case "email":
        const subject = encodeURIComponent("Check out this profile");
        const body = encodeURIComponent(`Hi, I thought you might be interested in this profile: ${shareableURL}`);
        shareURL = `mailto:?subject=${subject}&body=${body}`;
        break;
      default:
        break;
    }

    window.open(shareURL);
    // setShowModal(false);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const handleCopyURL = () => {
    const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodeURIComponent(candidateProfileEmailId)}`;
    navigator.clipboard.writeText(shareableURL);
    setCopyURL(shareableURL)
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      // setCopyURL("")
    }, 5000);
  };

  // test1 upload start
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedFile(null);
  };

  const handleFileChange = (event) => {
    // setSelectedFile(event.target.files[0]);
    //test
      const file = event.target.files[0];

      // original code start
      // if (file) {
      //   setSelectedFile(file);
      //   const reader = new FileReader();
      //   reader.onloadend = () => {
      //     setImageProfilePreview(reader.result);
      //   };
      //   reader.readAsDataURL(file);
      // }
      //original code end

      if (file) {
        if (file.size > 10 * 1024 * 1024) { // 10MB size limit
          alert('File size exceeds 2MB limit. Please choose a smaller file.');
          return;
        }
        if (!file.type.match('image/jpeg') && !file.type.match('image/png') && !file.type.match('image/jpg')) { // Only JPEG files
          alert('Only JPG/JPEG files are allowed.');
          return;
        }
  
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageProfilePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }

  };

  const handleUpload = async () => {
    try {
      if (!selectedFile) {
        alert('Please select a file to upload.');
        return;
      }

      const formData = new FormData();
      formData.append('image', selectedFile);

      // Replace 'your-upload-api-url' with your actual API endpoint for uploading images
      const response = await fetch('your-upload-api-url', {
        method: 'POST',
        body: formData,
        // You might need additional headers depending on your API requirements
      });

      if (response.ok) {
        const data = await response.json();
        // setCurrentImage(data.imageUrl); // Assuming your API returns the URL of the uploaded image
        setSelectedFile(null);
        setIsOpen(false);
      } else {
        alert('Failed to upload image.');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error uploading image. Please try again later.');
    }
  };
  // test1 upload end

  // test2 upload start

  const handleCoverOpenModal = () => {
    setIsCoverOpen(true);
  };

  const handleCoverCloseModal = () => {
    setIsCoverOpen(false);
    setSelectedCoverFile(null);
  };

  const handleCoverFileChange = (event) => {
    // setSelectedCoverFile(event.target.files[0]);
    //test
    const file = event.target.files[0];

    // original code start
    // if (file) {
    //   // setCoverImage(file);
    //   setSelectedCoverFile(event.target.files[0]);
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setImagePreview(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
    // original code end

    if (file) {
      if (file.size > 10 * 1024 * 1024) { // 10MB size limit
        alert('File size exceeds 2MB limit. Please choose a smaller file.');
        return;
      }
      if (!file.type.match('image/jpeg') && !file.type.match('image/png') && !file.type.match('image/jpg')) { // Only JPEG files
        alert('Only JPG/JPEG files are allowed.');
        return;
      }

      setSelectedCoverFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }


  };

  const handleCoverUpload = async () => {
    try {
      if (!selectedFile) {
        alert('Please select a file to upload.');
        return;
      }

      const formData = new FormData();
      formData.append('image', selectedFile);

      // Replace 'your-upload-api-url' with your actual API endpoint for uploading images
      const response = await fetch('your-upload-api-url', {
        method: 'POST',
        body: formData,
        // You might need additional headers depending on your API requirements
      });

      if (response.ok) {
        const data = await response.json();
        // setCurrentImage(data.imageUrl); // Assuming your API returns the URL of the uploaded image
        setSelectedCoverFile(null);
        setIsCoverOpen(false);
      } else {
        alert('Failed to upload image.');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error uploading image. Please try again later.');
    }
  };

  // test2 upload end



  console.log("\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\", selectedFile)
  console.log("////////////////////////////////////////////////////////", selectedCoverFile)
  return (
    <div>
      <Header />
      <div className="desktop">
        {
          candidateProfile && (

            <div className="overlap">
              <div className="rectangle"></div>

              {/* // test1 upload start */}

              {/* origibal code cover photo */}

              {/* <div className="img">
                <img src={CoverImage1} />
              </div>
              <div className="div"></div> */}

              {/* original code cover photo */}

              {/* test code cover start */}

              <div className="img" onClick={handleCoverOpenModal}>
                <img src={CoverImage1} />
              </div>
              <div className="div"></div>

              {/* {isCoverOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                  <div className="bg-white rounded-lg overflow-hidden max-w-md w-full p-8">
                    <h2 className="text-2xl font-bold mb-4">Upload New Image</h2>
                    <div className="flex justify-center mb-4">
                      <div className="relative">
                        <img
                          src={CoverImage1}
                          alt="Profile"
                          className="w-32 h-32 rounded-full object-cover border-4 border-white"
                        />
                      </div>
                    </div>
                    <input
                      type="file"
                      onChange={handleCoverFileChange}
                      accept="image/*"
                      className="border border-gray-300 p-2 w-full mb-4"
                    />
                    <div className="flex justify-end">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                        onClick={handleCoverUpload}
                      >
                        Upload
                      </button>
                      <button
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleCoverCloseModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )} */}

              {isCoverOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                  <div className="bg-white rounded-lg overflow-hidden w-11/12 md:w-3/4 lg:w-2/3 xl:w-[800px] h-[600px] p-8">
                    <h2 className="text-2xl font-bold mb-4">Upload New Image</h2>
                    <div className="flex justify-center mb-4">
                      <div className="relative w-full">
                        {/* <img
                          src={CoverImage1}
                          alt="Profile"
                          className="w-full xl:w-[800px] h-[300px] rounded-lg object-cover border-4 border-white"
                        /> */}
                        {imagePreview ? (
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className="w-full xl:w-[800px] h-[300px] rounded-lg object-cover border-4 border-white"
                          />
                        ) : (
                          <img
                            src={CoverImage1} // Your default image or placeholder
                            alt="Profile"
                            className="w-full xl:w-[800px] h-[300px] rounded-lg object-cover border-4 border-white"
                          />
                        )}
                      </div>
                    </div>
                    <input
                      type="file"
                      onChange={handleCoverFileChange}
                      accept="image/*"
                      className="border border-gray-300 p-2 w-full mb-4"
                    />
                    <div className="flex justify-end">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                        onClick={handleCoverUpload}
                      >
                        Upload
                      </button>
                      <button
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleCoverCloseModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* test code cover end */}

              {/* original code start */}

              {/* <div className="ellipse">
                <img src={candidateProfile.photoURL || Profileimg} style={{
                  maxWidth: "100%",
                  position: "absolute",
                  width: "200px",
                  height: "200px",
                  top: "-1px",
                  left: "0px",
                  border: "1px solid #fff",
                  borderRadius: "108px",
                  backgroundColor: "your-color-value"  // Remember to replace with your actual color value
                }} alt="Profile" className="" />
              </div> */}

              {/* original code end */}


              <div className="ellipse" onClick={handleOpenModal}>
                <img
                  src={candidateProfile.photoURL || Profileimg}
                  style={{
                    maxWidth: '100%',
                    position: 'absolute',
                    width: '200px',
                    height: '200px',
                    top: '-1px',
                    left: '0px',
                    border: '1px solid #fff',
                    borderRadius: '108px',
                    backgroundColor: 'your-color-value', // Replace with actual color value
                  }}
                  alt="Profile"
                  className=""
                />
              </div>
              {/* Existing elements */}

              {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                  <div className="bg-white rounded-lg overflow-hidden max-w-md w-full p-8">
                    <h2 className="text-2xl font-bold mb-4">Upload New Image</h2>
                    <div className="flex justify-center mb-4">
                      <div className="relative">
                        {/* <img
                          src={candidateProfile.photoURL || Profileimg}
                          alt="Profile"
                          className="w-32 h-32 rounded-full object-cover border-4 border-white"
                        /> */}
                        {imageProfilePreview ? (
                          <img
                            src={imageProfilePreview}
                            alt="Preview"
                            className="w-32 h-32 rounded-full object-cover border-4 border-white"
                          />
                        ) : (
                          <img
                            src={candidateProfile.photoURL || Profileimg} // Use candidateProfile.photoURL or default Profileimg
                            alt="Profile"
                            className="w-32 h-32 rounded-full object-cover border-4 border-white"
                          />
                        )}
                      </div>
                    </div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                      className="border border-gray-300 p-2 w-full mb-4"
                    />
                    <div className="flex justify-end">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                        onClick={handleUpload}
                      >
                        Upload
                      </button>
                      <button
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* // test1 upload start*/}




              <div className="ellipse-1">
                <img src={Linkdin} style={{ maxWidth: "70%" }} alt="LinkedIn" />
              </div>
              <div className="ellipse-3">
                <img src={Whatsappimg} style={{ maxWidth: "70%" }} alt="WhatsApp" />
              </div>
              <div className="ellipse-4">
                <img src={Ellipse} style={{ maxWidth: "70%" }} alt="Ellipse" />
              </div>
              <div className="text-wrapper">Name :</div>
              <div className="text-wrapper-2">Work Status :</div>
              <div className="text-wrapper-3">{candidateProfile.fullName}</div>
              <div className="text-wrapper-4">{candidateProfile.workStatus}</div>
              <div className="text-wrapper-5">Job Position :</div>
              <div className="text-wrapper-6">{candidateProfile.roleApplyFor}</div>
              <div className="Score">
                <span
                  className="scoretext"
                  style={{ color: "#fff", fontSize: "24px" }}
                >
                  Score : 8/10
                </span>
              </div>
              <div className="frame" onClick={handleOpenPopup}>
                <div className="ellipse-5" >
                  <img src={Shear} style={{ maxWidth: "100%" }} alt="Share" />
                </div>
                <div className="vector"></div>
              </div>
              <div className="lorem-ipsum-is">
                {/* original skill start  */}
                {/* <div style={{ marginBottom: "4px", fontSize: "20px" }}>Skill :</div> */}
                {/* <div style={{ display: "flex", gap: "16px", marginBottom: "12px" }}>
              <div>
                <div
                  style={{
                    padding: "4px 15px",
                  }}
                  className="text-wrapper-7"
                >
                  Html
                </div>
              </div>
              <div>
                <div className="text-wrapper-7">Css</div>
              </div>
              <div>
                <div className="text-wrapper-7">JavaScript</div>
              </div>
              <div>
                <div className="text-wrapper-7">Node.js</div>
              </div>
            </div> */}
                {/* original skill end */}
                <div className="skills items-center gap-1 mt-2">
                  <strong style={{ color: "#fff" }}>Skills :</strong>
                  <br />
                  {candidateProfile.keySkills &&
                    candidateProfile.keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}
                </div>

                <p>
                  {candidateProfile.description}
                </p>
              </div>
            </div>

          )
        }


        {/* <div className="lock">
          <div className="sublock">
            <p className="p">Control Who Can View Your Profile</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img src={img} alt="Visible to everyone" /> Visible to everyone
            </div>
          </div>
        </div> */}
        {/* test public private start */}

        {/* pratik send code start */}

        {isPopupVisible && (
          <div className="popupbox">
            <div className="subpopupbox">
              <div className="close-icon" onClick={handleClosePopup}>
                <img
                  className="close-icon-img"
                  src={CloseIcon}
                  style={{ width: "24px", height: "24px" }}
                  alt="Close"
                />
              </div>
              {/* <button onClick={handleCopyURL}>
            <div className="popupsecbox">
              <div className="shraiconbg" style={{ background: "#F1F0FF" }}>
                <img className="shearicon" src={Copy} alt="shearicon" />
              </div>
              <div className="text">
                {copied ? <h6>Link Copied</h6>:<h6>Copy Link</h6>}
              </div>
            </div>
            </button> */}
              <button onClick={() => handleShareVia("linkedin")} >
                <div className="popupsecbox">
                  <div className="shraiconbg" style={{ background: "#3988D5" }}>
                    <img className="Linkdin" src={Linkdin} alt="LinkedIn" />
                  </div>
                  <div className="text">
                    <h5 className="text-style">LinkedIn</h5>
                  </div>
                </div>
              </button>
              <button onClick={() => handleShareVia("whatsapp")} >
                <div className="popupsecbox">
                  <div className="shraiconbg" style={{ background: "#1AED07" }}>
                    <img className="Whatsappimg" src={Whatsappimg} alt="WhatsApp" />
                  </div>
                  <div className="text">
                    <h5 className="text-style">WhatsApp</h5>
                  </div>
                </div>
              </button>
              <button onClick={() => handleShareVia("instagram")}>
                <div className="popupsecbox">
                  <div className="shraiconbg" style={{ background: "#DD7464" }}>
                    <img className="Insta" src={Insta} alt="Insta" />
                  </div>
                  <div className="text">
                    <h5 className="text-style">Instagram</h5>
                  </div>
                </div>
              </button>
              <button onClick={() => handleShareVia("facebook")}>
                <div className="popupsecbox">
                  <div className="shraiconbg" style={{ background: "#419BE9" }}>
                    <img className="facebook" src={facebook} alt="facebook" />
                  </div>
                  <div className="text">
                    <h5 className="text-style">facebook</h5>
                  </div>
                </div>
              </button>
            </div>
            <div className="url">
              {/* <div className="link-url">{copyURL}</div> */}
              <div className="link-url">{shortURL}</div>
              <button className="link-path">
              <div className=" text-center" onClick={handleCopyURL}>{copied ? <h6>Link Copied</h6> : <h6>Copy Link</h6>}</div>
              </button>
            </div>
          </div>
        )}

        {/* Pratik send code end */}


        <div className="lock flex flex-col gap-3">
          <div>
            <t5 className="capitalize font-medium">
              control who can view your profile
            </t5>
          </div>
          <div>
            <button
              onClick={changestate}
              className={`p-2 capitalize ${privacy
                ? "bg-green-600 rounded-lg uppercase"
                : "bg-red-400 rounded-lg uppercase"
                }`}
            >
              {privacy ? <FaLockOpen /> : <FaLock />}
            </button>

            <span className="ml-3 text-gray-500">
              {privacy === true
                ? "Visible to everyone"
                : "visible for editor"}
            </span>
          </div>
        </div>

        {/* adminCandidate Data side  code start  */}
        {/* <div>
          
          {profileData.map((profile, index) => (
            <div key={index}>
              <div className="transcript-container">
                <CustomizedResumeText filteredResumeText={profile.filteredText} />
                <h1>{console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", profile.filteredText)}</h1>
              </div>
              <div className="transcript-container">
                <CustomizedResumeRating candidateRating={profile.candidateRating} />
                <h1>{console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", profile.candidateRating)}</h1>
                <h1>hello</h1>
              </div>
              <h1>Hello</h1>
            </div>
          ))}
          
        </div> */}
        {/* adminCandidate Data side code end */}

        {/* test public private end */}

        <div>
          <div className="overlap-group">
            <hr className="line" />
            <div
              className={`rectangle-2 ${activeSection === "video" ? "active" : ""
                }`}
              onClick={interviewVideo}
            >
              <div>Interview Evaluation</div>
            </div>
            <div
              className={`rectangle-3 ${activeSection === "transcript" ? "active" : ""
                }`}
              onClick={interviewTranscript}
            >
              <div>Interview Transcript</div>
            </div>
            <div
              className={`rectangle-4 ${activeSection === "evaluation" ? "active" : ""
                }`}
              onClick={interviewEvaluation}
            >
              <div className="navbartext">Interview Video</div>
            </div>

            <div
              className={`interview-video ${activeSection === "video" ? "active" : ""
                }`}
            >

              {interviewEvaluations && interviewEvaluations.length > 0 ? (
                interviewEvaluations.map((evaluation, index) => (
                  <div key={index}>
                    <div className="transcript-container">
                      {evaluation.evaluation &&
                        Object.entries(JSON.parse(evaluation.evaluation)).map(([criterion, data], idx) => (
                          <div key={idx}>
                            <p>
                              <strong>{criterion}:</strong>{" "}
                              {data.score ? data.score : "N/A"} / {data.max_score ? data.max_score : "N/A"}
                            </p>
                            <p>
                              <strong>Comments:</strong> {data.comments ? data.comments : "N/A"}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <div className="">
                    {profileData.map((profile, index) => (
                      <div key={index}>
                        <div className="transcript-container">
                          <CustomizedResumeRating candidateRating={profile.candidateRating} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className={`interview-transcript ${activeSection === "transcript" ? "active" : ""}`}>

              {/* test code start */}
              {interviewEvaluations && interviewEvaluations.length > 0 ? (
                interviewEvaluations.map((evaluation, index) => (
                  <div key={index}>
                    <div className="transcript-container">
                      {evaluation.transcript &&
                        JSON.parse(evaluation.transcript).map((item, idx) => (
                          <div key={idx}>
                            <p>
                              <strong className="text-white">{item.speaker}:</strong> {item.message}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <div className="">
                    {profileData.map((profile, index) => (
                      <div className="transcript-container">
                        <CustomizedResumeText filteredResumeText={profile.filteredText} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* test code end */}

            </div>


            <div
              className={`interview-evaluation ${activeSection === "evaluation" ? "active" : ""
                }`}
            >


              {/*  test code start */}

              {interviewEvaluations && interviewEvaluations.length > 0 ? (
                interviewEvaluations.map((evaluation, index) => (
                  <div key={index}>
                    <div className="transcript-container">
                      <video
                        controls
                        style={{ width: "100%", border: "2px solid #ccc" }}
                        controlsList="nodownload"
                      >
                        <source src={evaluation.videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <div className="">
                    {profileData.map((profile, index) => (
                      <div className="transcript-container">
                        <video
                          controls
                          style={{ width: "100%", border: "2px solid #ccc" }}
                          controlsList="nodownload" // Add controlsList attribute here
                        >
                          <source src={profile.candidateVideo} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* test code end */}

            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

const CustomizedResumeText = ({ filteredResumeText }) => {
  return (
    <>
      <div>
        <h5 style={{ color: '#9900ff' }}>TranScript</h5>
        <pre>{filteredResumeText}</pre>
      </div>
    </>
  );
};

const CustomizedResumeRating = ({ candidateRating }) => {
  return (
    <>
      <div>
        <h5 style={{ color: '#9900ff' }}>Evaluation</h5>
        <pre>{candidateRating}</pre>
      </div>
    </>
  );
};

export default ProfilePage;
