import React from 'react'
import Header from '../../components/header/Header'

const CompanyTerms = () => {
  return (
    <div>
     <Header/>

    {/* <div className="container mx-auto px-4 py-8  border border-purple-600 rounded-lg"> */}
    <div className="container mx-auto px-4 py-8   rounded-lg">
    
    <h1 className="text-5xl font-bold mt-3 mb-6">Terms and Conditions</h1>

    <div className="prose px-14 mt-3">
      <h2 className="text-lg sm:text-xl">1. Binding Agreement</h2>
      <p>1.1 Varahi Technologies Pvt Ltd (CIN: U72900PN2017PTC170554), located at 102, Townhouse 1, K Raheja Viva, Pune ("GetScreened", "we", "us", and "our"), provides a platform that facilitates interactions between job candidates and an AI-powered screening and assessment engine. The terms of this Agreement are between you and GetScreened, and your access and use of the Platform constitutes your consent to this Agreement and its terms.</p>
      <p>1.2 We may update the terms of this Agreement from time to time. We will notify you of such changes either via the email address provided by you on registration or through an announcement on the Platform. The changes will apply to the access and use of the Platform upon such notice. Your continued access and use of the Platform after such notice indicates your agreement to be bound by the new terms.</p>

      <h2 className="text-lg sm:text-xl">2. Definitions</h2>
      <p><strong>Candidate:</strong> A user who creates a Candidate Profile for accessing and using the Platform to participate in an AI-powered screening, assessment, and interview process.</p>
      <p><strong>Candidate Profile:</strong> A profile created by a Candidate that may include personal details, resume, photo, video, skills, and professional interests.</p>
      <p><strong>Intellectual Property Rights:</strong> All patents, trade names, trademarks, service marks, logos, design rights, copyrights, trade secrets, database rights, rights in technology, know-how, or other intellectual property rights (whether registered or unregistered) protected under any applicable law, and all applications, renewals, and extensions of the same.</p>
      <p><strong>Personal Data:</strong> Data, whether true or not, about an individual who can be identified (a) from that data; or (b) from that data and other information to which we have or are likely to have access.</p>
      <p><strong>Platform:</strong> The GetScreened platform and any service or feature provided thereon by GetScreened or its affiliates or agents, whether directly or indirectly, including through application programming interface (API) integration and/or hyperlinks.</p>
      <p><strong>User Content:</strong> All information, including but not limited to data, text, videos, photographs, graphics, messages, music, or other materials submitted, posted, displayed or otherwise provided by users on or through the Platform, including Candidate Profiles.</p>

      <h2 className="text-lg sm:text-xl">3. Registration</h2>
      <p>3.1 You must be at least 18 years of age to register an account on the Platform.</p>
      <p>3.2 On registration, you will be asked to create a Candidate Profile. You must ensure that the details provided by you on registration or at any time thereafter are accurate, up to date, and complete.</p>
      <p>3.3 We reserve the right to delete your account and all of your information after a period of inactivity, but only after providing you with notice.</p>
      <p>3.4 You may cancel your registration with the Platform at any time by informing us in writing. Upon cancellation, you agree to stop accessing and using the Platform.</p>

      <h2 className="text-lg sm:text-xl">4. Password and Security</h2>
      <p>4.1 You agree to keep your login details confidential and not disclose them to any third party.</p>
      <p>4.2 You are strictly liable and responsible for all uses of your account and password, whether or not authorized by you.</p>
      <p>4.3 You shall notify us immediately if you know or suspect that your account has been compromised.</p>
      <p>4.4 If we have reason to believe that there is likely a breach of your account or misuse of the Platform, we may require you to reset your password or may suspend your account.</p>
      <p>4.5 As a result of the loss of your login details or misuse of the Platform, you shall:</p>
      <p>
      <ul className='flex flex-col gap-2'>
        <li>a) bear all losses or damage incurred thereby; and</li>
        <li>b) fully indemnify GetScreened should GetScreened suffer any loss or damage.</li>
      </ul>
      </p>

      <h2 className="text-lg sm:text-xl">5. Use of Platform</h2>
      <p>5.1 GetScreened hereby grants you a limited, terminable, non-exclusive right to access and use the Platform for your personal, non-commercial purposes as follows:</p>
      <p>
      <ol className='flex flex-col gap-2'>
        <li>a) participating in AI-powered screening, assessment, and interview processes; and</li>
        <li>b) any other purposes reasonably related to the above.</li>
      </ol>
      </p>
      <p>5.2 Your permission to access and use the Platform is personal, non-transferable, and non-assignable. Participation in screening, assessment, and interview processes may include activities such as video and audio interviews, psychometric assessments, and gaming tests. Your participation may be recorded, and you consent to such recording, including the use of your name, likeness, voice, and persona in connection with the Platform, and to GetScreened's access to and use of any such recordings and your Candidate Profile in accordance with Clause 9 (Personal Data).</p>
      <p>5.3 You may not access or use the Platform:</p>
      <p>
      <ol className='flex flex-col gap-2'>
        <li>a) for any unlawful or fraudulent purpose;</li>
        <li>b) to disseminate or publish any content that is false, inaccurate, unlawful, harassing, defamatory, misleading, abusive, threatening, harmful, violent, obscene, vulgar, or otherwise objectionable;</li>
        <li>c) to modify, adapt, translate, reverse engineer, decompile, or disassemble any part of the Platform without our prior consent;</li>
        <li>d) to interfere with any other person's access to or use of the Platform;</li>
        <li>e) to post any content on behalf of another party;</li>
        <li>f) to access information not intended for you, or to access a server or account which you are not authorized to access;</li>
        <li>g) to interfere with or disrupt the operation of the Platform, or the servers or networks used to make the Platform available, or violate any requirements, procedures, policies or regulations of such networks;</li>
        <li>h) to create a database by systematically downloading and storing content available on the Platform;</li>
        <li>i) to sell, promote, or advertise products or services; and</li>
        <li>j) in any manner in breach of this Agreement.</li>
      </ol>
      </p>
      <p>5.4 We reserve the right to waive any of the conditions in Clause 5.3 either generally or in specific instances.</p>
      <p>5.5 We may, but are not obliged to, remove, edit, suspend, block, and/or monitor user accounts that we determine, in our sole discretion, violate the terms of this Agreement or any applicable law.</p>

      <h2 className="text-lg sm:text-xl">6. Candidate Data</h2>
      <p>6.1 As part of the screening, assessment, and interview processes, you may be asked to participate in activities such as video and audio interviews, psychometric assessments, and gaming tests on the Platform. Your participation will be recorded, processed, and analyzed as Candidate Data by GetScreened.</p>
      <p>6.2 GetScreened will no longer have access to your Candidate Data if:</p>
      <p>
      <ul className='flex flex-col gap-2'>
        <li>a) your Candidate Profile is deleted or otherwise removed;</li>
        <li>b) your account is deleted or otherwise removed; or</li>
        <li>c) you withdraw from the screening, assessment, and interview processes.</li>
      </ul>
      </p>

      <h2 className="text-lg sm:text-xl">7. Availability of Services</h2>
      <p>7.1 We will apply reasonable efforts to ensure that the Platform is available at all times. However, we cannot guarantee that the Platform or any part or feature thereof will always be available or error-free, although we will attempt to correct the problem as soon as reasonably possible.</p>
      <p>7.2 Access to the Platform may be restricted from time to time to allow for the repair, maintenance, or update of the Platform. We will attempt to restore access to the Platform as soon as reasonably possible.</p>

      <h2 className="text-lg sm:text-xl">8. Intellectual Property</h2>
      <p>Subject to Clause 9 (User Content), all Intellectual Property Rights in the Platform and all of the text, pictures, videos, graphics, user interfaces, visual interfaces, trademarks, logos, applications, programs, computer code, and other content made available on it are owned by GetScreened. You may not reproduce, modify, distribute, or otherwise use for commercial purposes any such content without our written consent, except as provided in this Agreement or permitted by law.</p>

      <h2 className="text-lg sm:text-xl">9. User Content</h2>
      <p>9.1 We do not claim ownership of any User Content that you provide on or through our Platform. You represent and warrant that you own or are otherwise authorized to use in any manner such User Content provided by you.</p>
      <p>9.2 We do not represent, endorse, or guarantee the accuracy, veracity, or reliability of User Content (whether provided by you or other persons) or derivative works therefrom. Any reliance on User Content will be at your own risk.</p>
      <p>9.3 You acknowledge and understand that all User Content is the sole responsibility of the person from whom the User Content originated.</p>
      <p>9.4 Any User Content that you upload or otherwise provide on or to the Platform may not contain:</p>
      <p>
      <ul className='flex flex-col gap-2'>
        <li>a) names, logos, or trademarks of unaffiliated persons, unless the necessary consent has been obtained from them; and</li>
        <li>b) information that is false, misleading, or inaccurate.</li>
      </ul>
      </p>
      <p>9.5 By submitting, posting, displaying, or otherwise providing User Content on or through the Platform, you grant GetScreened a worldwide, non-exclusive, royalty-free license to reproduce, adapt, distribute, and publish the User Content in its sole discretion and in accordance with Clause 10 (Personal Data).</p>
      <p>9.6 We may review and remove any User Content that we determine, in our sole discretion</p>

      <h2 className="text-lg sm:text-xl">10. Limitation of Liability</h2>
      <p>10.1 To the maximum extent permitted by applicable law, GetScreened shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
      <p>
      <ol className='flex flex-col gap-2'>
        <li>a) your access to or use of or inability to access or use the Platform;</li>
        <li>b) any conduct or content of any third party on the Platform;</li>
        <li>c) any content obtained from the Platform; and</li>
        <li>d) unauthorized access, use, or alteration of your transmissions or content.</li>
      </ol>
      </p>
      <p>10.2 In no event shall GetScreened’s aggregate liability for all claims relating to the Platform exceed the amount paid by you, if any, for accessing the Platform in the past twelve months.</p>

      <h2 className="text-lg sm:text-xl">11. Governing Law and Jurisdiction</h2>
      <p>11.1 This Agreement shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.</p>
      <p>11.2 Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of Pune, India.</p>

      <h2 className="text-lg sm:text-xl">12. Indemnification</h2>
      <p>12.1 You agree to indemnify, defend, and hold harmless GetScreened, its affiliates, officers, directors, employees, agents, and licensors from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Platform, your violation of this Agreement, or your User Content.</p>

      <h2 className="text-lg sm:text-xl">13. Termination</h2>
      <p>13.1 We reserve the right to terminate or suspend your account and access to the Platform at our sole discretion, without notice, for conduct that we believe violates this Agreement or is harmful to other users of the Platform, us, or third parties, or for any other reason.</p>
      <p>13.2 Upon termination, you remain liable for all amounts due up to and including the date of termination.</p>

      <h2 className="text-lg sm:text-xl">14. Force Majeure</h2>
      <p>14.1 GetScreened shall not be liable for any failure or delay in the performance of its obligations under this Agreement if such delay or failure is due to causes beyond its reasonable control, including but not limited to acts of God, war, strikes, labor disputes, embargoes, government orders, or any other force majeure event.</p>
    </div>
    </div>

     
  </div>
  )
}

export default CompanyTerms
