import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { RiseLoader } from "react-spinners";
import {
  createAdminAddCandidateContext,
  deleteInterviewSlot,
} from "../graphql/mutations";
import { listInterviewSlots } from "../graphql/queries";
import awsExports from "../aws-exports";
import Header from "../components/header/Header";
import { MdCancel } from "react-icons/md";

API.configure(awsExports);
Storage.configure(awsExports);

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const AdminAddContext = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [adminCandidateProfile, setAdminCandidateProfile] = useState({
    candidateName: "",
    candidateVideo: null,
    candidateContextTextFile: null,
    candidateEmail: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [interviewSlots, setInterviewSlots] = useState([]);
  const [hasScheduledInterview, setHasScheduledInterview] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [searching, setSearching] = useState(false);
  const handleClick = () => {
    setShowModal(true);
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setEmail(""); // Clear email input field
    setInterviewSlots([]); // Clear response data
    setSearchPerformed(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "candidateVideo" || name === "candidateContextTextFile") {
      setAdminCandidateProfile({
        ...adminCandidateProfile,
        [name]: files[0],
      });
    } else {
      setAdminCandidateProfile({
        ...adminCandidateProfile,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if candidate name and email are filled
    if (
      !adminCandidateProfile.candidateName ||
      !adminCandidateProfile.candidateEmail
    ) {
      alert("Please fill in Candidate Name and Candidate Email.");
      return;
    }

    setLoading(true);
    const yourS3BucketName = "getscreenedstoragebucket71511-staging";
    try {
      const { candidateVideo, candidateContextTextFile } =
        adminCandidateProfile;

      // Upload video file if provided
      let videoURL = null;
      if (candidateVideo) {
        const videoKey = await Storage.put(
          candidateVideo.name,
          candidateVideo,
          {
            contentType: "video/*",
          }
        );
        videoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;
      }

      // Upload text file if provided
      let textFileURL = null;
      if (candidateContextTextFile) {
        const textFileKey = await Storage.put(
          candidateContextTextFile.name,
          candidateContextTextFile,
          {
            contentType: "text/plain",
          }
        );
        textFileURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${textFileKey.key}`;
      }

      // Create candidate context using GraphQL mutation
      await API.graphql({
        query: createAdminAddCandidateContext,
        variables: {
          input: {
            candidateName: adminCandidateProfile.candidateName,
            candidateEmail: adminCandidateProfile.candidateEmail,
            candidateVideo: videoURL,
            candidateContextTextFile: textFileURL,
          },
        },
      });

      // Display alert popup indicating success
      alert("Candidate context created successfully");

      // Reset form fields
      setAdminCandidateProfile({
        candidateName: "",
        candidateVideo: null,
        candidateContextTextFile: null,
        candidateEmail: "",
      });

      navigate("/AdminAddcontext");
    } catch (error) {
      console.error("Error creating candidate context:", error);
    } finally {
      setLoading(false);
    }
  };

  const candidateInfo = () => {
    navigate("/InterviewSlotBook");
  };
  const candidateEvalution = () => {
    navigate("/CompanyDashboard");
  };

  const handleSearch = async () => {
    if (email) {
      setSearching(true)
      let nextToken = null;
      let interviewSlots = [];
      do {
        try {
          const interviewSlotsResponse = await API.graphql(
            graphqlOperation(listInterviewSlots, {
              filter: { candidateEmail: { eq: email } },
              limit: 100,
              nextToken: nextToken,
            })
          );
          interviewSlots.push(
            ...interviewSlotsResponse.data.listInterviewSlots.items
          );
          nextToken = interviewSlotsResponse.data.listInterviewSlots.nextToken;
        } catch (error) {
          console.error("Error fetching interview slots:", error);
          break; // Exit loop on error
        }
      } while (nextToken);

      setInterviewSlots(interviewSlots);
      setHasScheduledInterview(interviewSlots.length > 0);
    }
    setSearching(false)
    setSearchPerformed(true);
    
  };

  const handleDeleteSlot = async (slotId) => {
    try {
      // Execute the deleteInterviewSlot mutation
      await API.graphql(
        graphqlOperation(deleteInterviewSlot, {
          input: { id: slotId },
        })
      );
      // If deletion is successful, remove the deleted slot from the state
      setInterviewSlots(interviewSlots.filter((slot) => slot.id !== slotId));

      setEmail("");
      
    } catch (error) {
      console.error("Error deleting interview slot:", error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col py-3">
          <Header />
          <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center mt-8">
            Admin Add Candidate Registration
          </h2>
          {loading ? (
            <div className="flex justify-center items-center">
              <RiseLoader
                color={"#ffffff"}
                loading={loading}
                css={override}
                size={40}
                aria-label="Loading Spinner"
              />
            </div>
          ) : (
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-1 md:grid-cols-2 gap-6"
            >
              <div>
                <label
                  htmlFor="candidateName"
                  className="block text-sm font-medium"
                >
                  Candidate Name
                </label>
                <input
                  type="text"
                  name="candidateName"
                  placeholder="e.g. John Doe"
                  value={adminCandidateProfile.candidateName}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="candidateEmail"
                  className="block text-sm font-medium"
                >
                  Candidate Email:
                </label>
                <input
                  type="email"
                  id="candidateEmail"
                  placeholder="e.g. atulnagose@gmail.com"
                  name="candidateEmail"
                  value={adminCandidateProfile.candidateEmail}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="candidateVideo"
                  className="block text-sm font-medium"
                >
                  Candidate Video
                </label>
                <input
                  type="file"
                  name="candidateVideo"
                  id="candidateVideo"
                  accept="video/*"
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="candidateContextTextFile"
                  className="block text-sm font-medium"
                >
                  Candidate Context Text File
                </label>
                <input
                  type="file"
                  name="candidateContextTextFile"
                  id="candidateContextTextFile"
                  accept=".txt"
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
              </div>
              {/* button Navigate to candidiate information */}
              <button
                type="candidiateInfo"
                className="w-full border-1 md:w-auto hover:bg-[#9900FF] font-medium py-2 px-4 rounded"
                onClick={candidateInfo}
              >
                Candidate Information
              </button>
              <button
                type="candidiateInfo"
                className="w-full border-1 md:w-auto hover:bg-[#9900FF] font-medium py-2 px-4 rounded"
                onClick={candidateEvalution}
              >
                Candidate Evalution
              </button>
              <button
                onClick={() => navigate("/CandidateInterviewSlotList")}
                className="w-full border-1 md:w-auto hover:bg-[#9900FF] font-medium py-2 px-4 rounded capitalize"
              >
                view interview Details
              </button>
              <button
                onClick={() => navigate("/UpdateCandidate")}
                className="w-full border-1 md:w-auto hover:bg-[#9900FF] font-medium py-2 px-4 rounded capitalize"
              >
                Update Candidate Data
              </button>
              {/*  */}

              <div className="md:col-span-2 flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    // Reset form fields
                    setAdminCandidateProfile({
                      candidateName: "",
                      candidateVideo: null,
                      candidateContextTextFile: null,
                      candidateEmail: "",
                    });
                  }}
                  className="w-full md:w-auto hover:bg-blue-700 text-white font-medium py-2 px-4 mr-4 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #9900ff",
                    color: "#9900ff",
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-full md:w-auto hover:bg-blue-700 font-medium py-2 px-4 rounded"
                  style={{
                    backgroundColor: "#9900ff",
                    border: "1px solid #9900ff",
                    color: "#fff",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
          <button
            type="CandidateRescheduleButton"
            className="w-full border-1 md:w-auto hover:bg-blue-700 font-medium py-2 px-4 rounded"
            onClick={handleClick}
          >
            Candidate Reschedule
          </button>

          {showModal && (
            <div className="fixed inset-0 flex items-center justify-center z-50 ">
              <div className="bg-[#b58aff] p-6 rounded-lg shadow-lg max-h-[80vh] overflow-y-auto">
                
                <div className="flex flex-col items-center">
                  <p className="   text-2xl font-semibold">Delete Interview Slot</p>
                  <div className="flex flex-row items-center">
                    <button
                      className="text-black rounded"
                      onClick={handleCloseModal}
                    >
                      <MdCancel />
                    </button>
                  </div>
                </div>



                {/* Email Input Field */}
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="mt-4 p-2 border border-gray-300 rounded w-full text-black"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                {/* Search Button */}
                <button
                  className="mt-4 bg-gray-500 w-full bg-gray border-1 md:w-auto font-medium py-2 px-4 rounded capitalize transition-colors duration-300 border-[#9900FF] hover:bg-[#9900FF] hover:text-white hover:border-transparent"
                  onClick={handleSearch}
                >
                  {/* Search */}
                  {searching ? 'Searching...' : 'Search'}
                </button>

                {/* Display Interview Slots or Message */}
                {interviewSlots.length > 0 ? (
                  <div className="mt-4">
                    {interviewSlots.map((slot) => (
                      <div
                        key={slot.id}
                        className="border-b border-gray-300 pb-2"
                      >
                        <p className="text-black">
                          candidateEmail: {slot.candidateEmail}
                        </p>
                        <p className="text-black">Date: {slot.date}</p>
                        <p className="text-black">
                          Start Time: {slot.startTime}
                        </p>
                        <p className="text-black">End Time: {slot.endTime}</p>

                        {/* Delete Button */}
                        <button
                          className="mt-2 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:bg-red-600"
                          onClick={() => handleDeleteSlot(slot.id)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="mt-4 text-black">
                    {searchPerformed
                      ? "No interview slots found for the provided email."
                      : ""}
                  </p>
                )}

                {/* Close Button */}
                {/* <button
                  className="mt-2 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:bg-red-600"
                  onClick={handleCloseModal}
                >
                  Close
                </button> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAddContext;
