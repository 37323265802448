import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Untitled from "../../assets/Untitled-design-3--unscreen.gif";
import { API, graphqlOperation } from "aws-amplify";
import { getCompanyProfile } from "../../graphql/queries";
import {
  createCompanyProfile,
  createCompanySubscription,
} from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
// import awsExports from "../aws-exports";
import CompanyTerms from "./CompanyTerms";
import Header from "../../components/header/Header";
import { FaWindowClose } from "react-icons/fa";

import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";

// API.configure(awsExports);

const CompanyRegistarFrom = () => {
  // const [formData, setFormData] = useState({
  //   userID: localStorage.getItem("candidateProfileAuthID") || "",
  //   email: localStorage.getItem("candidateProfileEmailID") || "",
  //   companyName: "",
  //   companyUrl: "",
  //   location: "",
  //   contactPersonName: "",
  //   contactPersonNumber: "",
  //   contactPersonDesignation: "",
  //   contactPersonEmail: "",
  //   t_c: false,
  //   companyUser: false,
  //   remainingCredit: 0,
  // });
  const [candidateEmail, setCandidateEmail] = useState("");
  const [userid, setUserid] = useState("");
  const [res, setRes] = useState();
  const navigate = useNavigate();

  // video
  const [modalOpen, setModalOpen] = useState(false);

  //

  useEffect(() => {
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );
    setCandidateEmail(candidateProfileEmailID);

    const candidateID = localStorage.getItem("candidateProfileAuthID");
    setUserid(candidateID);
  });

  // const handleChange = (e) => {

  //   const { name, value, checked, type } = e.target;
  //   let updatedFormData = {
  //     ...formData,
  //     [name]: type === 'checkbox' ? checked : value,
  //   };

  //   // If the checkbox for "Term & condition" is checked, update companyUser and remainingPoints fields
  //   if (name === 't_c' && checked) {
  //     updatedFormData = {
  //       ...updatedFormData,
  //       companyUser: true,
  //       remainingCredit: 150,
  //     };
  //   }

  //   setFormData(updatedFormData);

  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   console.log("its form data********************", formData)
  //   try {

  //     const inputdata = {

  //       userID: formData.userID,
  //       email: formData.email,
  //       companyName: formData.companyName,
  //       location: formData.location,
  //       companyUrl: formData.companyUrl,
  //       contactPersonName: formData.contactPersonName,
  //       contactPersonNumber: formData.contactPersonNumber,
  //       contactPersonDesignation: formData.contactPersonDesignation,
  //       contactPersonEmail: formData.contactPersonEmail,
  //       t_c: formData.t_c,
  //       companyUser: formData.companyUser,
  //       remainingCredit: formData.remainingCredit,
  //       // remainingPoints

  //     }
  //     console.log(inputdata)
  //     const response = await API.graphql(
  //       graphqlOperation(createCompanyProfile, { input: inputdata })
  //     );
  //     console.log('Response:', response);
  //     navigate('/CompanyHome')
  //     // navigate('/')
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }

  // };
  //  pop up

  // video code

  function YouTubeModal({ onClose }) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div className="bg-white p-4 rounded-lg relative">
          <button className="absolute top-0 right-0 m-1" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500 hover:text-gray-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Bz12gQzb-xA?autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  }

  // video
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // form mil code

  const signupschema = Yup.object({
    companyName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Company name must contain only letters")
      .min(2, "Company name must be at least 2 characters")
      .max(100, "Company name must not exceed 100 characters")
      .required("Please Enter Company Name"),
    companyUrl: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Please enter website"),
    location: Yup.string()
      // .matches(/^[A-Za-z\s]+$/, "Company location contain only letters")
      .min(2)
      .max(25)
      .required("Please enter Company Location"),
    contactPersonName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Contact person name must contain only letters")
      .min(2)
      .max(25)
      .required("Please enter Contact Person Name"),
    contactPersonNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits")
      .required("Mobile Number is required")
      .required("Please enter Contact Person Number"),

    contactPersonDesignation: Yup.string()
      // .typeError(' In-valid input')
      .matches(
        /^[A-Za-z\s-]+$/,
        "Company contactPersonDesignation contain only letters"
      )
      .min(2)
      .max(60)
      .required("Please enter Contact Person Designation"),
    // contactPersonEmail: Yup.string()
    //   .email()
    //   .required("Please enter Contact Person Email"),
    
  });

  const initialValues = {
    userID: localStorage.getItem("candidateProfileAuthID") || "",
    email: localStorage.getItem("candidateProfileEmailID") || "",
    companyName: "",
    companyUrl: "",
    location: "",
    contactPersonName: "",
    contactPersonNumber: "",
    contactPersonDesignation: "",
    // contactPersonEmail: "",
    t_c: false,
    companyUser: true,
    remainingCredit: 1000,
    CompanySubscriptionPlan: "trial",
    CompanySubscriptionStartDate: new Date().toISOString(),
    CompanySubscriptionEndDate: (() => {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 7);
      return endDate.toISOString();
    })(),
  };

  // const { values, errors, handleChange, touched, handleSubmit } = useFormik({
  //   initialValues: initialValues,
  //   validationSchema: signupschema,
  //   onSubmit: async (values) => {
  //     console.log("++++++++++++++++++++++++++++++++++++++", values);
  //     const response = await API.graphql(
  //       graphqlOperation(createCompanyProfile, { input: values })
  //     );
  //     console.log("Response:", response);
  //     navigate("/CompanyHome");
  //     localStorage.setItem("CompanyProfileID", response.id);
  //   },
  // });
  const { values, errors, handleChange, touched, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: signupschema,
    onSubmit: async (values) => {
      try {
        console.log("++++++++++++++++++++++++++++++++++++++", values);
        const response = await API.graphql(
          graphqlOperation(createCompanyProfile, { input: values })
        );
        console.log("Response:", response);

        // Store CompanyProfileID in local storage
        const companyProfileID = response.data.createCompanyProfile.id;
        localStorage.setItem("CompanyProfileID", companyProfileID);

        // Static values for company subscription
        const plan = "trial";
        const duration = "1 week";
        const price = 1000;
        const startDate = new Date().toISOString();
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 7); // Add 7 days for 1 week duration
        const endDateString = endDate.toISOString();

        // Create company subscription
        const subscriptionResponse = await API.graphql(
          graphqlOperation(createCompanySubscription, {
            input: {
              companyProfileID,
              plan,
              duration,
              price,
              startDate,
              endDate: endDateString,
            },
          })
        );
        console.log("Subscription Response:", subscriptionResponse);

        // Navigate to CompanyHome
        navigate("/CompanyHome");
      } catch (error) {
        console.error("Error creating company profile or subscription:", error);
      }
    },
  });

  // console.log("-----------------------", errors)

  return (
    <div className=" bg-gray-100 ">
      <Header />
      <div className="container mx-auto py-8 px-4">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 mb-8 md:mr-4 mt-20">
            <div className="intro-text bg-[#9900ff] rounded-lg p-6">
              <h2 className="text-2xl font-semibold mb-4">
                Introducing GetScreened.in - AI-powered Screening Platform
              </h2>
              <ul className="list-disc pl-5 mb-4 flex flex-col gap-3">
                <li>
                  We are excited to announce the early beta release of
                  GetScreened.in, an AI-based screening platform designed to
                  revolutionize the hiring process for companies. Our
                  cutting-edge technology streamlines candidate screening,
                  saving you time and effort in finding the perfect fit for your
                  organization.
                </li>
                <li>
                  As we prepare for our public launch in April, we invite
                  companies to express their interest and share their
                  requirements with us. By doing so, you'll gain early access to
                  our platform and experience the benefits of AI-driven
                  screening firsthand.
                </li>
                <li>
                  Fill out the form below to let us know your company's needs,
                  and our team will be in touch to provide you with exclusive
                  early access to GetScreened.in in a short time.
                </li>
              </ul>
              <div className="text-center">
                <img src={Untitled} alt="" className="mx-auto h-[200px] " />
              </div>
              {/*  */}
              <div className="text-center mt-2">
                <a
                  href="#"
                  id="viewDemoBtn"
                  className="text-blue-500 hover:underline"
                  onClick={openModal}
                >
                  How GetScreened works?
                </a>
                {modalOpen && <YouTubeModal onClose={closeModal} />}
              </div>
              {/*  */}
              {/* <div className="text-center mt-2">
                <a href="#" id="viewDemoBtn" className="text-blue-500 hover:underline">How GetScreened works?</a>
              </div> */}
              {/*  */}
            </div>
          </div>
          <div className=" md:w-1/2 border-1 p-4 rounded-lg ">
            <form
              onSubmit={handleSubmit}
              className="form-container w-full text-center flex flex-col gap-2"
              id="recruiterForm"
            >
              <h1 className="text-2xl mb-4 capitalize">
                Become part of our beta customers !!!
              </h1>
              <div className="mb-4 flex flex-col w-full">
                {/* <label className='text-left'>Company Name :</label> */}
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  className="bg-transparent border-1 rounded-lg p-2"
                  placeholder="Company Name"
                  required
                />
                {touched.companyName && errors.companyName ? (
                  <p className="text-sm text-red-700">{errors.companyName}</p>
                ) : null}
              </div>
              <div className="mb-4 flex flex-col w-full ">
                {/* <label className='px-2' >Company Location</label> */}
                <input
                  className=" bg-transparent border-1 rounded-lg p-2"
                  type="text"
                  id="location"
                  name="location"
                  value={values.location}
                  onChange={handleChange}
                  placeholder="Company Location"
                  required
                />
                {touched.location && errors.location ? (
                  <p className="text-sm text-red-700">{errors.location}</p>
                ) : null}
              </div>
              <div className="mb-4 flex flex-col w-full">
                {/* <label className='px-2' >Company Website</label> */}
                <input
                  className="bg-transparent border-1 rounded-lg p-2"
                  type="text"
                  id="companyUrl"
                  name="companyUrl"
                  value={values.companyUrl}
                  onChange={handleChange}
                  placeholder="Company Website"
                  required
                />
                {touched.companyUrl && errors.companyUrl ? (
                  <p className="text-sm text-red-700">{errors.companyUrl}</p>
                ) : null}
              </div>
              <div className="mb-4 flex flex-col w-full">
                {/* <label>Contact Person Name</label> */}
                <input
                  className="bg-transparent border-1 rounded-lg p-2"
                  type="text"
                  id="contactPersonName"
                  name="contactPersonName"
                  value={values.contactPersonName}
                  onChange={handleChange}
                  placeholder="Contact Person Name"
                  required
                ></input>
                {touched.contactPersonName && errors.contactPersonName ? (
                  <p className="text-sm text-red-700">
                    {errors.contactPersonName}
                  </p>
                ) : null}
              </div>
              <div className="mb-4 flex flex-col w-full">
                {/* <label>Contact Person Number:</label> */}
                <input
                  className="bg-transparent border-1 rounded-lg p-2"
                  type="text"
                  name="contactPersonNumber"
                  value={values.contactPersonNumber}
                  onChange={handleChange}
                  placeholder="Contact Person Number"
                  required
                />
                {touched.contactPersonNumber && errors.contactPersonNumber ? (
                  <p className="text-sm text-red-700">
                    {errors.contactPersonNumber}
                  </p>
                ) : null}
              </div>
              <div className="mb-4 flex flex-col w-full">
                {/* <label>Contact Person Designation:</label> */}
                <input
                  className="bg-transparent border-1 rounded-lg p-2"
                  type="text"
                  name="contactPersonDesignation"
                  value={values.contactPersonDesignation}
                  onChange={handleChange}
                  placeholder="Contact Person Designation"
                  required
                />
                {touched.contactPersonDesignation &&
                errors.contactPersonDesignation ? (
                  <p className="text-sm text-red-700">
                    {errors.contactPersonDesignation}
                  </p>
                ) : null}
              </div>
              <div className="mb-4 flex flex-col w-full">
                {/* <label>Contact Person Email:</label> */}
                {/* <input
                  className="bg-transparent border-1 rounded-lg p-2"
                  type="text"
                  name="contactPersonEmail"
                  value={values.contactPersonEmail}
                  onChange={handleChange}
                  placeholder="Contact Person Email"
                  required
                />
                {touched.contactPersonEmail && errors.contactPersonEmail ? (
                  <p className="text-sm text-red-700">
                    {errors.contactPersonEmail}
                  </p>
                ) : null} */}
              </div>
              <div className="mb-4 flex  w-full gap-3 items-center justify-center">
                {/* <label>Contact Person Email:</label> */}
                <div className="flex gap-3 justify-center ">
                  <div>
                    <input
                      className=""
                      type="checkbox"
                      name="t_c"
                      checked={values.t_c}
                      onChange={handleChange}
                      placeholder=""
                      required
                    />
                  </div>
                  <div>
                    <h6 className="text-center items-center">
                      By accepting you are agree with our{" "}
                      <Link to={"/CompanyTerms"} target="_blank" className="">
                        Terms & condition
                      </Link>{" "}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="submit-btn bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-md font-bold "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyRegistarFrom;
