import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SidebarNavigation from "./SidebarNavigation";
import JobDetails from "./JobDetails";
import JobDetailsList from "../../pages/Company/JobDetailsList ";
import SendMail from "../../pages/Company/SendMail";
import Header from "../header/Header";

function CompanyJdform() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showSidebar, setShowSidebar] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(<JobDetailsList />);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    if (isMobile) {
      setShowSidebar(!showSidebar);
    }
  };

  const handleNavigation = (component) => {
    switch (component) {
      case "JobDetails":
        setCurrentComponent(<JobDetails />);
        break;
      case "JobDetailsList":
        setCurrentComponent(<JobDetailsList />);
        break;
      case "SendMail":
        setCurrentComponent(<SendMail />);
        break;
      default:
        setCurrentComponent(<JobDetails />);
        break;
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />
      <div style={{ display: "flex", flex: 1 }}>
        {/* <SidebarNavigation
          isMobile={isMobile}
          showSidebar={showSidebar}
          toggleSidebar={toggleSidebar}
          handleNavigation={handleNavigation}
        /> */}
        <div style={{ flex: 1 }}>{currentComponent}</div>
      </div>
    </div>
  );
}

export default CompanyJdform;
