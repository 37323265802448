import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  getCompanyProfile,
  listCompanySubscriptions,
  listPrivateScreenings,
} from "../../graphql/queries";
import {
  updateCompanyProfile,
  updateCompanySubscription,
} from "../../graphql/mutations";
import Header from "../../components/header/Header";

const CompanyProfile = () => {
  const [currentPoints, setCurrentPoints] = useState(0);
  const [totalScans, setTotalScans] = useState(0);
  const [revenuePerScan, setRevenuePerScan] = useState(300);
  const [currentPackage, setCurrentPackage] = useState({
    name: "Trial",
    startDate: "",
    endDate: "",
  });

  const [transactionHistory, setTransactionHistory] = useState([]);
  const [packageHistory, setPackageHistory] = useState([]);
  const [showUpcomingPackage, setShowUpcomingPackage] = useState(false);

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        const companyProfileID = localStorage.getItem("CompanyProfileID");
        const response = await API.graphql(
          graphqlOperation(getCompanyProfile, { id: companyProfileID })
        );
        const profile = response.data.getCompanyProfile;

        setCurrentPoints(profile.remainingCredit);
        setCurrentPackage({
          name: profile.CompanySubscriptionPlan,
          startDate: new Date(
            profile.CompanySubscriptionStartDate
          ).toLocaleDateString(),
          endDate: new Date(
            profile.CompanySubscriptionEndDate
          ).toLocaleDateString(),
        });

        const screeningsResponse = await API.graphql(
          graphqlOperation(listPrivateScreenings, {
            filter: { companyProfileID: { eq: companyProfileID } },
          })
        );
        const screenings = screeningsResponse.data.listPrivateScreenings.items;
        setTotalScans(screenings.length);
        const transactions = screenings.map((screening) => ({
          date: new Date(screening.createdAt).toLocaleString(),
          type: "Screening",
          name: screening.candidateName,
          sendMail: screening.candidateEmail,
          deduction: screening.pointsDeducted,
          balance: currentPoints - screening.pointsDeducted,
        }));

        setTransactionHistory(transactions);
      } catch (error) {
        console.error(
          "Error fetching company profile and transaction history:",
          error
        );
      }
    };

    const fetchPackageHistory = async () => {
      try {
        const companyProfileID = localStorage.getItem("CompanyProfileID");
        const response = await API.graphql(
          graphqlOperation(listCompanySubscriptions, {
            filter: { companyProfileID: { eq: companyProfileID } },
          })
        );
        const packages = response.data.listCompanySubscriptions.items;
        packages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPackageHistory(packages);
      } catch (error) {
        console.error("Error fetching package history:", error);
      }
    };

    fetchCompanyProfile();
    fetchPackageHistory();
  }, []);

  const handleButtonClick = (isUpcoming) => {
    setShowUpcomingPackage(isUpcoming);
  };

  const handleActivatePackage = async (pkg) => {
    const confirmation = window.confirm(
      "Are you sure you want to activate this package?"
    );
    if (!confirmation) return;

    const startDate = new Date();
    const endDate = new Date(startDate);

    // Adjust endDate based on package duration
    endDate.setMonth(endDate.getMonth() + parseInt(pkg.duration));

    const companyProfileID = localStorage.getItem("CompanyProfileID");

    try {
      await API.graphql(
        graphqlOperation(updateCompanyProfile, {
          input: {
            id: companyProfileID,
            CompanySubscriptionStartDate: startDate.toISOString(),
            CompanySubscriptionEndDate: endDate.toISOString(),
          },
        })
      );

      await API.graphql(
        graphqlOperation(updateCompanySubscription, {
          input: {
            id: pkg.id,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        })
      );

      alert("Package activated successfully!");
      // Optionally, refresh the component state to reflect the changes
    } catch (error) {
      console.error("Error activating package:", error);
      alert("Failed to activate the package. Please try again.");
    }
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto py-8">
        <div className="bg-black shadow-md rounded-lg p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h2 className="text-xl font-bold mb-2">Current Points</h2>
              <p className="text-4xl font-bold text-yellow-600">
                ₹{currentPoints}
              </p>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-2">Total Private Screen</h2>
              <p className="text-4xl font-bold">{totalScans}</p>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-2">Cost Per Screened</h2>
              <p className="text-4xl font-bold">
                ₹<span className="line-through">{revenuePerScan}</span> 200
              </p>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-2">Current Package</h2>
              <p className="text-lg">
                {currentPackage.name} - {currentPackage.startDate} to{" "}
                {currentPackage.endDate}
              </p>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2">
                Buy Package
              </button>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-xl font-bold mb-4">Transaction History</h2>
            <div className="overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-black">
                    <th className="px-4 py-2 text-yellow-600">CreatedAt</th>
                    <th className="px-4 py-2 text-yellow-600">Type</th>
                    <th className="px-4 py-2 text-yellow-600">Name</th>
                    <th className="px-4 py-2 text-yellow-600">Send Mail</th>
                    <th className="px-4 py-2 text-yellow-600">Deduction ₹</th>
                    <th className="px-4 py-2 text-yellow-600">Balance ₹</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionHistory.map((txn, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-black" : "bg-black"}
                    >
                      <td className="border px-4 py-2 text-yellow-600">
                        {txn.date}
                      </td>
                      <td className="border px-4 py-2 text-yellow-600">
                        {txn.type}
                      </td>
                      <td className="border px-4 py-2 text-yellow-600">
                        {txn.name}
                      </td>
                      <td className="border px-4 py-2 text-yellow-600">
                        {txn.sendMail}
                      </td>
                      <td className="border px-4 py-2 text-yellow-600">
                        {txn.deduction}
                      </td>
                      <td className="border px-4 py-2 text-yellow-600">
                        {txn.balance}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-xl font-bold mb-4">Package History</h2>
            <div className="flex justify-center space-x-4">
              <button
                className={`px-4 py-2 ${
                  showUpcomingPackage
                    ? "bg-black text-yellow-400"
                    : "bg-yellow-400 text-black"
                } rounded hover:bg-yellow-500 hover:text-black transition-colors duration-300`}
                onClick={() => handleButtonClick(false)}
              >
                Package History
              </button>
              <button
                className={`px-4 py-2 ${
                  showUpcomingPackage
                    ? "bg-yellow-400 text-black"
                    : "bg-green-500 text-white"
                } rounded hover:bg-yellow-500 hover:text-black transition-colors duration-300`}
                onClick={() => handleButtonClick(true)}
              >
                Upcoming Package
              </button>
            </div>
            {showUpcomingPackage ? (
              <div className="overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-black">
                      <th className="px-4 py-2 text-yellow-600">CreatedAt</th>
                      <th className="px-4 py-2 text-yellow-600">Package</th>
                      <th className="px-4 py-2 text-yellow-600">Duration</th>
                      <th className="px-4 py-2 text-yellow-600">Credit ₹</th>
                      <th className="px-4 py-2 text-yellow-600">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {packageHistory
                      .filter(
                        (pkg) =>
                          new Date(pkg.startDate).getTime() ===
                            new Date("1970-01-01").getTime() ||
                          new Date(pkg.endDate).getTime() ===
                            new Date("1970-01-01").getTime()
                      )
                      .map((pkg, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "bg-black" : "bg-black"}
                        >
                          <td className="border px-4 py-2 text-yellow-600">
                            {new Date(pkg.createdAt).toLocaleString()}
                          </td>
                          <td className="border px-4 py-2 text-yellow-600">
                            {pkg.plan}
                          </td>
                          <td className="border px-4 py-2 text-yellow-600">
                            {pkg.duration === 0
                              ? ""
                              : pkg.duration === "1 week"
                              ? pkg.duration
                              : pkg.duration === 1
                              ? `${pkg.duration} Month`
                              : `${pkg.duration} Months`}
                          </td>
                          <td className="border px-4 py-2 text-yellow-600">
                            ₹{pkg.price}
                          </td>
                          <td className="border px-4 py-2 text-yellow-600">
                            <button
                              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-300"
                              onClick={() => handleActivatePackage(pkg)}
                            >
                              Activate Package
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-black">
                      <th className="px-4 py-2 text-yellow-600">CreatedAt</th>
                      <th className="px-4 py-2 text-yellow-600">Package</th>
                      <th className="px-4 py-2 text-yellow-600">Duration</th>
                      <th className="px-4 py-2 text-yellow-600">Start Date</th>
                      <th className="px-4 py-2 text-yellow-600">End Date</th>
                      <th className="px-4 py-2 text-yellow-600">Credit ₹</th>
                    </tr>
                  </thead>
                  <tbody>
                    {packageHistory
                      .filter(
                        (pkg) =>
                          !(
                            new Date(pkg.startDate).getTime() ===
                              new Date("1970-01-01").getTime() ||
                            new Date(pkg.endDate).getTime() ===
                              new Date("1970-01-01").getTime()
                          )
                      )
                      .map((pkg, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "bg-black" : "bg-black"}
                        >
                          <td className="border px-4 py-2 text-yellow-600">
                            {new Date(pkg.createdAt).toLocaleString()}
                          </td>
                          <td className="border px-4 py-2 text-yellow-600">
                            {pkg.plan}
                          </td>
                          <td className="border px-4 py-2 text-yellow-600">
                            {pkg.duration === 0
                              ? ""
                              : pkg.duration === "1 week"
                              ? pkg.duration
                              : pkg.duration === 1
                              ? `${pkg.duration} Month`
                              : `${pkg.duration} Months`}
                          </td>
                          <td className="border px-4 py-2 text-yellow-600">
                            {new Date(pkg.startDate).toLocaleDateString()}
                          </td>
                          <td className="border px-4 py-2 text-yellow-600">
                            {new Date(pkg.endDate).toLocaleDateString()}
                          </td>
                          <td className="border px-4 py-2 text-yellow-600">
                            ₹{pkg.price}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
