import React, { useState, useEffect } from "react";
function CompanyJdform() {
  // State variables to manage dynamic behavior
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [categoriesData, setCategory] = useState([]);
  const [compensation, setCompensation] = useState(""); // Add state for compensation
  const [benefits, setBenefits] = useState(""); // Add state for benefits
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Check if screen size is below 768px
  const [showSidebar, setShowSidebar] = useState(false); // State variable to control sidebar visibility

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Toggle sidebar visibility when hamburger icon is clicked
  const toggleSidebar = () => {
    if (isMobile) {
      // Toggle sidebar visibility only on mobile devices
      setShowSidebar(!showSidebar);
    }
  };

  // Event handler for category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  // Event handler for experience level selection
  const handleExperienceSelect = (experience) => {
    setSelectedExperience(experience);
  };

  // Event handler for search input change
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  // Event handler for adding search input to selected category
  const handleSearchInputEnter = () => {
    if (searchInput.trim() !== "") {
      setCategory([...categoriesData, searchInput]); // Add new category to categoriesData
      setSelectedCategory(searchInput);
      setSearchInput("");
    }
  };

  // Event handler for removing a category
  const handleRemoveCategory = (categoryToRemove) => {
    setCategory(
      categoriesData.filter((category) => category !== categoryToRemove)
    ); // Filter out the category to remove
    if (selectedCategory === categoryToRemove) {
      setSelectedCategory(""); // Clear selected category if it's removed
    }
  };

  // Render category buttons with remove icons
  const renderCategoryButtons = () => {
    return categoriesData.map((category, index) => (
      <div key={index} style={{ display: "flex", alignItems: "center" }}>
        <button
          style={{
            padding: "5px 15px",
            backgroundColor:
              selectedCategory === category ? "#9900ff" : "#f3f3f3",
            borderRadius: "15px",
            cursor: "pointer",
            transition: "background-color 0.3s",
            marginRight: "5px",
            marginBottom: "5px",
            color: selectedCategory === category ? "#fff" : "#000",
            border: "1px solid #9900ff",
          }}
          onClick={() => handleCategorySelect(category)}
        >
          {category}
        </button>
        <span
          style={{ marginLeft: "5px", cursor: "pointer" }}
          onClick={() => handleRemoveCategory(category)}
        >
          x
        </span>
      </div>
    ));
  };

  // Render experience level buttons
  const renderExperienceButtons = () => {
    const experienceLevels = [
      { label: "Fresher\nJust out of college", value: "Fresher" },
      { label: "Entry Level\n1-3 years", value: "Entry Level" },
      { label: "Mid Level\n3-5 years", value: "Mid Level" },
      { label: "Senior Level\n5+ years", value: "Senior Level" },
    ];

    return experienceLevels.map((experience, index) => (
      <button
        key={index}
        style={{
          backgroundColor:
            selectedExperience === experience.value ? "#9900ff" : "#1b1935",
          border: "1px solid #9900ff",
          borderRadius: "5px",
          padding: "15px 10px",
          textAlign: "center",
          fontSize: "14px",
          cursor: "pointer",
          flexGrow: "1",
          margin: "0 5px",
          transition: "border-color 0.3s ease",
        }}
        onClick={() => handleExperienceSelect(experience.value)}
      >
        {experience.label}
      </button>
    ));
  };

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      {/* Hamburger icon */}
      <div
        style={{
          display: isMobile ? "block" : "none",
          position: "fixed",
          top: "20px",
          left: "20px",
          zIndex: "999",
          color: "#ffffff",
          fontSize: "24px",
          cursor: "pointer",
        }}
        onClick={toggleSidebar}
      >
        &#9776;
      </div>

      {/* Sidebar */}
      <div
        style={{
          flexBasis: isMobile ? (showSidebar ? "250px" : "0") : "250px",
          backgroundColor: "#0f1221",
          padding: "40px",
          borderRadius: "6px",
          transition: "flex-basis 0.3s",
          display: isMobile ? (showSidebar ? "block" : "none") : "block",
        }}
      >
        {/* Back button icon (visible on larger screens) */}
        {!isMobile && (
          <a
            href="./companydashboard.html"
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#ffffff",
              textDecoration: "none",
              fontSize: "24px",
            }}
          >
            <i className="fas fa-arrow-left"></i>
          </a>
        )}

        {/* Navigation links */}
        <ul style={{ listStyle: "none", padding: 0, marginTop: "8vh" }}>
          {
            <>
              <li>
                <a
                  href="./company-job-details.html"
                  style={{
                    textDecoration: "none",
                    color: "#ffffff",
                    display: "block",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #9900ff",
                    marginBottom: "16px",
                  }}
                >
                  Job details
                </a>
              </li>
              <li>
                <a
                  href="./company-job-Compensationbenefits.html"
                  style={{
                    textDecoration: "none",
                    color: "#ffffff",
                    display: "block",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #9900ff",
                    marginBottom: "16px",
                  }}
                >
                  Compensation & benefits
                </a>
              </li>
            </>
          }
        </ul>

        {/* Voice Assist */}
        <div style={{ marginTop: "375px" }}>
          <img
            src="./images/voiceAssist.gif"
            alt="Voice Assistance"
            style={{
              width: "119%",
              marginLeft: "-10%",
              borderRadius: "4px",
            }}
          />
        </div>
      </div>

      {/* Main Content */}
      <div
        style={{
          flexGrow: "1",
          padding: "20px",
          border: "2px solid #9900ff",
          borderRadius: "6px",
        }}
      >
        <h1
          style={{
            color: "#ffffff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          New Job
        </h1>

        {/* Compensation Input */}
        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="compensation"
            style={{ display: "block", color: "#ffffff", marginBottom: "8px" }}
          >
            Compensation
          </label>
          <input
            type="text"
            id="compensation"
            placeholder="Enter compensation details"
            value={compensation}
            onChange={(e) => setCompensation(e.target.value)}
            style={{
              width: "97%",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
          />
        </div>

        {/* Benefits Input */}
        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="benefits"
            style={{ display: "block", color: "#ffffff", marginBottom: "8px" }}
          >
            Benefits
          </label>
          <textarea
            id="benefits"
            placeholder="Enter benefits details"
            rows="4"
            value={benefits}
            onChange={(e) => setBenefits(e.target.value)}
            style={{
              width: "97%",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
          ></textarea>
        </div>

        {/* Buttons */}
        <div style={{ textAlign: "right" }}>
          <a
            href="./companydashboard.html"
            className="btn btn-cancel"
            style={{
              padding: "10px 15px",
              marginLeft: "10px",
              backgroundColor: "#d9534f",
              color: "#ffffff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Cancel
          </a>
          <a
            href="./companydashboard.html"
            className="btn btn-save"
            style={{
              padding: "10px 15px",
              marginLeft: "10px",
              backgroundColor: "#5cb85c",
              color: "#ffffff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Save & Next
          </a>
        </div>
      </div>
    </div>
  );
}

export default CompanyJdform;
