import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { PopupWidget } from "react-calendly";
import logo from "../assets/logo.svg"; // Adjusted import path for logo.svg
import recruiterImage from "../assets/candidate.svg"; // Path to recruiter image
import candidateImage from "../assets/Student.svg"; // Path to candidate image
import Header from "../components/header/Header";
import "./CompanyPage.css";

function CandidateSchedule() {
  // Create a ref to hold the root element
  const rootElementRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    // After the component mounts, setIsMounted is set to true
    setIsMounted(true);
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col py-3">
          {/* Header */}
          <div className="d-flex justify-content-between align-items-center dashboard-header border-bottom pb-2 w-100">
            {/* Company logo */}
            <div>
              <img src={logo} alt="Company Logo" style={{ height: "64px" }} />
            </div>
            {/* User profile icon */}
            <div>
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{ fontSize: "1.5rem" }}
              />
            </div>
          </div>

          {/* Centered content */}
          <div className="d-flex flex-column align-items-center justify-content-center py-3">
            {/* Div for Schedule button */}
            <div className="mb-4">
              {/* Use the ref to specify the root element */}
            </div>

            {/* Div for benefit and instruction information */}
            <div className="row position-relative">
              <div className="col-md-6 mb-4 position-relative d-flex">
                <div className="p-3 border rounded animate__animated animate__fadeInRight d-flex flex-column justify-content-between align-items-center">
                  <div>
                    <img
                      src={candidateImage}
                      alt="Candidate"
                      className="img-fluid mb-3"
                    />
                    <h3 className="text-center font-weight-bold mb-3">
                      Candidate
                    </h3>
                    <p>
                      1. Experience fair and unbiased AI-powered interviews that
                      focus on your skill set.
                    </p>
                    <p>
                      2. Skill Assessment: Get evaluated on your true abilities,
                      not just your resume.
                    </p>
                    <p>
                      3. Instant Feedback: Receive immediate insights to improve
                      your interview skills.
                    </p>
                    <p>
                      Exposure to Top Companies: Your profile is accessible to a
                      wide array of employers seeking your expertise.
                    </p>
                    <p>
                      4. Job Matching: AI algorithms match you with job
                      opportunities that fit your unique skills and potential.
                    </p>
                  </div>
                  <div className="diagonal-split"></div>
                  <a
                    style={{
                      width: "fit-content",
                      backgroundColor: "#9900ff",
                      color: "#fff",
                      zIndex: 1,
                    }}
                    className="btn btn-md"
                  >
                    Access
                  </a>
                </div>
              </div>

              <div className="col-md-6 mb-4 position-relative d-flex">
                <div className="p-3 border rounded animate__animated animate__fadeInLeft d-flex flex-column justify-content-between align-items-center">
                  <div>
                    <img
                      src={recruiterImage}
                      alt="Recruiter"
                      className="img-fluid mb-3"
                    />
                    <h3 className="text-center font-weight-bold mb-3">
                      Recruiter
                    </h3>
                    <p>
                      1. Automates screening, presenting only top-tier
                      candidates.
                    </p>
                    <p>
                      2. Customizable Interviews: Tailor questions to
                      company-specific needs.
                    </p>
                    <p>3. Efficiency: Drastically cuts down time-to-hire.</p>
                    <p>
                      4. Quality of Hire: Enhances candidate quality through
                      skill-based matching.
                    </p>
                    <p>
                      5. HR Integration: Seamless workflow with existing HR
                      systems.
                    </p>
                  </div>
                  <div className="diagonal-split"></div>
                  <a
                    style={{
                      width: "fit-content",
                      backgroundColor: "#9900ff",
                      color: "#fff",
                      zIndex: 1,
                    }}
                    className="btn btn-md"
                  >
                    Access
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidateSchedule;
