import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { listCompanyProfiles } from "../../graphql/queries";
import { listCandidateProfiles } from "../../graphql/queries";

const ProfileModal = ({
  isOpen,
  onClose,
  onHome,
  onLogoutClick,
  onAdminaddcontext,
  onProfileClickUpdate,
}) => {
  const candidateProfileEmailID = localStorage.getItem(
    "candidateProfileEmailID"
  );
  const [iscompany, setIscompany] = useState();

  const [candidateProfileExists, setCandidateProfileExists] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let nextToken = null;
        let userProfileList = [];
        do {
          const CompanyResponse = await API.graphql(
            graphqlOperation(listCompanyProfiles, {
              filter: { email: { eq: candidateProfileEmailID } },
              limit: 100,
              nextToken: nextToken,
            })
          );

          setIscompany(CompanyResponse.data.listCompanyProfiles.items[0]);
          userProfileList.push(
            ...CompanyResponse.data.listCompanyProfiles.items
          );
          nextToken = CompanyResponse.data.listCompanyProfiles.nextToken;
        } while (nextToken);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // checking candidate profile id
    async function checkcandidateProfile() {
      try {
        let nextToken = null;
        let candidateProfile = null;
        const userInfo = await Auth.currentAuthenticatedUser();
        const userEmail = userInfo.attributes.email.toLowerCase();
        console.log("yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyttttttttttttttttttttttttttttttt",userEmail)

        do {
          const candidateProfileData = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              filter: {
                // originnal code start
                // userID: {
                //   eq: localStorage.getItem("candidateProfileAuthID"),
                // },
                // original code end
                email: {
                  eq: userEmail,
                },
              },
              limit: 100, // Adjust the limit as per your requirement
              nextToken: nextToken,
            })
          );

          const items = candidateProfileData.data.listCandidateProfiles.items;
          if (items.length > 0) {
            candidateProfile = items[0];
            setCandidateProfileExists(true);
            localStorage.setItem("candidateProfileId", candidateProfile.id);
            localStorage.setItem(
              "candidateProfile",
              JSON.stringify(candidateProfile)
            );
            break; // Exit the loop since a profile is found
          } else {
            // Continue to next iteration if there are more records to fetch
            nextToken =
              candidateProfileData.data.listCandidateProfiles.nextToken;
          }
        } while (nextToken);

        // If no profile found after exhausting all pages
        if (!candidateProfile) {
          console.log("No profile found for this candidate ID.");
          setCandidateProfileExists(false);
        }
      } catch (error) {
        console.error("Error fetching candidate profile", error);
        setCandidateProfileExists(false);
      }
    }

    checkcandidateProfile();
  }, []);

  //test code start local null

  const handleLogout = () => {
    
    // localStorage.clear();

    const keysToRemove = [
      'candidateProfileEmailID',
      'candidateProfileAuthID',
      'candidateProfileId',
      'candidateProfile',
      'CompanyProfileID',
      'CompanyEmailID',
      'CompanyProfile'
    ];
    keysToRemove.forEach(key => {
      localStorage.removeItem(key);
    });

    // Call the logout function passed from props
    onLogoutClick();
  };

  //test code end local null


  // console.log("@@@@@@@@@@@@@@@@@@@@@@",candidateProfileExists)


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="relative w-auto max-w-md mx-auto my-6">
        <div className="relative flex flex-col w-full bg-white border-2 border-gray-300 rounded-md shadow-md outline-none focus:outline-none">
          <div className="flex flex-col items-center justify-between p-5 border-b border-solid border-gray-300 rounded-t">
            <h3 className="text-lg font-semibold  text-black">
              {candidateProfileEmailID}
            </h3>
            <button
              // className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              className="py-1 px-3 border-1 text-black"
              onClick={onClose}
            >
              {/* <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span> */}
              x
            </button>
          </div>
          <div className="relative p-6 flex-auto">
            <button
              className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
              onClick={onHome}
            >
              Home
            </button>
            {
              //   (candidateProfileEmailID && iscompany) ? (
              //     <h1></h1>
              //   ) : (

              //     <button
              //   className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
              //   onClick={onProfileClickUpdate}
              // >
              //   Edit User Profile
              // </button>
              //   )

              candidateProfileExists ? (
                <button
                  className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
                  onClick={onProfileClickUpdate}
                >
                  Edit User Profile
                </button>
              ) : iscompany ? (
                <button
                  className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200" 
                >
                  Company Profile
                </button>
              ) : (
                <h1></h1>
              )
            }
            {/* <button
              className="w-full py-2 my-2 text-center text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
              onClick={onProfileClickUpdate}
            >
              Edit User Profile
            </button> */}
            {candidateProfileEmailID ===
              "getscreened@varahitechnologies.com" && (
              <button
                className="w-full py-2 my-2 text-center text-green-700 border border-green-300 rounded hover:bg-green-200"
                onClick={onAdminaddcontext}
              >
                Admin
              </button>
            )}
            <Link to={"/"}>
              <button
                className="w-full py-2 my-2 text-center text-red-700 border border-red-300 rounded hover:bg-red-200"
                // onClick={onLogoutClick}  original code
                //test code start
                onClick={handleLogout}
                // test code end
              >
                signOut
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function Header({ signOut }) {
  const [showEmail, setShowEmail] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateEmail, setCandidateEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );
    setCandidateEmail(candidateProfileEmailID);
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleWindowResize = () => {
    if (window.innerWidth <= 500) {
      setShowEmail(false);
    } else {
      setShowEmail(true);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    console.log("Modal toggled");
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center dashboard-header border-bottom pb-2 w-100">
        <Link to={"/"}>
          <div>
            <img src={logo} alt="Company Logo" style={{ height: "64px" }} />
          </div>
        </Link>
        <div className="d-flex align-items-center">
          {showEmail && <span className="mr-2">{candidateEmail}</span>}
          <FontAwesomeIcon
            icon={faUserCircle}
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={toggleModal}
            id="userId"
          />
          <div>
            {isModalOpen && (
              <ProfileModal
                isOpen={isModalOpen}
                onClose={toggleModal}
                onHome={() => {
                  navigate("/CandidateDashboard");
                }}
                onProfileClickUpdate={() => {
                  navigate("/EditCandidateProfileReg");
                }}
                onAdminaddcontext={() => {
                  navigate("/AdminAddcontext");
                }}
                onLogoutClick={signOut}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(Header);
