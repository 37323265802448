import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listAdminAddCandidateContexts,
  listInterviewEvaluations,
  listCandidateProfiles,
} from "../graphql/queries";
import { updateCandidateProfile } from "../graphql/mutations";
import logo from "../assets/logo.svg"; // Adjusted import path for logo.svg
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faUserCircle } from "@fortawesome/free-solid-svg-icons"; // Importing user circle icon
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import "./CandidateResumeDetails.css"; // Import CSS file for styling
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import { FaFacebook, FaInstagram, FaLinkedin, FaLock, FaMailchimp, FaWhatsapp } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { FaLockOpen } from "react-icons/fa";
import profilephoto from "../assets/profile.png";
import { LinkedinFilled } from "@ant-design/icons";

const fetchDataFromAPIs = async (
  candidateProfileEmailId,
  setProfiles,
  setInterviewEvaluations,
  setCandidateProfile,
  setPrivateCandidate,
  setLinkedinurl
) => {
  try {
    // Fetch data from the first API (listAdminAddCandidateContexts)
    let nextToken = null;
    let adminContextItems = [];
    do {
      const adminContextResponse = await API.graphql(
        graphqlOperation(listAdminAddCandidateContexts, {
          filter: {
            candidateEmail: { eq: candidateProfileEmailId },
          },
          limit: 100, // Adjust the limit as per your requirement
          nextToken: nextToken,
        })
      );
      adminContextItems = [
        ...adminContextItems,
        ...adminContextResponse.data.listAdminAddCandidateContexts.items,
      ];
      nextToken =
        adminContextResponse.data.listAdminAddCandidateContexts.nextToken;
    } while (nextToken);
    const filteredProfiles = adminContextItems;
    setProfiles(filteredProfiles);

    // Fetch data from the second API (listInterviewEvaluations)
    nextToken = null;
    let interviewEvaluationsItems = [];
    do {
      const interviewEvaluationsResponse = await API.graphql(
        graphqlOperation(listInterviewEvaluations, {
          filter: {
            candidateEmail: { eq: candidateProfileEmailId },
          },
          limit: 100, // Adjust the limit as per your requirement
          nextToken: nextToken,
        })
      );
      interviewEvaluationsItems = [
        ...interviewEvaluationsItems,
        ...interviewEvaluationsResponse.data.listInterviewEvaluations.items,
      ];
      nextToken =
        interviewEvaluationsResponse.data.listInterviewEvaluations.nextToken;
    } while (nextToken);
    setInterviewEvaluations(interviewEvaluationsItems);

    // Fetch candidate profile data (listCandidateProfiles)
    nextToken = null;
    let candidateProfileItem = null;
    do {
      const candidateProfileResponse = await API.graphql(
        graphqlOperation(listCandidateProfiles, {
          filter: {
            email: { eq: candidateProfileEmailId },
          },
          limit: 100, // Adjust the limit as per your requirement
          nextToken: nextToken,
        })
      );
      const items = candidateProfileResponse.data.listCandidateProfiles.items;

      if (items.length > 0) {
        candidateProfileItem = items[0];
        break; // Exit the loop since a profile is found
      } else {
        // Continue to next iteration if there are more records to fetch
        nextToken =
          candidateProfileResponse.data.listCandidateProfiles.nextToken;
      }
    } while (nextToken);

    setCandidateProfile(candidateProfileItem);
    setLinkedinurl(candidateProfileItem.linkedinURL)
    setPrivateCandidate(candidateProfileItem.private_Candidate);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const CandidateResumeDetails = (signOut) => {
  const [profiles, setProfiles] = useState([]);
  const candidateProfileEmailId = localStorage.getItem(
    "candidateProfileEmailID"
  );
  const [candidateProfile, setCandidateProfile] = useState(null); // State to hold candidate profile details
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [candidateEmail, setCandidateEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const navigate = useNavigate();
  const [gettingdata, setGettingdata] = useState(false);
  const [updateprivate, setUpdateprivate] = useState(false);
  const [privateCandidate, setPrivateCandidate] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [linkedinurl,setLinkedinurl] =useState("")
 

  const [isPublic, setIsPublic] = useState(
    // candidateProfile ? candidateProfile.isPublic : true
    candidateProfile && candidateProfile.isPublic === true ? true : false
  );

  console.log("is private ###################", candidateProfile);
  console.log("is private canndiadte ###################", privateCandidate);

  const handleTogglePublic = () => {
    const newIsPublicValue = !isPublic;
    setIsPublic(newIsPublicValue);
    updateIsPublicInDatabase(newIsPublicValue);
  };

  // share original code start

  // const handleShare = () => {
  //   const encodedEmail = encodeURIComponent(candidateProfileEmailId);
  //   const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
  //   navigator.clipboard.writeText(shareableURL);
  //   setCopied(true);

  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 5000); // 5000 milliseconds = 5 seconds
  // };

  // share orinial code end

  //  share test start

  // const handleShare = () => {
  //   setShowDropdown(!showDropdown);
  // };

  // const handleShareVia = (platform) => {
  //   const encodedEmail = encodeURIComponent(candidateProfileEmailId);
  //   const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
  //   // const shareableURL = `${window.location.origin}/profile`;
  //   let shareURL = "";

  //   switch (platform) {
  //     case "whatsapp":
  //       shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareableURL)}`;
  //       break;
  //     case "linkedin":
  //       shareURL = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareableURL)}`;
  //       break;
  //     case "facebook":
  //       shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareableURL)}`;
  //       break;
  //     case "instagram":
  //       shareURL = `https://www.instagram.com/share?url=${encodeURIComponent(shareableURL)}`;
  //       break;
  //     case "email":
  //       // You can customize the email body as needed
  //       const subject = encodeURIComponent("Check out this profile");
  //       const body = encodeURIComponent(`Hi, I thought you might be interested in this profile: ${shareableURL}`);
  //       shareURL = `mailto:?subject=${subject}&body=${body}`;
  //       break;
  //     default:
  //       break;
  //   }

  //   window.open(shareURL);

  //   // Close dropdown after sharing
  //   setShowDropdown(false);

  //   // Set copied state to true
  //   setCopied(true);

  //   // Reset copied state after 5 seconds
  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 5000);
  // };

  // const handleCopyURL = () => {
  //   const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodeURIComponent(candidateProfileEmailId)}`;
  //   navigator.clipboard.writeText(shareableURL);
  //   setCopied(true);
  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 5000);
  // };
  // share test end

  // share test 1  start
  const ShareModal = ({ isOpen, onClose, onShareVia, onCopyURL, copied }) => {
    const modalRef = useRef();

    const handleCloseModal = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleCloseModal);
      return () => {
        document.removeEventListener('mousedown', handleCloseModal);
      };
    }, []);

    return (

      <>
        {isOpen && (
          <div className="fixed inset-0 flex items-end justify-center z-10">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="absolute top-96" ref={modalRef}>
              <div className="rounded-md shadow-lg p-6 flex flex-col items-center" style={{
                border: '1px solid purple',
                background: "rgb(13 12 26 / 88%)",
                padding: '39px 44px'
              }} >
                <button className="absolute top-2 right-2 " onClick={onClose}>
                  &times;
                </button>
                <div className="flex flex-col gap-2">
                  <div>
                    <h6 className="text-center">Share with</h6>
                  </div>
                  <div className="flex gap-3" >
                    <button onClick={onCopyURL}><FontAwesomeIcon icon={faCopy} className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("whatsapp")}   > <FaWhatsapp className="h-[32px] w-[35px]" />  </button>
                    <button onClick={() => onShareVia("linkedin")}><FaLinkedin className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("facebook")}><FaFacebook className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("instagram")}><FaInstagram className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("email")}><BiLogoGmail className="h-[32px] w-[35px]" /></button>
                  </div>
                </div>
                {copied && <div>Link Copied!</div>}
              </div>
            </div>
          </div>
        )}
      </>

    );
  };


  const handleShare = () => {
    setShowModal(!showModal);
  };

  const handleShareVia = (platform) => {
    const encodedEmail = encodeURIComponent(candidateProfileEmailId);
    const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
    let shareURL = "";

    switch (platform) {
      case "whatsapp":
        shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareableURL)}`;
        break;
      case "linkedin":
        shareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareableURL)}`;
        break;
      case "facebook":
        shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareableURL)}`;
        break;
      case "instagram":
        shareURL = `https://www.instagram.com/share?url=${encodeURIComponent(shareableURL)}`;
        break;
      case "email":
        const subject = encodeURIComponent("Check out this profile");
        const body = encodeURIComponent(`Hi, I thought you might be interested in this profile: ${shareableURL}`);
        shareURL = `mailto:?subject=${subject}&body=${body}`;
        break;
      default:
        break;
    }

    window.open(shareURL);
    setShowModal(false);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const handleCopyURL = () => {
    const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodeURIComponent(candidateProfileEmailId)}`;
    navigator.clipboard.writeText(shareableURL);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  //share test 1 end



  const TogglePublic = ({ isPublic, handleTogglePublic }) => {
    return (
      <div className="toggle-public mb-7 flex flex-col gap-3">
        {/* <label className="toggle-label">
        <input
          type="checkbox"
          checked={isPublic}
          onChange={handleTogglePublic}
          className="toggle-checkbox"
          value={isPublic}
        />
        <span className="toggle-button"></span>
        <span className="toggle-text">{isPublic ? "Public" : "Private"}</span>
        <span className="company-label">
          {isPublic ? "Company for Public" : "Company for Private"}
        </span>
      </label> */}

        {/* <button className="" onClick={changestate}>
        {
          candidateProfile.isPublic ? <span className="bg-green-600 p-2 capitalize">Public</span> : <span className="bg-red-400 p-2 capitalize rounded-sm">private</span>
        }
      </button> */}
        <div>
          <t5 className="capitalize font-medium">
            control who can view your profile
          </t5>
        </div>
        <div>
          <button
            onClick={changestate}
            className={`p-2 capitalize ${candidateProfile.isPublic
              ? "bg-green-600 rounded-lg uppercase"
              : "bg-red-400 rounded-lg uppercase"
              }`}
          >
            {candidateProfile.isPublic ? <FaLockOpen /> : <FaLock />}
          </button>

          <span className="ml-3 text-gray-500">
            {candidateProfile.isPublic === true
              ? "Visible to everyone"
              : "visible for editor"}
          </span>
        </div>
      </div>
    );
  };

  // true false

  const changestate = () => {
    console.log(
      "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",
      candidateProfile.isPublic
    );
    const change = !candidateProfile.isPublic;
    setGettingdata(change);
    // setIsPublic(change)
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", change);
    updateIsPublicInDatabase(change);
  };
  console.log(">>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<", isPublic);
  // Fetch candidate profile details from localStorage on component mount
  useEffect(() => {
    const candidateProfileEmailId = localStorage.getItem(
      "candidateProfileEmailID"
    );
    if (candidateProfileEmailId) {
      const storedProfile = JSON.parse(
        localStorage.getItem("candidateProfile")
      );
      setCandidateProfile(storedProfile);
    }
  }, []);

  console.log("Inter view evalution data", interviewEvaluations);
  console.log(typeof interviewEvaluations);

  useEffect(() => {
    fetchDataFromAPIs(
      candidateProfileEmailId,
      setProfiles,
      setInterviewEvaluations,
      setCandidateProfile,
      setPrivateCandidate,
      setLinkedinurl
    );
  }, [candidateProfileEmailId, gettingdata, updateprivate]);

  // console.log("profiles test1", profiles);
  // console.log("interviewEvaluations test2 responce from namdev sir", interviewEvaluations);
  // console.log("candidateProfile test3",candidateProfile)

  // Function to handle showing profile info after interview completion

  useEffect(() => {
    // Retrieve the candidateProfileEmailID from local storage
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );

    // Update the content of the span element with the candidateProfileEmailID
    setCandidateEmail(candidateProfileEmailID);
  }, []);
  // Function to toggle modal

  const updateIsPublicInDatabase = async (isPublicValue) => {
    try {
      const input = {
        id: candidateProfile.id,
        isPublic: isPublicValue,
      };

      setUpdateprivate(true);

      const result = await API.graphql(
        graphqlOperation(updateCandidateProfile, { input })
      );

      console.log(
        "Updated isPublic:",
        result.data.updateCandidateProfile.isPublic
      );
    } catch (error) {
      console.error("Error updating isPublic:", error);
      updateprivate(false);
    }
  };

  // const linkedinURL = candidateProfile.linkedinURL;
  // console.log("23232323232323232323233223",linkedinurl)

  // const formattedURL = linkedinurl.startsWith('https://')
  //   ? linkedinurl  // If it starts with 'https://', use it as-is
  //   : `https://${linkedinurl}`; 

  let formattedURL = '';
  if (linkedinurl) {
    formattedURL = linkedinurl.startsWith('https://')
      ? linkedinurl  // If it starts with 'https://', use it as-is
      : `https://${linkedinurl}`;
  } else {
    // Handle the case where linkedinurl is null or undefined
    formattedURL = 'https://linkedin.com'; // Replace with your default URL or handle accordingly
  }




  return (
    <div>
      {/* Header Section */}
      <Header />

      {/* Container for centering content */}
      <div className="container" style={{ color: "#fff", width: "80%" }}>
        {" "}
        {/* Increased width to 80% */}
        {/* Your JSX code to display candidate details */}
        {profiles &&
          profiles.map((profile, index) => (
            <div className="card" key={index} style={{ marginTop: "50px" }}>
              <div className="card-body">
                <div
                  className="profile-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src={candidateProfile.photoURL || profilephoto}
                      alt="dev"
                      className="profile-img"
                    />
                    <div style={{ marginTop: "15px" }}>
                      <h5 className="card-title" style={{ color: "#fff" }}>
                        {/* {`${candidateProfile.fullName}`} */}
                        {/* {`${pfullName}`} */}
                        Name : {`${candidateProfile.fullName}`}
                      </h5>
                      <strong style={{ color: "#fff" }}>
                        {" "}
                        Work Status+: {candidateProfile.workStatus}
                      </strong>
                      <strong style={{ color: "#fff" }}>
                        {" "}
                        Job Position : {candidateProfile.roleApplyFor}
                      </strong>
                      {/* Assuming workStatus represents experience */}
                      <br />
                    </div>
                  </div>

                  {
                    candidateProfile.linkedinURL && (
                      <div className="ml-11" style={{ marginLeft: '6.5rem', marginBottom: '17px' }}>
                        <a href={formattedURL}  target="_blank" rel="noopener noreferrer">
                          <i className="fa-brands fa-linkedin" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                        </a>
                      </div>
                    )
                  }

                  {

                    candidateProfile.githubURL && (
                      <div className="" style={{ marginLeft: '1rem', marginBottom: '17px' }}>
                        <a href={`${candidateProfile.githubURL}`} target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-github" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                        </a>
                      </div>
                    )

                  }


                  <div style={{ marginLeft: '1rem', marginBottom: '0.5rem', marginBottom: '0.5rem' }}>
                    <div className="share-dropdown">
                      <button className="share-button text-2xl" onClick={handleShare}>
                        <FontAwesomeIcon icon={faShare} />
                      </button>
                    </div>
                    <ShareModal
                      isOpen={showModal}
                      onClose={() => setShowModal(false)}
                      onShareVia={handleShareVia}
                      onCopyURL={handleCopyURL}
                      copied={copied}
                    />
                  </div>




                </div>
                <br />
                <p className="card-text" style={{ color: "#fff" }}>
                  {candidateProfile.description}
                </p>

                <div className="skills items-center gap-1 mt-10">
                  <strong style={{ color: "#fff" }}>Skills :</strong>
                  <br />
                  {candidateProfile.keySkills &&
                    candidateProfile.keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}
                </div>

                <TogglePublic
                  isPublic={isPublic}
                  handleTogglePublic={handleTogglePublic}
                />

                <h5 className="card-title" style={{ color: "#fff" }}>
                  Interview Video
                </h5>
                <video
                  controls
                  style={{ width: "100%", border: "2px solid #ccc" }}
                  controlsList="nodownload" // Add controlsList attribute here
                  onContextMenu={(e) => e.preventDefault()} // Disable right-click context menu
                  onContextMenuCapture={(e) => e.preventDefault()} 
                >
                  <source src={profile.candidateVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="transcript-container">
                  <CustomizedResumeText
                    candidateContextTextFile={profile.candidateContextTextFile}
                  />
                </div>
                <div className="transcript-container">
                  <CustomizedResumeRating
                    candidateContextTextFile={profile.candidateContextTextFile}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      {/*  */}
      <div className="container" style={{ color: "#fff", width: "80%" }}>
        {" "}
        {/* Increased width to 80% */}
        {/* Your JSX code to display candidate details */}
        {interviewEvaluations &&
          interviewEvaluations.map((evaluation, index) => (
            <div className="card" key={index} style={{ marginTop: "50px" }}>
              <div className="card-body">
                <div
                  className="profile-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src={candidateProfile.photoURL || profilephoto}
                      alt="dev"
                      className="profile-img"
                    />
                    <div style={{ marginTop: "15px" }}>
                      <h5 className="card-title" style={{ color: "#fff" }}>
                        {/* {`${candidateProfile.fullName}`} */}
                        {/* {`${pfullName}`} */}
                        Name : {`${candidateProfile.fullName}`}
                      </h5>
                      <strong style={{ color: "#fff" }}>
                        {" "}
                        Work Status-: {candidateProfile.workStatus}
                      </strong>
                      <br></br>
                      <strong style={{ color: "#fff" }}>
                        {" "}
                        Job Position : {candidateProfile.roleApplyFor}
                      </strong>
                      <br></br>
                      {/* Assuming workStatus represents experience */}
                      {/* Button to open LinkedIn profile */}
                      <br />
                    </div>
                  </div>
                  {/* original share code start */}
                  {/* <div>
                    <button onClick={handleShare}>
                      {copied ? (
                        "✓ Copied Link!"
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ cursor: "pointer" }}
                          />
                          {" GetScreened Link"}
                        </>
                      )}
                    </button>
                  </div> */}
                  {/* original share code end */}

                  {/* test */}
                  {/* <div className="ml-11" style={{ marginLeft: '6.5rem', marginBottom: '17px' }}>
                    <a href={`https://${candidateProfile.linkedinURL}`} target="_blank" rel="noopener noreferrer">
                      <i className="fa-brands fa-linkedin" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                    </a>
                  </div>
                  <div className="" style={{ marginLeft: '1rem', marginBottom: '17px' }}>
                    <a href={`${candidateProfile.githubURL}`} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-github" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                    </a>
                  </div> */}
                  {
                    candidateProfile.linkedinURL && (
                      <div className="ml-11" style={{ marginLeft: '6.5rem', marginBottom: '17px' }}>
                        <a href={formattedURL}target="_blank" rel="noopener noreferrer">
                          <i className="fa-brands fa-linkedin" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                        </a>
                      </div>
                    )
                  }

                  {

                    candidateProfile.githubURL && (
                      <div className="" style={{ marginLeft: '1rem', marginBottom: '17px' }}>
                        <a href={`${candidateProfile.githubURL}`} target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-github" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                        </a>
                      </div>
                    )

                  }

                  {/* <div>
                    {candidateProfile.linkedinURL && (
                      <div className="ml-11" style={{ marginLeft: '6.5rem', marginBottom: '17px' }}>
                        <a href={`https://${candidateProfile.linkedinURL}`} target="_blank" rel="noopener noreferrer">
                          <i className="fa-brands fa-linkedin" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                        </a>
                      </div>
                    )}
                    {candidateProfile.githubURL && (
                      <div className="" style={{ marginLeft: '1rem', marginBottom: '17px' }}>
                        <a href={`${candidateProfile.githubURL}`} target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-github" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                        </a>
                      </div>
                    )}
                  </div> */}


                  <div style={{ marginLeft: '1rem', marginBottom: '0.5rem', marginBottom: '0.5rem' }}>
                    <div className="share-dropdown">
                      <button className="share-button text-2xl" onClick={handleShare}>
                        <FontAwesomeIcon icon={faShare} />
                      </button>
                    </div>
                    <ShareModal
                      isOpen={showModal}
                      onClose={() => setShowModal(false)}
                      onShareVia={handleShareVia}
                      onCopyURL={handleCopyURL}
                      copied={copied}
                    />
                  </div>



                  {/* tetst */}


                </div>
                <br />
                <p className="card-text" style={{ color: "#fff" }}>
                  {candidateProfile.description}
                </p>

                {/* <div className="skills">
                  <strong style={{ color: "#fff" }}>Skills</strong>
                  <br />
                  {candidateProfile.keySkills &&
                    candidateProfile.keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}
                </div> */}

                <div className="skills items-center gap-1 mt-10">
                  <strong style={{ color: "#fff" }}>Skills :</strong>
                  <br />
                  {candidateProfile.keySkills &&
                    candidateProfile.keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}
                </div>

                <TogglePublic
                  isPublic={isPublic}
                  handleTogglePublic={handleTogglePublic}
                />
                {
                  // privateCandidate !== true ? (
                  //   <div>
                  //     <h5 className="card-title" style={{ color: "#fff" }}>
                  //       Interview Video
                  //     </h5>
                  //     <video
                  //       controls
                  //       style={{ width: "100%", border: "2px solid #ccc" }}
                  //     >
                  //       <source src={evaluation.videoURL} type="video/mp4" />
                  //       Your browser does not support the video tag.
                  //     </video>
                  //     <h5
                  //       className="card-title"
                  //       style={{ color: "#fff", margin: "1rem" }}
                  //     >
                  //       Interview Transcript
                  //     </h5>
                  //     <div className="transcript-container">
                  //       {evaluation.transcript &&
                  //         JSON.parse(evaluation.transcript).map((item, idx) => (
                  //           <div key={idx}>
                  //             <p>
                  //               <strong>{item.speaker}:</strong> {item.message}
                  //             </p>
                  //           </div>
                  //         ))}
                  //     </div>
                  //     <h5
                  //       className="card-title"
                  //       style={{ color: "#fff", margin: "1rem" }}
                  //     >
                  //       Interview Evaluation
                  //     </h5>
                  //     <div className="transcript-container">
                  //       {evaluation.evaluation &&
                  //         Object.entries(JSON.parse(evaluation.evaluation)).map(
                  //           ([criterion, data], idx) => (
                  //             <div key={idx}>
                  //               <p>
                  //                 <strong>{criterion}:</strong>{" "}
                  //                 {data.score ? data.score : "N/A"} /{" "}
                  //                 {data.max_score ? data.max_score : "N/A"}
                  //               </p>
                  //               <p>
                  //                 <strong>Comments:</strong>{" "}
                  //                 {data.comments ? data.comments : "N/A"}
                  //               </p>
                  //             </div>
                  //           )
                  //         )}
                  //       {/* Add more evaluation criteria as needed */}
                  //     </div>
                  //   </div>
                  // ) : (
                  //   <div className="border border-gray-300 rounded p-4" style={{ width: '100%', border: '0.5px solid #fff9' }}>
                  //     <div className="flex flex-col items-center mb-1">
                  //       <div className="w-8 h-8 flex items-center justify-center mr-2">
                  //         <p className="text-xs font-semibold" style={{ marginBottom: '0.5rem', padding: '0% 6%' }}>Note</p>
                  //       </div>
                  //       {/* <h1 className="" style={{ fontSize: '2.5rem', marginBottom: '20px', fontWeight: '100', color: '#fff9', textAlign: 'justify', lineHeight: '1.75rem', justifyContent: 'end', textAlign: '-webkit-center' }}>
                  //         You are a private screening candidate. Your evaluation results will be shared with the company.
                  //       </h1> */}
                  //       <h1 className=" text-[#fff9] font-light text-lg mb-2 lg:text-center lg:justify-end">
                  //         You are a private screening candidate. Your evaluation results will be shared with the company.
                  //       </h1>
                  //     </div>
                  //   </div>
                  // )
                }
                {privateCandidate !== true ? (
                  <div>
                    <h5 className="card-title" style={{ color: "#fff" }}>
                      Interview Video
                    </h5>
                    <video
                      controls
                      style={{ width: "100%", border: "2px solid #ccc" }}
                      controlsList="nodownload" 
                      onContextMenu={(e) => e.preventDefault()} // Disable right-click context menu
                      onContextMenuCapture={(e) => e.preventDefault()} 
                    >
                      <source src={evaluation.videoURL} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <h5
                      className="card-title"
                      style={{ color: "#fff", margin: "1rem" }}
                    >
                      Interview Transcript
                    </h5>
                    <div className="transcript-container">
                      {evaluation.transcript &&
                        JSON.parse(evaluation.transcript).map((item, idx) => (
                          <div key={idx}>
                            <p>
                              <strong>{item.speaker}:</strong> {item.message}
                            </p>
                          </div>
                        ))}
                    </div>
                    <h5
                      className="card-title"
                      style={{ color: "#fff", margin: "1rem" }}
                    >
                      Interview Evaluation
                    </h5>
                    <div className="transcript-container">
                      {evaluation.evaluation &&
                        Object.entries(JSON.parse(evaluation.evaluation)).map(
                          ([criterion, data], idx) => (
                            <div key={idx}>
                              <p>
                                <strong>{criterion}:</strong>{" "}
                                {data.score ? data.score : "N/A"} /{" "}
                                {data.max_score ? data.max_score : "N/A"}
                              </p>
                              <p>
                                <strong>Comments:</strong>{" "}
                                {data.comments ? data.comments : "N/A"}
                              </p>
                            </div>
                          )
                        )}
                      {/* Add more evaluation criteria as needed */}
                    </div>
                  </div>
                ) : privateCandidate === true ? (
                  <div
                    className="border border-gray-300 rounded p-4"
                    style={{ width: "100%", border: "0.5px solid #fff9" }}
                  >
                    <div className="flex flex-col items-center mb-1">
                      <div className="w-8 h-8 flex items-center justify-center mr-2">
                        <p
                          className="text-xl font-semibold text-red-600"
                          style={{ marginBottom: "0.5rem", padding: "0% 6%" }}
                        >
                          Note
                        </p>
                      </div>
                      <h1 className="text-[#fff9] font-light text-lg mb-2 lg:text-center lg:justify-end">
                        You are a private screening candidate. Your evaluation
                        results will be shared with the company.
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className="text-white">Not yet screened</div>
                )}
                {/* original code start */}
                {/* <h5 className="card-title" style={{ color: "#fff" }}>
                  Interview Video
                </h5>
                <video
                  controls
                  style={{ width: "100%", border: "2px solid #ccc" }}
                >
                  <source src={evaluation.videoURL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h5
                  className="card-title"
                  style={{ color: "#fff", margin: "1rem" }}
                >
                  Interview Transcript
                </h5>
                <div className="transcript-container">
                  {evaluation.transcript &&
                    JSON.parse(evaluation.transcript).map((item, idx) => (
                      <div key={idx}>
                        <p>
                          <strong>{item.speaker}:</strong> {item.message}
                        </p>
                      </div>
                    ))}
                </div>
                <h5
                  className="card-title"
                  style={{ color: "#fff", margin: "1rem" }}
                >
                  Interview Evaluation
                </h5>
                <div className="transcript-container">
                  {evaluation.evaluation &&
                    Object.entries(JSON.parse(evaluation.evaluation)).map(
                      ([criterion, data], idx) => (
                        <div key={idx}>
                          <p>
                            <strong>{criterion}:</strong>{" "}
                            {data.score ? data.score : "N/A"} /{" "}
                            {data.max_score ? data.max_score : "N/A"}
                          </p>
                          <p>
                            <strong>Comments:</strong>{" "}
                            {data.comments ? data.comments : "N/A"}
                          </p>
                        </div>
                      )
                    )}
                  
                </div> */}
                {/* original code end */}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeText = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);

        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>TranScript</h5>
        <pre>{filteredResumeText}</pre>
      </div>
    </>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeRating = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);
        // console.log("text",text)
        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>Evaluation</h5>
        <pre>{candidateRating}</pre>
      </div>
    </>
  );
};

export default withAuthenticator(CandidateResumeDetails);
