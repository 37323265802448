import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listAdminAddCandidateContexts,
  listInterviewEvaluations,
} from "../../graphql/queries";
// import logo from "../assets/logo.svg"; // Adjusted import path for logo.svg
import logo from "../../assets/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons"; // Importing user circle icon
// import "./CompanyCandidateViewProfile.css"; // Import CSS file for styling
import "./AdminCandidateViewProfile.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import profilephoto from "../../assets/profile.png";
import Header from "../../components/header/Header";
import { useParams } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaLock, FaMailchimp, FaWhatsapp } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { FaLockOpen } from "react-icons/fa";
import { LinkedinFilled } from "@ant-design/icons";
import { faShare, faUserCircle } from "@fortawesome/free-solid-svg-icons"; // Importing user circle icon


const AdminCandidateViewProfile = () => {
  const [profiles, setProfiles] = useState([]);
  const candidateProfileEmailId = localStorage.getItem(
    "candidateProfileEmailID"
  );
  const [candidateProfile, setCandidateProfile] = useState(null); // State to hold candidate profile details
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [candidateEmail, setCandidateEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const navigate = useNavigate();
  let a = 1;


  // testing start
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [workStatus, setWorkStatus] = useState("");
  const [description, setDescription] = useState("");
  const [keySkills, setKeySkills] = useState("");
  const [copied, setCopied] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // Other state variables for props

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setEmail(searchParams.get("email"));
    setFullName(searchParams.get("fullName"));
    setPhotoURL(searchParams.get("photoURL"));
    setWorkStatus(searchParams.get("workStatus"));
    setDescription(searchParams.get("description"));
    setKeySkills(searchParams.get("keySkills"));
    // Set other state variables accordingly
  }, [location.search]);

  // testing end

  console.log(
    "$$$$$$$$$$$$$$$$$$$$all date$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$",
    email,
    fullName,
    workStatus,
    description,
    keySkills
  );

  // const handleShare = () => {
  //   const encodedEmail = encodeURIComponent(email);
  //   const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
  //   navigator.clipboard.writeText(shareableURL);
  //   setCopied(true);

  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 5000); // 5000 milliseconds = 5 seconds
  // };

  // share code start

  const ShareModal = ({ isOpen, onClose, onShareVia, onCopyURL, copied }) => {
    const modalRef = useRef();

    const handleCloseModal = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleCloseModal);
      return () => {
        document.removeEventListener('mousedown', handleCloseModal);
      };
    }, []);

    return (

      <>
        {isOpen && (
          <div className="fixed inset-0 flex items-end justify-center z-10">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="absolute top-96" ref={modalRef}>
              <div className="rounded-md shadow-lg p-6 flex flex-col items-center" style={{
                border: '1px solid purple',
                background: "rgb(13 12 26 / 88%)",
                padding: '39px 44px'
              }} >
                <button className="absolute top-2 right-2 " onClick={onClose}>
                  &times;
                </button>
                <div className="flex flex-col gap-2">
                  <div>
                    <h6 className="text-center">Share with</h6>
                  </div>
                  <div className="flex gap-3" >
                    <button onClick={onCopyURL}><FontAwesomeIcon icon={faCopy} className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("whatsapp")}   > <FaWhatsapp className="h-[32px] w-[35px]" />  </button>
                    <button onClick={() => onShareVia("linkedin")}><FaLinkedin className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("facebook")}><FaFacebook className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("instagram")}><FaInstagram className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("email")}><BiLogoGmail className="h-[32px] w-[35px]" /></button>
                  </div>
                </div>
                {copied && <div>Link Copied!</div>}
              </div>
            </div>
          </div>
        )}
      </>

    );
  };


  const handleShare = () => {
    setShowModal(!showModal);
  };

  const handleShareVia = (platform) => {
    const encodedEmail = encodeURIComponent(email);
    const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
    let shareURL = "";

    switch (platform) {
      case "whatsapp":
        shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareableURL)}`;
        break;
      case "linkedin":
        shareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareableURL)}`;
        break;
      case "facebook":
        shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareableURL)}`;
        break;
      case "instagram":
        shareURL = `https://www.instagram.com/share?url=${encodeURIComponent(shareableURL)}`;
        break;
      case "email":
        const subject = encodeURIComponent("Check out this profile");
        const body = encodeURIComponent(`Hi, I thought you might be interested in this profile: ${shareableURL}`);
        shareURL = `mailto:?subject=${subject}&body=${body}`;
        break;
      default:
        break;
    }

    window.open(shareURL);
    setShowModal(false);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const handleCopyURL = () => {
    const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodeURIComponent(email)}`;
    navigator.clipboard.writeText(shareableURL);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  // share code end


  // console.log("all date",keySkills)

  // original code start

  // const fetchDataFromAPIs = async (
  //   candidateProfileEmailId,
  //   setProfiles,
  //   setInterviewEvaluations,
  //   email
  // ) => {
  //   try {
  //     let nextToken = null;
  //     const adminContextItems = [];
  //     const interviewEvaluationsItems = [];

  //     // Fetch data from the first API with pagination
  //     do {
  //       const adminContextResponse = await API.graphql(
  //         graphqlOperation(listAdminAddCandidateContexts, {
  //           filter: {
  //             candidateEmail: { eq: email },
  //           },
  //           limit: 100,
  //           nextToken: nextToken,
  //         })
  //       );
  //       const adminContextPageItems =
  //         adminContextResponse.data.listAdminAddCandidateContexts.items;
  //       adminContextItems.push(...adminContextPageItems);
  //       nextToken =
  //         adminContextResponse.data.listAdminAddCandidateContexts.nextToken;
  //     } while (nextToken);

  //     // Filter profiles based on candidateProfileEmailId
  //     const filteredProfiles = adminContextItems.filter(
  //       (profile) => profile.candidateEmail === email
  //     );
  //     setProfiles(filteredProfiles);

  //     nextToken = null; // Reset nextToken for the next API call

  //     // Fetch data from the second API with pagination
  //     do {
  //       const interviewEvaluationsResponse = await API.graphql(
  //         graphqlOperation(listInterviewEvaluations, {
  //           filter: {
  //             candidateEmail: { eq: email },
  //           },
  //           limit: 100,
  //           nextToken: nextToken,
  //         })
  //       );
  //       const interviewEvaluationsPageItems =
  //         interviewEvaluationsResponse.data.listInterviewEvaluations.items;
  //       interviewEvaluationsItems.push(...interviewEvaluationsPageItems);
  //       nextToken =
  //         interviewEvaluationsResponse.data.listInterviewEvaluations.nextToken;
  //     } while (nextToken);

  //     // Set interview evaluations
  //     setInterviewEvaluations(interviewEvaluationsItems);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // console.log("Profile+++++++++++++++++++++++++++++++++++++",profiles)
  // console.log("interview evalution+++++++++++++++++++++++++++++++++++++++++++++", interviewEvaluations)

  // // Fetch candidate profile details from localStorage on component mount
  // // useEffect(() => {
  // //   const candidateProfileEmailId = localStorage.getItem(
  // //     "candidateProfileEmailID"
  // //   );
  // //   if (candidateProfileEmailId) {
  // //     const storedProfile = JSON.parse(
  // //       localStorage.getItem("candidateProfile")
  // //     );
  // //     setCandidateProfile(storedProfile);
  // //   }
  // // }, []);

  // useEffect(() => {
  //   fetchDataFromAPIs(
  //     candidateProfileEmailId,
  //     setProfiles,
  //     setInterviewEvaluations,
  //     email
  //   );

  // }, [candidateEmail]);

  // original code end

  useEffect(() => {
    const fetchData = async () => {
      try {
        let nextToken = null;
        const adminContextItems = [];
        const interviewEvaluationsItems = [];

        // Fetch admin contexts
        do {
          const adminContextResponse = await API.graphql(
            graphqlOperation(listAdminAddCandidateContexts, {
              filter: {
                candidateEmail: { eq: email },
              },
              limit: 100,
              nextToken: nextToken,
            })
          );
          const adminContextPageItems =
            adminContextResponse.data.listAdminAddCandidateContexts.items;
          adminContextItems.push(...adminContextPageItems);
          nextToken =
            adminContextResponse.data.listAdminAddCandidateContexts.nextToken;
        } while (nextToken);

        // Filter profiles based on candidate email
        const filteredProfiles = adminContextItems.filter(
          (profile) => profile.candidateEmail === email
        );
        setProfiles(filteredProfiles);

        nextToken = null; // Reset nextToken for the next API call

        // Fetch interview evaluations
        do {
          const interviewEvaluationsResponse = await API.graphql(
            graphqlOperation(listInterviewEvaluations, {
              filter: {
                candidateEmail: { eq: email },
              },
              limit: 100,
              nextToken: nextToken,
            })
          );
          const interviewEvaluationsPageItems =
            interviewEvaluationsResponse.data.listInterviewEvaluations.items;
          interviewEvaluationsItems.push(...interviewEvaluationsPageItems);
          nextToken =
            interviewEvaluationsResponse.data.listInterviewEvaluations
              .nextToken;
        } while (nextToken);

        // Set interview evaluations
        setInterviewEvaluations(interviewEvaluationsItems);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (email) {
      fetchData();
    }
  }, [email]);

  console.log("Profile ++++++++++++++++++++++", profiles);
  console.log(`interviewEvaluations`);

  // Function to handle showing profile info after interview completion
  // const handleShowProfileInfo = () => {
  //   // Check if the candidateProfileEmailID is present in listAdminAddCandidateContexts
  //   if (localStorage.getItem("candidateProfileEmailID")) {
  //     setShowProfileInfo(true);
  //   } else {
  //     alert("Candidate profile not found in admin list.");
  //   }
  // };

  // useEffect(() => {
  //   // Retrieve the candidateProfileEmailID from local storage
  //   const candidateProfileEmailID = localStorage.getItem(
  //     "candidateProfileEmailID"
  //   );

  //   // Update the content of the span element with the candidateProfileEmailID
  //   setCandidateEmail(candidateProfileEmailID);
  // }, []);
  // Function to toggle modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    console.log("Modal toggled");
  };
  console.log("Photo url", photoURL);

  return (
    <div>
      {/* Header Section */}
      {/* Testing purops below */}
      {profiles ? (
        <div>{console.log("true")}</div>
      ) : (
        <div>{console.log("false")}</div>
      )}

      {/* Testing puropse Abow */}
      {/* Container for centering content */}

      {/* main original code start */}
      <div>
        <Header />
      </div>

      <div className="container" style={{ color: "#fff", width: "80%" }}>
        {" "}
        {/* Increased width to 80% */}
        {/* Your JSX code to display candidate details */}
        {/* {profiles &&  profiles.length >= 0 && */}
        {/* {   */}
        <div className="card" style={{ marginTop: "50px" }}>
          <div className="card-body">
            <div
              className="profile-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  // src={candidateProfile.photoURL}
                  src={photoURL || profilephoto}
                  alt="Atul"
                  className="profile-img"
                />
                <div style={{ marginTop: "15px" }}>
                  <h5 className="card-title" style={{ color: "#fff" }}>
                    {/* {`${candidateProfile.fullName}`} */}
                    {/* {`${pfullName}`} */}
                    Name : {fullName}
                  </h5>
                  <strong style={{ color: "#fff" }}>
                    {" "}
                    {/* Work Status 2: {candidateProfile.workStatus} */}
                    {/* Work Status 2: {pworkStatus}  */}
                    Work Status : {workStatus}
                  </strong>
                  {/* Assuming workStatus represents experience */}
                  <br />
                </div>
              </div>

              {/* <div>
                <button onClick={handleShare}>
                  {copied ? (
                    "✓ Copied Link!"
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faCopy}
                        style={{ cursor: "pointer" }}
                      />
                      {" GetScreened Link"}
                    </>
                  )}
                </button>
              </div> */}

              <div>
                <div className="share-dropdown">
                  <button className="share-button text-2xl" onClick={handleShare}>
                    <FontAwesomeIcon icon={faShare} />
                  </button>
                </div>
                <ShareModal
                  isOpen={showModal}
                  onClose={() => setShowModal(false)}
                  onShareVia={handleShareVia}
                  onCopyURL={handleCopyURL}
                  copied={copied}
                />
              </div>


            </div>

            <br />
            <p className="card-text" style={{ color: "#fff" }}>
              {/* {candidateProfile.description} */}
              Description : {description}
            </p>
            <div className="skillss gap-2">
              <br />
              Skill :
              {keySkills &&
                keySkills.split(",").map((skill, skillIndex) => (
                  <span key={skillIndex} className="skill-tag">
                    {skill.trim()}
                  </span>
                ))}
            </div>
            {/* { profiles.map((profile, index) => ( 
                <div key={index}>
                <h5 className="card-title" style={{ color: "#fff", marginTop:"40px" }}>
                  Interview Video
                </h5>
                <video
                  controls
                  style={{ width: "100%", border: "2px solid #ccc" }}
                >
                  <source src={profile.candidateVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="transcript-container">
                  <CustomizedResumeText
                    candidateContextTextFile={profile.candidateContextTextFile}
                  />
                </div>
                <div className="transcript-container">
                  <CustomizedResumeRating
                    candidateContextTextFile={profile.candidateContextTextFile}
                  />
                </div>
                </div>
                 ))} */}
          </div>
        </div>
        {/* }  */}
      </div>
      {/* renering code start */}
      {/* profiles || interviewEvaluations */}
      {(profiles && profiles.length > 0) ||
        (interviewEvaluations && interviewEvaluations.length > 0) ? (
        <div className="container" style={{ color: "#fff", width: "80%" }}>
          {" "}
          {/* {interviewEvaluations &&  interviewEvaluations.length >= 0 && 
          interviewEvaluations.map((evaluation, index) => ( */}
          {/*  */}
          <div className="card" style={{ marginTop: "50px" }}>
            <div className="card-body">
              {/*  */}
              {/* <div className="profile-header">
                  <img
                    src={photoURL}
                    alt="sachin"
                    className="profile-img"
                  />
                  <div>
                    <h5 className="card-title" style={{ color: "#fff" }}>
                      
                     Name : {fullName}
                    </h5>
                    <strong style={{ color: "#fff" }}>
                      {" "}
                    
                      Work Status : {workStatus}
                    </strong>
                    <br />
                  </div>
                </div>
                <br />
                <p className="card-text" style={{ color: "#fff" }}>
                Description : {description}
                </p>
                <div className="skillss">
                  
                  Skill :
                  {keySkills &&
                    keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}


                </div> */}

              {profiles.map((profile, index) => (
                <div key={index}>
                  <h5
                    className="card-title"
                    style={{ color: "#fff", marginTop: "40px" }}
                  >
                    Interview Video ++
                  </h5>
                  <video
                    controls
                    style={{ width: "100%", border: "2px solid #ccc" }}
                    controlsList="nodownload" 
                  >
                    <source src={profile.candidateVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="transcript-container">
                    <CustomizedResumeText
                      candidateContextTextFile={
                        profile.candidateContextTextFile
                      }
                    />
                  </div>
                  <div className="transcript-container">
                    <CustomizedResumeRating
                      candidateContextTextFile={
                        profile.candidateContextTextFile
                      }
                    />
                  </div>
                </div>
              ))}

              {/*  */}
              {interviewEvaluations.map((evaluation, index) => (
                <div key={index}>
                  <h5
                    className="card-title"
                    style={{ color: "#fff", marginTop: "40px" }}
                  >
                    Interview Video
                  </h5>
                  {/* <video
                    controls
                    style={{ width: "100%", border: "2px solid #ccc" }}
                  >
                    <source src={evaluation.videoURL} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                  {evaluation.videoURL ? (
                    <video
                      controls
                      style={{ width: "100%", border: "2px solid #ccc" }}
                      controlsList="nodownload" 
                    >
                      <source src={evaluation.videoURL} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <p>No video available</p>
                  )}
                  <h5
                    className="card-title"
                    style={{ color: "#fff", margin: "1rem" }}
                  >
                    Interview Transcript
                  </h5>
                  <div className="transcript-container">
                    {evaluation.transcript &&
                      JSON.parse(evaluation.transcript).map((item, idx) => (
                        <div key={idx}>
                          <p>
                            <strong>{item.speaker}:</strong> {item.message}
                          </p>
                        </div>
                      ))}
                  </div>
                  <h5
                    className="card-title"
                    style={{ color: "#fff", margin: "1rem" }}
                  >
                    Interview Evaluation
                  </h5>
                  <div className="transcript-container">
                    {evaluation.evaluation &&
                      Object.entries(JSON.parse(evaluation.evaluation)).map(
                        ([criterion, data], idx) => (
                          <div key={idx}>
                            <p>
                              <strong>{criterion}:</strong>{" "}
                              {data.score ? data.score : "N/A"} /{" "}
                              {data.max_score ? data.max_score : "N/A"}
                            </p>
                            <p>
                              <strong>Comments:</strong>{" "}
                              {data.comments ? data.comments : "N/A"}
                            </p>
                          </div>
                        )
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/*  */}
          {/* ))} */}
        </div>
      ) : (
        <div>
          <h1 className="text-center text-red-700">Not yet Screened</h1>
        </div>
      )}

      {/*rendering code end  */}

      {/* main original course end */}
    </div>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeText = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);

        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>TranScript</h5>
        <pre>{filteredResumeText}</pre>
      </div>
    </>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeRating = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);

        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>Evaluation</h5>
        <pre>{candidateRating}</pre>
      </div>
    </>
  );
};

export default AdminCandidateViewProfile;
