import React, { useState, useEffect } from "react";
import useRazorpay from "react-razorpay";
import {
  createCompanySubscription,
  updateCompanyProfile,
} from "../../graphql/mutations";
import { getCompanyProfile } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

const PaymentComponent = ({
  packageInfo,
  onPaymentClose,
  companySubscriptionEndDate,
}) => {
  const [loading, setLoading] = useState(false);
  const [Razorpay, initializeRazorpay] = useRazorpay();
  const [CompanyProfile, setCompanyProfile] = useState(null); // State to hold CompanyProfile

  useEffect(() => {
    // Parse CompanyProfile from localStorage
    const storedCompanyProfile = JSON.parse(
      localStorage.getItem("CompanyProfile")
    );
    setCompanyProfile(storedCompanyProfile);
  }, []);

  useEffect(() => {
    if (!Razorpay) {
      initializeRazorpay();
    }
  }, [Razorpay, initializeRazorpay]);

  useEffect(() => {
    if (Razorpay && packageInfo) {
      handlePayment();
    }
  }, [Razorpay, packageInfo]);

  const handlePayment = async () => {
    setLoading(true);
    const amount = packageInfo.price * 100;

    const orderData = {
      amount: amount,
      currency: "INR",
      receipt: "receipt#1",
      notes: { key1: "value3" },
    };

    try {
      const orderRes = await fetch(
        `https://pharmaclick.co.in/RazorPay/WeatherForecast?apiKey=${process.env.REACT_APP_RAZORPAY_KEY}&apiSecret=${process.env.REACT_APP_RAZORPAY_SECRET}&amount=${amount}&receipt=111&currency=INR`,
        {
          method: "POST",
          body: JSON.stringify(orderData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const orderDataid = await orderRes.text();

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: amount,
        currency: "INR",
        name: "GetScreened",
        description: `Subscribe ${packageInfo.title}`,
        order_id: orderDataid,
        handler: async function (response) {
          try {
            const storedCompanyID = localStorage.getItem("CompanyProfileID");
            const duration =
              packageInfo.title === "Monthly"
                ? 1
                : packageInfo.title === "Half Yearly"
                ? 6
                : packageInfo.title === "Yearly"
                ? 12
                : 0;

            let startDate = new Date();
            let endDate = null;

            // Fetch CompanyProfile to check CompanySubscriptionEndDate
            const companyProfileData = await API.graphql(
              graphqlOperation(getCompanyProfile, { id: storedCompanyID })
            );

            if (companyProfileData.data.getCompanyProfile) {
              const companyProfile = companyProfileData.data.getCompanyProfile;
              const companySubscriptionEndDate = new Date(
                companyProfile.CompanySubscriptionEndDate
              );

              if (companySubscriptionEndDate.getTime() > startDate.getTime()) {
                // CompanySubscriptionEndDate is smaller than current date
                startDate = null;
              } else {
                endDate = new Date(startDate);
                endDate.setMonth(endDate.getMonth() + duration);
              }

              const createSubscriptionInput = {
                input: {
                  companyProfileID: storedCompanyID,
                  plan: packageInfo.title,
                  duration: duration,
                  price: amount / 100,
                  startDate: startDate ? startDate.toISOString() : null,
                  endDate: endDate ? endDate.toISOString() : null,
                },
              };

              const createSubscriptionRes = await API.graphql(
                graphqlOperation(
                  createCompanySubscription,
                  createSubscriptionInput
                )
              );

              // Update CompanyProfile remaining credit
              const currentRemainingCredit = companyProfile.remainingCredit;
              const updatedRemainingCredit =
                currentRemainingCredit + amount / 100;

              const updateInput = {
                input: {
                  id: storedCompanyID,
                  remainingCredit: updatedRemainingCredit,
                },
                condition: {
                  remainingCredit: {
                    eq: currentRemainingCredit,
                  },
                },
              };

              const updateCompanyProfileRes = await API.graphql(
                graphqlOperation(updateCompanyProfile, updateInput)
              );

              onPaymentClose();
              window.location.href = "/JobDetailsList";
            } else {
              console.error(
                "Failed to fetch company profile for subscription check"
              );
            }
          } catch (error) {
            console.error("GraphQL mutation error:", error);
          }
        },
        prefill: {
          name: CompanyProfile ? CompanyProfile.companyName : "",
          email: CompanyProfile ? CompanyProfile.email : "",
          contact: CompanyProfile ? CompanyProfile.contactPersonNumber : "",
        },
        notes: {
          address: "Your App Address",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new Razorpay(options);

      rzp.on("payment.failed", (response) => {
        console.log(response);
        setLoading(false);
      });

      rzp.open();
    } catch (error) {
      console.error("Payment initialization error:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      {/* You can add any loading indicator or UI elements here */}
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default PaymentComponent;
