/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSpotResume = /* GraphQL */ `
  query GetSpotResume($id: ID!) {
    getSpotResume(id: $id) {
      id
      companyEmail
      spotJdID
      resumeURLs
      filterCandidate
      selectedCandidate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSpotResumes = /* GraphQL */ `
  query ListSpotResumes(
    $filter: ModelSpotResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpotResumes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyEmail
        spotJdID
        resumeURLs
        filterCandidate
        selectedCandidate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSpotJD = /* GraphQL */ `
  query GetSpotJD($id: ID!) {
    getSpotJD(id: $id) {
      id
      companyEmail
      jdName
      jdURL
      filterCandidate
      selectedCandidate
      skills
      workMode
      workCategory
      languages
      summary
      companyJobDescriptionID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSpotJDS = /* GraphQL */ `
  query ListSpotJDS(
    $filter: ModelSpotJDFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpotJDS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyEmail
        jdName
        jdURL
        filterCandidate
        selectedCandidate
        skills
        workMode
        workCategory
        languages
        summary
        companyJobDescriptionID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPracticeInterviewsEvaluation = /* GraphQL */ `
  query GetPracticeInterviewsEvaluation($id: ID!) {
    getPracticeInterviewsEvaluation(id: $id) {
      id
      interviewSlotID
      roundNumber
      transcript
      evaluation
      candidateEmail
      videoURL
      previousDataID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPracticeInterviewsEvaluations = /* GraphQL */ `
  query ListPracticeInterviewsEvaluations(
    $filter: ModelPracticeInterviewsEvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPracticeInterviewsEvaluations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        interviewSlotID
        roundNumber
        transcript
        evaluation
        candidateEmail
        videoURL
        previousDataID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfileVisit = /* GraphQL */ `
  query GetProfileVisit($id: ID!) {
    getProfileVisit(id: $id) {
      id
      visitorEmail
      visitedEmail
      visitedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProfileVisits = /* GraphQL */ `
  query ListProfileVisits(
    $filter: ModelProfileVisitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileVisits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        visitorEmail
        visitedEmail
        visitedTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCandidateProactringData = /* GraphQL */ `
  query GetCandidateProactringData($id: ID!) {
    getCandidateProactringData(id: $id) {
      id
      candidateId
      candidateEmail
      imageResult1
      pdfResult
      textResult
      imageResult2
      imageResult3
      imageResult4
      imageResult5
      videoResult1
      videoResult2
      videoResult3
      videoResult4
      videoResult5
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCandidateProactringData = /* GraphQL */ `
  query ListCandidateProactringData(
    $filter: ModelCandidateProactringDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateProactringData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        candidateId
        candidateEmail
        imageResult1
        pdfResult
        textResult
        imageResult2
        imageResult3
        imageResult4
        imageResult5
        videoResult1
        videoResult2
        videoResult3
        videoResult4
        videoResult5
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdminAddCandidateContext = /* GraphQL */ `
  query GetAdminAddCandidateContext($id: ID!) {
    getAdminAddCandidateContext(id: $id) {
      id
      candidateEmail
      candidateName
      candidateVideo
      candidateContextTextFile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdminAddCandidateContexts = /* GraphQL */ `
  query ListAdminAddCandidateContexts(
    $filter: ModelAdminAddCandidateContextFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminAddCandidateContexts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        candidateEmail
        candidateName
        candidateVideo
        candidateContextTextFile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCandidateProfile = /* GraphQL */ `
  query GetCandidateProfile($id: ID!) {
    getCandidateProfile(id: $id) {
      id
      user
      userID
      fullName
      gender
      mobileNumber
      description
      matchedPercentage
      workStatus
      currentCity
      educationDetails
      keySkills
      workPreference
      preferredSalary
      photoURL
      resumeURL
      isPublic
      t_c
      email
      roleApplyFor
      address
      interviewScheduleCount
      interviewSlots {
        nextToken
        __typename
      }
      s3objects {
        nextToken
        __typename
      }
      private_Candidate
      private_Candidate_Release
      workMode
      workCategory
      linkedinURL
      githubURL
      coverPhoto
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCandidateProfiles = /* GraphQL */ `
  query ListCandidateProfiles(
    $filter: ModelCandidateProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userID
        fullName
        gender
        mobileNumber
        description
        matchedPercentage
        workStatus
        currentCity
        educationDetails
        keySkills
        workPreference
        preferredSalary
        photoURL
        resumeURL
        isPublic
        t_c
        email
        roleApplyFor
        address
        interviewScheduleCount
        private_Candidate
        private_Candidate_Release
        workMode
        workCategory
        linkedinURL
        githubURL
        coverPhoto
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInterviewSlot = /* GraphQL */ `
  query GetInterviewSlot($id: ID!) {
    getInterviewSlot(id: $id) {
      id
      candidateProfile {
        id
        user
        userID
        fullName
        gender
        mobileNumber
        description
        matchedPercentage
        workStatus
        currentCity
        educationDetails
        keySkills
        workPreference
        preferredSalary
        photoURL
        resumeURL
        isPublic
        t_c
        email
        roleApplyFor
        address
        interviewScheduleCount
        private_Candidate
        private_Candidate_Release
        workMode
        workCategory
        linkedinURL
        githubURL
        coverPhoto
        createdAt
        updatedAt
        __typename
      }
      candidateProfileID
      date
      startTime
      endTime
      status
      meetingLinkURL
      meetingConfig
      candidateAttendance
      botAttendance
      interviewEvaluation {
        id
        interviewSlotID
        roundNumber
        reappearRoundNumber
        feedbackForAI
        transcript
        evaluation
        videoURL
        candidateEmail
        createdAt
        updatedAt
        interviewEvaluationInterviewSlotId
        __typename
      }
      candidateEmail
      createdAt
      updatedAt
      candidateProfileInterviewSlotsId
      interviewSlotInterviewEvaluationId
      __typename
    }
  }
`;
export const listInterviewSlots = /* GraphQL */ `
  query ListInterviewSlots(
    $filter: ModelInterviewSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        candidateProfileID
        date
        startTime
        endTime
        status
        meetingLinkURL
        meetingConfig
        candidateAttendance
        botAttendance
        candidateEmail
        createdAt
        updatedAt
        candidateProfileInterviewSlotsId
        interviewSlotInterviewEvaluationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInterviewEvaluation = /* GraphQL */ `
  query GetInterviewEvaluation($id: ID!) {
    getInterviewEvaluation(id: $id) {
      id
      interviewSlot {
        id
        candidateProfileID
        date
        startTime
        endTime
        status
        meetingLinkURL
        meetingConfig
        candidateAttendance
        botAttendance
        candidateEmail
        createdAt
        updatedAt
        candidateProfileInterviewSlotsId
        interviewSlotInterviewEvaluationId
        __typename
      }
      interviewSlotID
      roundNumber
      reappearRoundNumber
      feedbackForAI
      transcript
      evaluation
      videoURL
      candidateEmail
      createdAt
      updatedAt
      interviewEvaluationInterviewSlotId
      __typename
    }
  }
`;
export const listInterviewEvaluations = /* GraphQL */ `
  query ListInterviewEvaluations(
    $filter: ModelInterviewEvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewEvaluations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        interviewSlotID
        roundNumber
        reappearRoundNumber
        feedbackForAI
        transcript
        evaluation
        videoURL
        candidateEmail
        createdAt
        updatedAt
        interviewEvaluationInterviewSlotId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getS3object = /* GraphQL */ `
  query GetS3object($id: ID!) {
    getS3object(id: $id) {
      id
      objectId
      type
      candidateProfile {
        id
        user
        userID
        fullName
        gender
        mobileNumber
        description
        matchedPercentage
        workStatus
        currentCity
        educationDetails
        keySkills
        workPreference
        preferredSalary
        photoURL
        resumeURL
        isPublic
        t_c
        email
        roleApplyFor
        address
        interviewScheduleCount
        private_Candidate
        private_Candidate_Release
        workMode
        workCategory
        linkedinURL
        githubURL
        coverPhoto
        createdAt
        updatedAt
        __typename
      }
      candidateProfileID
      url
      createdAt
      updatedAt
      candidateProfileS3objectsId
      __typename
    }
  }
`;
export const listS3objects = /* GraphQL */ `
  query ListS3objects(
    $filter: ModelS3objectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listS3objects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        objectId
        type
        candidateProfileID
        url
        createdAt
        updatedAt
        candidateProfileS3objectsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrivateScreening = /* GraphQL */ `
  query GetPrivateScreening($id: ID!) {
    getPrivateScreening(id: $id) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      jobDescription
      invitedCandidates
      createdAt
      releasedAt
      isReleased
      pointsDeducted
      jdID
      candidateEmail
      candidateName
      updatedAt
      companyProfilePrivateScreeningsId
      __typename
    }
  }
`;
export const listPrivateScreenings = /* GraphQL */ `
  query ListPrivateScreenings(
    $filter: ModelPrivateScreeningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateScreenings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyProfileID
        jobDescription
        invitedCandidates
        createdAt
        releasedAt
        isReleased
        pointsDeducted
        jdID
        candidateEmail
        candidateName
        updatedAt
        companyProfilePrivateScreeningsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanySubscription = /* GraphQL */ `
  query GetCompanySubscription($id: ID!) {
    getCompanySubscription(id: $id) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      plan
      duration
      price
      startDate
      endDate
      createdAt
      updatedAt
      companyProfileCompanySubscriptionsId
      __typename
    }
  }
`;
export const listCompanySubscriptions = /* GraphQL */ `
  query ListCompanySubscriptions(
    $filter: ModelCompanySubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanySubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyProfileID
        plan
        duration
        price
        startDate
        endDate
        createdAt
        updatedAt
        companyProfileCompanySubscriptionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyProfile = /* GraphQL */ `
  query GetCompanyProfile($id: ID!) {
    getCompanyProfile(id: $id) {
      id
      userID
      email
      companyName
      companyUrl
      location
      websiteUrl
      contactPersonName
      contactPersonNumber
      contactPersonDesignation
      contactPersonEmail
      t_c
      companyUser
      remainingCredit
      privateScreenings {
        nextToken
        __typename
      }
      CompanySubscriptions {
        nextToken
        __typename
      }
      holdCredit
      consumeCredit
      companyJobDescription {
        nextToken
        __typename
      }
      CompanySubscriptionPlan
      CompanySubscriptionStartDate
      CompanySubscriptionEndDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyProfiles = /* GraphQL */ `
  query ListCompanyProfiles(
    $filter: ModelCompanyProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyJobDescription = /* GraphQL */ `
  query GetCompanyJobDescription($id: ID!) {
    getCompanyJobDescription(id: $id) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      jobIDNo
      jobTitle
      selectedCategory
      selectedExperience
      skill
      jobDescription
      compensation
      benefits
      workMode
      createdAt
      updatedAt
      companyProfileCompanyJobDescriptionId
      __typename
    }
  }
`;
export const listCompanyJobDescriptions = /* GraphQL */ `
  query ListCompanyJobDescriptions(
    $filter: ModelCompanyJobDescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyJobDescriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyProfileID
        jobIDNo
        jobTitle
        selectedCategory
        selectedExperience
        skill
        jobDescription
        compensation
        benefits
        workMode
        createdAt
        updatedAt
        companyProfileCompanyJobDescriptionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
