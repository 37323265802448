import React, { useEffect, useRef, useState } from "react";

import arrow from "../../assets/Arrow.svg";
import zoom from "../../assets/view.svg";
import logo from "../../assets/Copy of classic.gif";
import "../Company/SpotGPT.css";
import { MdOutlineWatchLater } from "react-icons/md";
import profile from "../../assets/profile.png"
import { Flex } from "@aws-amplify/ui-react";

function SpotGPT() {
  const [inputValue, setInputValue] = useState("");
  const [response, setResponse] = useState("");

  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [isRightSectionVisible, setIsRightSectionVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("JD Base");
  const [zoomleft, setZoomleft] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [candidatelist, setCandidatelist] = useState([])
  const [candidateSource, setCandidateSource] = useState([])



  const [candidates, setCandidates] = useState([
    {
      id: 1,
      name: "John Doe 1",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      skills: ["HTML", "CSS", "JS"],
    },
    {
      id: 2,
      name: "John Doe 2",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      skills: ["HTML", "CSS", "JS"],
    },
    {
      id: 3,
      name: "John Doe 3",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      skills: ["HTML", "CSS", "JS"],
    },
    {
      id: 4,
      name: "John Doe 4",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      skills: ["HTML", "CSS", "JS"],
    },
    {
      id: 5,
      name: "Multiple Candidates",
      description: "Click view more to see sub-candidates.",
      profiles: [
        {
          id: 6,
          name: "Bone Doe",
          imgSrc: "#",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          skills: ["React", "Node.js"],
        },
        {
          id: 7,
          name: "Jim Doe",
          imgSrc: "#",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          skills: ["Angular", "TypeScript"],
        },
        {
          id: 8,
          name: "Jake Doe",
          imgSrc: "#",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          skills: ["Vue", "JavaScript"],
        },
      ],
    },
  ]);

  // const handleKeyDown = (event) => {
  //   console.log("ddddd", event);
  //   if (event.type == "click") {

  //     console.log("called");
  //     setResponse("How are you?");

  //     console.log("typesd:", inputValue);
  //   }
  // };

  const handleKeyDown = () => {
    if (inputValue) {
      setInputValue(inputValue); // Clear the input field after submission
      setIsSubmitted(true); // Show the prompt
      setResponse("How are you?");
    }
  };

  const handleSelectCandidate = (candidate) => {
    if (!selectedCandidates.includes(candidate)) {
      setSelectedCandidates([...selectedCandidates, candidate]);
      setIsRightSectionVisible(true);
    }
  };

  const handleRemoveCandidate = (candidate) => {
    const updatedCandidates = selectedCandidates.filter(
      (c) => c.id !== candidate.id
    );
    setSelectedCandidates(updatedCandidates);
    if (updatedCandidates.length === 0) {
      setIsRightSectionVisible(false);
    }
  };

  const handlezoom = () => {
    setZoomleft(!zoomleft);
  };
  const handleViewMore = (id) => {
    const expandedProfiles = candidates
      .find((candidate) => candidate.id === id)
      .profiles.map((profile, idx) => ({
        ...profile,
        id: candidates.length + idx + 1,
      }));

    // Remove the original id 4 candidate and add the expanded profiles
    setCandidates((prevCandidates) => {
      const candidateToRemove = prevCandidates.find(
        (candidate) => candidate.id === id
      );

      // Add expanded profiles and ensure they include the removed candidate (if needed)
      return prevCandidates
        .filter((candidate) => candidate.id !== id) // Remove the candidate with the original id 4
        .concat(expandedProfiles); // Add the expanded profiles, which could include the updated candidate
    });
  };


  //  spot gpt code start



  const [query, setQuery] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [searched, setSearched] = useState(false); // State to track if search has been done
  const chatEndRef = useRef(null); // Ref to scroll to the end of chat
  // const [chatStarted, setChatStarted] = useState(false); // Track if chat has started
  const [resumeID, setResumeID] = useState("7f8ca1de-5b03-45dc-805c-2e787362d060")
  const [isGptSendEnabled, setIsGptSendEnabled] = useState(false);
  const [isGptLoading, setIsGptLoading] = useState(false);
  const [isGptInitialized, setIsGptInitialized] = useState(false);
  const [spotResumeID, setSpotResumeID] = useState("")


  const handleGptInitialization = async () => {

    const ResumeID = localStorage.getItem('SpotResumeID')
    const JDID = localStorage.getItem('SpotJDID')

    setSpotResumeID(ResumeID)

    const data1 = {
      resume_id: ResumeID,
      jd_id: JDID, // Add your new parameter here
    };

    setIsGptLoading(true);
    addMessage('Hi I am GetScreend bot I can help you to evalute this candidate better !', 'bot-message', true); // Display initial message
    addMessage('AI is churning data for the first time , Kindly wait for a minute or two to load the AI .', 'bot-message', false);
    setIsGptInitialized(true); // Mark that the initialization process has started
    try {
      // Replace with your actual API URL
      const response = await fetch('https://fce0-20-70-140-142.ngrok-free.app/initialize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data1), // Include resume_id in the request body
      });

      const result = await response.json();
      // Check if the response message matches the expected success message
      if (result.message === "Resumes and JD profiles embedded, QA system initialized successfully!") {
        setIsGptSendEnabled(true);
      } else {
        setIsGptSendEnabled(false);
      }
    } catch (error) {
      console.error('API call failed', error);
      setIsGptSendEnabled(false); // Ensure the send button is disabled if the API call fails
    } finally {
      setIsGptLoading(false);
    }
  };



  useEffect(() => {

    // const handleGptInitialization = async () => {

    //   const ResumeID = localStorage.getItem('SpotResumeID')
    //   const JDID = localStorage.getItem('SpotJDID')

    //   setSpotResumeID(ResumeID)

    //   const data1 = {
    //     resume_id: ResumeID,
    //     jd_id: JDID, // Add your new parameter here
    //   };

    //   setIsGptLoading(true);
    //   addMessage('Hi I am GetScreend bot I can help you to evalute this candidate better !', 'bot-message', true); // Display initial message
    //   addMessage('AI is churning data for the first time , Kindly wait for a minute or two to load the AI .', 'bot-message', false);
    //   setIsGptInitialized(true); // Mark that the initialization process has started
    //   try {
    //     // Replace with your actual API URL
    //     const response = await fetch('https://c431-20-70-140-142.ngrok-free.app/initialize', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(data1), // Include resume_id in the request body
    //     });

    //     const result = await response.json();
    //     // Check if the response message matches the expected success message
    //     if (result.message === "Resumes and JD profiles embedded, QA system initialized successfully!") {
    //       setIsGptSendEnabled(true);
    //     } else {
    //       setIsGptSendEnabled(false);
    //     }
    //   } catch (error) {
    //     console.error('API call failed', error);
    //     setIsGptSendEnabled(false); // Ensure the send button is disabled if the API call fails
    //   } finally {
    //     setIsGptLoading(false);
    //   }
    // };

    handleGptInitialization()

  }, [])



  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      // Save user's question to chat history first
      setQuery("")
      addMessage(query, 'user-message');

      // Send API request
      const response = await fetch('https://fce0-20-70-140-142.ngrok-free.app/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: query, resume_id: spotResumeID }),
      });

      // Parse response
      const data = await response.json();

      // Add API response to chat history
      addMessage(data.answer ? data.answer : data.error, 'bot-message');
      // setCandidates(data.candidates_referring_to)
      setCandidatelist(data.candidates_referring_to)
      setCandidateSource(data.sources)

      // Clear input field after successful submission
      setQuery('');
      setSearched(true);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const addMessage = (message, type) => {
    const newMessage = { text: message, type: type };
    setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
  };

  // spot gpt code end

  //  source code start

  const sourceTruncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return `${words.slice(0, wordLimit).join(' ')}...`;
  };

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  // source code end

  // list candidate show code start

  const commentTruncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return `${words.slice(0, wordLimit).join(' ')}...`;
  };

  // list candidate show code end

  console.log("***************Chat History********************", candidatelist)

  return (
    <>
      <style>
        {`
          .app-container {
            display: flex;
            width: 100%;
            height: 100vh;
            overflow: hidden;
            font-family: Arial, sans-serif;
            // background-color: #ddc8ff;
            background: linear-gradient(rgb(96, 61, 191), rgb(20, 8, 46));
          }

          .left-section {
            width: 100%;
            padding: 20px;
            transition: width 0.3s ease-in-out;
            overflow-y: scroll;
            // background-color: #ddc8ff;
            height: 92vh;
            scrollbar-width: none;
            scrollbar-width: thin;
            scrollbar-color: #9e85d9 #8062c9;
            margin-right: 8px;
          }

          .right-section {
            width: ${isRightSectionVisible ? "74%" : "0"};
            background-color: #E8DCFB;
            // box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
            overflow-y: auto;
            padding: ${isRightSectionVisible ? "20px" : "0"};
            transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
            position: relative;
            height: 85vh;
            border-radius: 10px;
            margin-top: 1rem;
            margin-right: 1rem;
          }
          .right-section h2{
            -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%);
          }
          .left-section h3{
    //         -webkit-background-clip: text !important;
    // -webkit-text-fill-color: transparent;
    // background: linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%);
    color: #fff;
          }
          .left-section p{
          color: #fff;
          }
          .header {
            display: flex;
    gap: 10px;
    margin-bottom: 20px;
    width: fit-content;
    padding: 10px;
    border: 1px solid #835fc5;
    border-radius: 4px;
          }

          .toggle-button {
            background-color: #d3bef5;
            
            border: none;
            border-radius: 4px;
            color: white;
            padding: 15px 30px;
            font-weight: bold;
            cursor: pointer;

          }

          .toggle-button.active {
            background: linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%);
          }

          .candidate-list {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 20px;
          }

          .candidate-card {
            background: linear-gradient(135deg, #667eea, #8859b8);
    // border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    /* text-align: center; */
    width: 283px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
          }
          .candidate-card2 {
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 10px;
            /* text-align: center; */
            width: 100%;
            cursor: pointer;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            position: relative;
            gap:10px;
            height: 19vh;
            width: 90%;
        }
          .candidate-subcard{
           display:flex; 
          }
          .remove-icon {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
            color: #6552ff;
            font-weight: bold;
            font-size: 14px;
            border: 1px solid #6552ff;
            padding: 4px;
            border-radius: 15px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        
          }

          .candidate-avatar {
            background-color: #ccc;
            border-radius: 50%;
            height: 34px;
            width: 47px;
            margin: 0 auto 10px;
            padding: 9px;
            margin: 10px;
          }
          .candidate-avatar2 {
            background-color: #ccc;
            border-radius: 50%;
            height: 16px;
            width: 50px;
            margin: 0 auto 10px;
            padding: 9px;
            margin: 10px;
          
        }
.name{
  font-size: 13px;
}
.discription{
  font-size: 9px;
  
}


          .skills {
            display: flex;
            gap: 5px;
            justify-content: center;
            margin-top: 10px;
          }

          .skills span {
            background: linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(170 134 207)  100%);
            border-radius: 4px;
            padding: 2px 8px;
            font-size: 0.8rem;
            color: #fff;
          }

          .result-section {
            background-color: #E8DCFB;
            // border: 1px solid #d4a5ff;
            padding: 15px;
            border-radius: 8px;
            margin-top: 20px;
            margin-bottom: 20px;
            // height: 31vh;
   
          }
          .result-icon{
            -webkit-text-fill-color: #E8DCFB;
                    }

          .result-section h3 {
            margin-bottom: 10px;
            -webkit-background-clip: text !important;
-webkit-text-fill-color: transparent;
background: linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%);
background-clip: text;

          }
//           .result-section p {
//             -webkit-background-clip: text !important;
// -webkit-text-fill-color: transparent;
// background: linear-gradient(180deg, rgb(35, 29, 56) 0%, rgb(80, 61, 117) 100%);
// background-clip: text;
//           }
          .view-more-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            padding: 10px;
            border: 1px solid #835fc5;
            border-radius: 8px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            width: 150px;
            text-align: center;
            cursor: pointer;
          }
          .view-more-card{
            color: #835fc5;
          }
          .view-more-card p{
           color:#fff;
          }

          .profile-images {
            display: flex;
            gap: 5px;
          }

          .profile-image {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #ccc;
          }

          .ask-gpt {
            display: flex;
            margin-top: 10px;
            gap: 5px;
          }

          .ask-gpt input {
            flex: 1;
            padding: 15px;
            border: 1px solid #ccc;
            border-radius: 16px;
            border: 1px solid #835fc5;
            background: transparent;
            color: #fff;
    
    background: transparent;
          }

          .ask-gpt button {
            background: transparent;
           
            border: 1px solid #835fc5;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
          }
.send-mail{
  position:relative;
}
          .send-mail-button {
            position: absolute;
            bottom: 10px;
            // left: 20px;
            right: 20px;
            background: linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%);
            border: none;
            border-radius: 5px;
            color: white;
            padding: 10px 20px;
            cursor: pointer;
          }

          .selected-candidate-header {
            font-size: 1.2rem;
            margin-bottom: 20px;
            color: #fff;
          }

          .jdnumber{
            background: black;
    padding: 4px;
    border-radius: 4px;
    margin-left: 8px;
          }
.resumenumber{
  background: linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%);
  padding: 4px;
  border-radius: 4px;
  margin-left: 8px;
}
.hr{
  border: none; 
  height: 0.5px; 
  background-color: #b8b8b8;
}
          .right-candidate-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
            margin-bottom: 60px;
          }
          .candidate-list2{
            display:flex;
            align-items: center;
    justify-content: space-between;

          }
          .logo{
            width: 24%;
            height: fit-content;
          }
          .logoparent{
            height: 14vh;
            display: flex !important;
            align-items: center !important;
            justify-content: flex-start !important;
            left: -5%;
            position: relative;
        }
        .prompt{
          background: linear-gradient(135deg, #667eea, #8859b8);
          
          padding: 8px;
          z-index: 1;
          position: relative;
          color: #fff !important;
          border-radius: 4px;
          overflow: overlay;
          left: 74%;
    width: 25%;
    border-radius: 4px 10px;

        }
        .prompt2{
       
          color: #000 !important;
         
        }
          }
        
        `}
      </style>

      <div className="app-container">
        {/* Left Section */}
        <div className="left-section">
          {/* Header Toggle Buttons */}
          <div className="logoparent">
            {/* <button
              className={`toggle-button ${
                activeTab === "JD Base" ? "active" : ""
              }`}
              onClick={() => setActiveTab("JD Base")}
            >
              JD Base <span className="jdnumber">20</span>
            </button>
            <button
              className={`toggle-button ${
                activeTab === "Resume Base" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Resume Base")}
            >
              Resume Base <span className="resumenumber">50</span>
            </button> */}
            <img className="logo" src={logo} alt="" srcset="" />
          </div>


          {/* source code start */}

          {
            candidateSource.length > 0 && (

              <div className="candidate-list2">
                <h2 className="selected-candidate-header">Source list</h2>
                <img onClick={handlezoom} src={zoom} alt="" srcset="" />
              </div>

            )
          }

          <div className="candidate-list">
            {candidateSource.map((candidate, index) => (
              <div
                className="candidate-card overflow-hidden"
                key={index}
                onClick={() => handleSelectCandidate(candidate)}
              >
                <div className="candidate-subcard  overflow-hidden">
                  {/* <div className="candidate-avatar"></div> */}
                  <div className=" overflow-hidden">


                    {/* <h3>{candidate}</h3> */}

                    {/* testing code start */}
                    <p
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering parent onClick
                        handleClick(candidate); // Open full URL
                      }}
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      className="overflow-hidden"
                    >
                      {sourceTruncateText(candidate, 4)} {/* Display truncated text */}
                    </p>
                    {/* testing code end */}



                    {/* <p>{candidate.description}</p> */}
                  </div>
                </div>
                {/* <div className="skills">  
                  {candidate.skills.map((skill, index) => (
                    <span key={index}>{skill}</span>
                  ))}
                </div> */}
              </div>
            ))}

            {/* {candidates[4] && candidates[4].profiles && (
              <div className="view-more-card">
                <div className="profile-images">
                  {candidates[4].profiles.map((profiles, index) => (
                    <div className="profile-image" key={index}></div>
                  ))}
                </div>

                <p className="paragraph">
                  In publishing and graphic design, Lorem ipsum...
                </p>
                <a
                  onClick={() => handleViewMore(candidates[4].id)}
                  className="view-more"
                >
                  View More
                </a>
              </div>
            )} */}
          </div>

          {/* source code end */}

          {
            candidatelist.length > 0 && (

              <div className="candidate-list2">
                <h2 className="selected-candidate-header">🎯 Candidate List</h2>
                <img onClick={handlezoom} src={zoom} alt="" srcset="" />
              </div>

            )
          }

          {/* candidate list data start */}
          <div className="candidate-list">
            {candidatelist.map((candidate) => (
              <div
                className="candidate-card"
                key={candidate.id}
                onClick={() => handleSelectCandidate(candidate)}
              >
                <div className="candidate-subcard">
                  <div className="candidate-avatar"></div>
                  <div className="">
                    <h6 className="font-medium ml-3 mt-2">{candidate.name}</h6>
                    {/* <p>{candidate.comments}</p> */}
                    <p className="text-gray-700 " style={{fontSize:"12px"}}>
                      {commentTruncateText(candidate.comments, 10)} {/* Display truncated comments */}
                    </p>
                  </div>
                </div>
                {/* <div className="skills bg-red-600" >
                  {candidate.skills.split(',').slice(0, 3).join(', ')}
                </div> */}

                {candidate.skills.split(',').slice(0, 3).map((skill, index) => (
                  <span
                    key={index}
                    style={{
                      // backgroundColor: 'rgba(221, 200, 255, 0.84)',
                      background: "linear-gradient(180deg, rgb(102, 126, 234) 0%, rgb(136, 89, 184) 100%)",
                      color: 'white',
                      padding: '0.2rem 0.5rem',
                      borderRadius: '4px',
                      marginRight: '0.5rem',
                      marginBottom: '0.5rem',
                      fontSize: "16px",
                      padding: "0px 8px",
                      width: "-webkit-fill-available",
                      display:" flex",
                      
                    }}
                  >
                    {skill.trim()}
                  </span>
                ))}

              </div>
            ))}

            {candidatelist.length > 4 && (
              <div className="view-more-card">
                <div className="profile-images">
                  {candidatelist[4].profiles.map((profiles, index) => (
                    <div className="profile-image" key={index}>
                      <h1>{profiles.name}</h1>
                    </div>
                  ))}
                </div>

                <p className="paragraph">
                  In publishing and graphic design, Lorem ipsum...
                </p>
                <a
                  onClick={() => handleViewMore(candidates[4].id)}
                  className="view-more"
                >
                  View More
                </a>
              </div>
            )}

          </div>
          {/* candidate list data end */}

          {/* Result Section */}
          {zoomleft ? (
            <div className="result-section">
              <h3>
                <span className="result-icon">📋</span> Result
              </h3>
              <hr className="hr" />


              {/* <p>
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. s simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's standard dummy text ever since the
                1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not
                only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised
                in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </p> */}

              {/* {isSubmitted && inputValue && (
                <>
                  <p className="prompt">⚡{inputValue}</p>
                  <p className="prompt2">{response}</p>
                </>
              )} */}
              <div className=" flex-1 overflow-y-auto px-4 py-4 h-[60vh] ">

                {/* original chat code start below */}

                {/* {chatHistory.map((message, index) => (
                  <div
                    key={index}
                    className={`flex mb-2 ${message.type === 'user-message' ? 'justify-end' : 'justify-start'
                      }`}
                  >
                    <div
                      className={`${message.type === 'user-message'
                        ? 'bg-blue-500 text-white rounded-lg p-2 max-w-xs'
                        : 'bg-red-400 text-gray-800 rounded-lg p-2 max-w-xs'
                        }`}
                    >

                      <p className="text-sm">{message.text}</p>
                    </div>
                  </div>
                ))} */}

                {/* original chat code end above */}

                {/* testing code start below */}

                {chatHistory.map((message, index) => (
                  <div key={index} className={`p-2 mb-2 rounded flex ${message.type === 'user-message' ? 'justify-end' : 'justify-start'}`}>
                    {/* {message.type !== 'user-message' && (
                      <div className="w-8 h-8 bg-blue-500 rounded-full mr-2"></div>
                    )} */}
                    <div className={`${message.type === 'user-message' ? 'text-right' : 'text-left'} flex flex-col`}>
                      <span className={`text-xs font-semibold mb-1 ${message.type === 'user-message' ? 'text-blue-500' : 'text-red-500'}`}>
                        {message.type === 'user-message' ? 'You' : 'GetScreened Bot'}
                      </span>
                      <div className={`${message.type === 'user-message' ? 'bg-blue-500 text-white' : 'bg-gray-500 text-gray-800'} rounded-lg   max-w-2xl` }
                        style={message.type === 'user-message' ? { background: 'linear-gradient(135deg, #667EEA, #8859B8)', display:"flex", alignItems:"center",justifyContent:"center", marginBottom:"0rem" , padding:"8px 12px"} : { color: "black" , padding:"8px 12px"}}
                      >
                        <p className="text-sm">{message.text}</p>
                      </div>
                    </div>
                  </div>
                ))}

                {/* testing code end above */}

              </div>

            </div>
          ) : (
            <div>Content when condition is false</div>
          )}

          {/* Ask GPT Section */}
          <div className="ask-gpt">
            <input
              type="text"
              placeholder="Type your question here..."
              // value={inputValue}
              // onChange={(e) => setInputValue(e.target.value)}
              // onChange={(e) => {
              //   setInputValue(e.target.value);
              //   setIsSubmitted(false); // Hide prompt when typing
              // }}
              value={query}
              onChange={handleChange}
              required
            />
            {/* <button onClick={handleSubmit} disabled={!isGptSendEnabled}>
              send
            </button> */}

            {/* {!isGptInitialized && (
              <button onClick={handleGptInitialization} disabled={isGptLoading}>
                {isGptLoading ? 'Loading...' : 'Initialize'}
              </button>
            )} */}

            {isGptSendEnabled && ( // Show the send button only if it's enabled
              <button onClick={handleSubmit} disabled={!isGptSendEnabled}>
                send
              </button>
            )}
            {/* Uncomment if you want a manual re-initialization button */}
            {!isGptSendEnabled && (
              <button onClick={handleGptInitialization} disabled={isGptLoading}>
                {isGptLoading ? 'initializing...' : 'Initialize'}
              </button>
            )}



          </div>
        </div>

        {/* Right Section */}
        <div className="right-section">
          {isRightSectionVisible && (
            <>
              <h2>
                {" "}
                <span class>✨</span> Final Selected Candidates
              </h2>
              <div className="right-candidate-list">
                {selectedCandidates.map((candidate) => (
                  <div className="candidate-card2 overflow-hidden" key={candidate.id}>
                    <span
                      className="remove-icon"
                      onClick={() => handleRemoveCandidate(candidate)}
                    >
                      ✖
                    </span>
                    <div className="candidate-subcard">
                      <div className="candidate-avatar2"></div>
                      <div>
                        <h3 className="name text-black">{candidate.name}</h3>
                        <p className="discription text-black">{candidate.comments}</p>
                      </div>
                    </div>
                    {/* <div className="skills">
                      {candidate.skills &&
                        candidate.skills.map((skill, index) => (
                          <span key={index}>{skill}</span>
                        ))}
                    </div> */}
                    <div className="skills">
                      {candidate.skills.split(',').slice(0, 3).join(', ')}
                    </div>
                  </div>
                ))}
              </div>

              {/* Send Mail to Candidate Button */}
              <div className="send-mail">
                <button className="send-mail-button">
                  Send Mail to Candidate
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SpotGPT;
