import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/header/Header';

const CompanyGlobal = () => {

    // original code start

    const [query, setQuery] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [searched, setSearched] = useState(false); // State to track if search has been done
    const chatEndRef = useRef(null); // Ref to scroll to the end of chat
    // const [chatStarted, setChatStarted] = useState(false); // Track if chat has started


    const handleChange = (event) => {
        setQuery(event.target.value);
    };


    const handleSubmit = async () => {
        try {
            // Save user's question to chat history first
            setQuery("")
            addMessage(query, 'user-message');

            // Send API request
            const response = await fetch('jdgjdfglbdfgbjd', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ question: query }),
            });

            // Parse response
            const data = await response.json();

            // Add API response to chat history
            addMessage(data.answer ? data.answer : data.error, 'bot-message');

            // Clear input field after successful submission
            setQuery('');
            setSearched(true);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const addMessage = (message, type) => {
        const newMessage = { text: message, type: type };
        setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
    };


    const scrollToBottom = () => {
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' }); // Use optional chaining
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);





    return (


       <div>
        <Header/>
        <div className="flex flex-col items-center justify-center h-[80vh] mt-7">
            {chatHistory.length > 0 ? (
                <div className="flex flex-col h-full max-w-screen-md w-full  rounded-lg shadow-lg overflow-hidden">
                    <div className="flex-1 overflow-y-auto px-4 py-4">
                        {chatHistory.map((message, index) => (
                            <div
                                key={index}
                                className={`flex mb-2 ${message.type === 'user-message' ? 'justify-end' : 'justify-start'
                                    }`}
                            >
                                <div
                                    className={`${message.type === 'user-message'
                                            ? 'bg-blue-500 text-white rounded-lg p-2 max-w-xs'
                                            : 'bg-gray-200 text-gray-800 rounded-lg p-2 max-w-xs'
                                        }`}
                                >
                                    <p className="text-sm">{message.text}</p>
                                </div>
                            </div>
                        ))}
                        <div ref={chatEndRef} />
                    </div>
                    <div className="border-t border-gray-200 py-4 px-4">
                        <div className="max-w-screen-md mx-auto flex items-center">
                            <input
                                type="text"
                                value={query}
                                onChange={handleChange}
                                placeholder="Type a message..."
                                className="flex-1 py-2 px-4 border border-gray-300 rounded-lg focus:outline-none text-gray-700 "
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }}
                            />
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="ml-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg focus:outline-none"
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            ) : (

                <div>
                <div className="max-w-3xl mx-auto flex items-center p-2 bg-white shadow-xl rounded-full" style={{ width: '1400px' }}>
                    <input
                        type="text"
                        value={query}
                        onChange={handleChange}
                        placeholder="Search..."
                        className="flex-1 appearance-none rounded-full py-2 px-4 text-gray-700 leading-tight focus:outline-none"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    />
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="ml-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-full focus:outline-none"
                    >
                        Search
                    </button>
                </div>
                
                <div className="max-w-3xl mx-auto mt-4 flex justify-between">
                    <div className="flex-1 mx-4 bg-white shadow-md rounded-lg p-4">
                        <p className="text-gray-800 text-sm">Get instant answers to your questions.</p>
                    </div>
                    <div className="flex-1 mx-4 bg-white shadow-md rounded-lg p-4">
                        <p className="text-gray-800 text-sm">Explore our advanced AI capabilities.</p>
                    </div>
                    <div className="flex-1 mx-4 bg-white shadow-md rounded-lg p-4">
                        <p className="text-gray-800 text-sm">Receive personalized recommendations.</p>
                    </div>
                </div>
                </div>


            )}
        </div>
        </div>

       

    )
}

export default CompanyGlobal
