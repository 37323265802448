import React, { useState } from "react";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { Document, Packer, Paragraph, TextRun } from "docx";

const JobPostDetails = ({ jobDetails }) => {
  const [fileType, setFileType] = useState("text");

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleDownloadJD = () => {
    if (fileType === "text") {
      downloadAsText();
    } else if (fileType === "pdf") {
      downloadAsPDF();
    } else if (fileType === "word") {
      downloadAsWord();
    }
  };

  const downloadAsText = () => {
    const element = document.createElement("a");
    const file = new Blob([jobDetails.jobDescription], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${jobDetails.jobTitle}_JobDescription.txt`;
    document.body.appendChild(element);
    element.click();
  };

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    doc.text(jobDetails.jobDescription, 10, 10);
    doc.save(`${jobDetails.jobTitle}_JobDescription.pdf`);
  };

  const downloadAsWord = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [new TextRun(jobDetails.jobDescription)],
            }),
          ],
        },
      ],
    });
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${jobDetails.jobTitle}_JobDescription.docx`);
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Job Post Details</h2>
      <div className="bg-black shadow-md rounded-lg p-6 max-w-[800px] mx-auto">
        <div className="mb-4">
          <h3 className="text-lg font-bold">Job Title</h3>
          <p>{jobDetails.jobTitle}</p>
        </div>
        <div className="mb-4 w-[900px]">
          <h3 className="text-lg font-bold">Category</h3>
          <p>{jobDetails.selectedCategory}</p>
        </div>
        <div className="mb-4 w-[900px]">
          <h3 className="text-lg font-bold">Work Mode</h3>
          <p>{jobDetails.workMode}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-bold">Experience Level</h3>
          <p>{jobDetails.selectedExperience}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-bold">Skill</h3>
          <p>{jobDetails.skill}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-bold">Job Description</h3>
          <p>{jobDetails.jobDescription}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-bold">Compensation</h3>
          <p>{jobDetails.compensation}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-bold">Benefits</h3>
          <p>{jobDetails.benefits}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-bold">Created At</h3>
          <p>{jobDetails.createdAt}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-bold">Updated At</h3>
          <p>{jobDetails.updatedAt}</p>
        </div>
        <div className="mb-4">
          <label className="block text-lg font-bold mb-2" htmlFor="fileType">
            Select File Type:
          </label>
          <select
            id="fileType"
            value={fileType}
            onChange={handleFileTypeChange}
            className="mb-4 p-2 border rounded text-black"
          >
            <option value="text">Text</option>
            <option value="pdf">PDF</option>
            <option value="word">Word</option>
          </select>
        </div>
        <div className="mb-4">
          <button
            onClick={handleDownloadJD}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Download JD
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobPostDetails;
