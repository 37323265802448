import React, { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const AdminPrivateRoute = () => {



    const email = localStorage.getItem(
        "candidateProfileEmailID"
      );



      
      return email === "getscreened@varahitechnologies.com" || email === "hr@varahitechnologies.com"  ? <Outlet />:<Navigate to="/" />

}

export default AdminPrivateRoute
