import React, { useState } from "react";

// Import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./StudentDashbord.css";

// Import Bootstrap icons and Font Awesome
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// Define the React component
function ResponsiveDashboard({ signOut }) {
  // Define state for voice recognition
  const [recognition, setRecognition] = useState(null);

  // Function to handle voice commands
  const handleVoiceCommand = (command) => {
    switch (command) {
      case "play recent interview video":
        playRecentInterviewVideo();
        break;
      case "stop video":
        stopVideo();
        break;
      case "open schedule page":
        openSchedulePage();
        break;
      case "show me my profile":
        showProfile();
        break;
      case "show me recent interview information":
        openRecentInterviewScreen();
        break;
      case "back":
        navigateToDashboard();
        break;
      default:
        break;
    }
  };

  // Function to play recent interview video
  const playRecentInterviewVideo = () => {
    const video = document.getElementById("recentInterviewVideo");
    // Play the video
    video.play();
  };

  // Function to stop video
  const stopVideo = () => {
    const video = document.getElementById("recentInterviewVideo");
    // Pause the video
    video.pause();
  };

  // Function to open schedule page
  const openSchedulePage = () => {
    window.location.href = "./schedule.html";
  };

  // Function to show profile
  const showProfile = () => {
    window.location.href = "./profile.html";
  };

  // Function to open recent interview screen
  const openRecentInterviewScreen = () => {
    window.location.href = "./newinterviewscreen.html";
  };

  // Function to navigate back to the dashboard
  const navigateToDashboard = () => {
    window.location.href = "./dashboard.html";
  };

  // Function to start voice recognition
  const startVoiceRecognition = () => {
    const recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognition.lang = "en-US";
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.onresult = (event) => {
      const lastResult = event.results[event.resultIndex];
      const transcript = lastResult[0].transcript.trim();
      handleVoiceCommand(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error", event.error);
    };

    recognition.start();
    setRecognition(recognition); // Set recognition object to state
  };

  // Render the component
  return (
    <div class="container-fluid">
      <div class="row flex-nowrap">
        <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 sidebar">
          <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-4 text-white min-vh-100">
            <a
              href="/"
              class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
            >
              <span class="fs-5 d-none d-sm-inline">
                <img src="/Img/Logo.svg" alt="" style={{ height: "64px%" }} />
              </span>
            </a>
            <ul
              class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start pt-4"
              style={{ gap: "24px" }}
              id="menu"
            >
              <li class="nav-item">
                <a href="#" class="nav-link align-middle px-0">
                  <i class="fs-4 bi bi-grid"></i>{" "}
                  <span class="ms-1 d-none d-sm-inline">Home</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="./schedule.html" class="nav-link align-middle px-0">
                  <i class="fs-4 bi bi-calendar3"></i>{" "}
                  <span class="ms-1 d-none d-sm-inline">Schedule</span>
                </a>
              </li>
              <li>
                <a href="#" class="nav-link px-0 align-middle">
                  <i class="fs-4 bi bi-gear"></i>{" "}
                  <span class="ms-1 d-none d-sm-inline">Settings</span>
                </a>
              </li>
              <li>
                <a href="#" class="nav-link px-0 align-middle">
                  <i class="fs-4 bi bi-question-circle"></i>{" "}
                  <span class="ms-1 d-none d-sm-inline">Help</span>
                </a>
              </li>
              <li>
                <button
                  onClick={signOut}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Sign Out
                </button>
              </li>
            </ul>

            <div class="voice-assist-container" id="voiceAssistContainer">
              <img
                src="./images/voiceAssist.gif"
                alt="Voice Assistance"
                class="voice-assist"
                id="voiceAssist"
                style={{
                  width: "119%",
                  marginLeft: "-10%",
                  borderRadius: "4px",
                }}
              />
            </div>
          </div>
        </div>

        <div class="col py-3">
          <header class="d-flex justify-content-between dashboard-header">
            <h1 class="h2" style={{ fontWeight: 400 }}>
              Dashboard
            </h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a class="navbar-brand" href="./profile.html">
                <i
                  class="fas fa-user-circle"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </a>
            </div>
          </header>

          <div class="container-fluid">
            <div class="row" style={{ marginTop: "8%" }}>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">Upcoming Interviews</div>
                  <div
                    class="card-body scrollable-card"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    <div class="card card-border mb-3">
                      <div class="card-body">
                        <h5 class="card-title">
                          Interview with Sachin G - .NET Developer
                        </h5>
                        <p class="card-text">Date: February 27, 2024</p>
                        <p class="card-text">Time: 6:50 PM - 7:10 PM</p>
                      </div>
                    </div>

                    <div class="card card-border mb-3">
                      <div class="card-body">
                        <h5 class="card-title">
                          Interview with Sachin G - .NET Developer
                        </h5>
                        <p class="card-text">Date: February 29, 2024</p>
                        <p class="card-text">Time: 7:50 PM - 8:10 PM</p>
                      </div>
                    </div>

                    <div class="card card-border mb-3">
                      <div class="card-body">
                        <h5 class="card-title">
                          Interview with Sachin G - .NET Developer
                        </h5>
                        <p class="card-text">Date: March 1, 2024</p>
                        <p class="card-text">Time: 8:50 PM - 9:10 PM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">
                    Recent Interviews
                    <a
                      style={{ textDecoration: "none", color: "#9900ff" }}
                      href="./newinterviewscreen.html"
                    >
                      View Details
                    </a>
                  </div>
                  <div
                    class="card-body scrollable-card"
                    style={{ height: "41vh" }} // Set a height relative to viewport height
                  >
                    <video
                      id="recentInterviewVideo"
                      src="/recent-interview.mp4"
                      controls
                      style={{ width: "100%", height: "100%" }} // Ensure the video fills the container
                    >
                      <p>No recent interviews.</p>
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveDashboard;
