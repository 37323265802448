import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logogetscreened.svg";
import TandCImage from "../assets/T&C.png";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { createCandidateProfile } from "../graphql/mutations"; // Adjust the path as necessary
import { listCandidateProfiles, listPrivateScreenings } from "../graphql/queries";
const TermsPolicy = () => {
  const [accepted, setAccepted] = useState(false);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const [candidateEmail, setCandidateEmail] = useState("");
  const [privateCandidate,SetPrivateCandidate] = useState(false)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUserId(userInfo.attributes.sub); // Set the user ID in state
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    // Retrieve the candidateProfileEmailID from local storage
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );

    // Update the content of the span element with the candidateProfileEmailID
    setCandidateEmail(candidateProfileEmailID);

    // Testing code Start
    
    const getCandidatePrivateScreenings = async () => {
      try {
        // Retrieve the candidate's email from local storage
        const candidateEmail = localStorage.getItem("candidateProfileEmailID");
    
        // Fetch candidate's private screenings
        const privateScreenings = [];
        let nextToken = null;
        do {
          const privateScreeningsResponse = await API.graphql(
            graphqlOperation(listPrivateScreenings, {
              filter: { candidateEmail: { eq: candidateEmail } },
              limit: 100,
              nextToken: nextToken,
            })
          );
          privateScreenings.push(
            ...privateScreeningsResponse.data.listPrivateScreenings.items
          );
          nextToken = privateScreeningsResponse.data.listPrivateScreenings.nextToken;
        } while (nextToken);
    
        // Now privateScreenings array contains all private screenings associated with the candidate
        // console.log("+++++++++++++++++++++++++++",privateScreenings);
      if(privateScreenings.length > 0)
        {
             SetPrivateCandidate(true)
        }
        else{
          SetPrivateCandidate(false)
        }
    
        // Further processing or rendering can be done here
        // For example, you can update state or trigger UI updates with this data
      } catch (error) {
        // console.error('Error fetching candidate private screenings:', error);
        SetPrivateCandidate(false)
      }
    };
    
    // Call the function to fetch candidate's private screenings
    getCandidatePrivateScreenings();

    // Testing code End


  }, []);
  useEffect(() => {
    const getCandidateProfile = async () => {
      try {
        // Retrieve the candidateProfileEmailID from local storage
        const candidateProfileEmailID = localStorage.getItem(
          "candidateProfileEmailID"
        );

        // Update the content of the span element with the candidateProfileEmailID
        setCandidateEmail(candidateProfileEmailID);

        // Fetch candidate profiles
        const userProfileList = [];
        let nextToken = null;
        do {
          const userProfileResponse = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              filter: { email: { eq: candidateProfileEmailID } },
              limit: 100,
              nextToken: nextToken,
            })
          );
          userProfileList.push(
            ...userProfileResponse.data.listCandidateProfiles.items
          );
          nextToken = userProfileResponse.data.listCandidateProfiles.nextToken;
        } while (nextToken);

        // Check if userProfileList is not empty, then navigate to CandidateDashboard
        if (userProfileList.length > 0) {
          navigate("/CandidateDashboard"); // Replace "/CandidateDashboard" with your actual route
        }
      } catch (error) {
        console.log("Error fetching candidate profiles:", error);
      }
    };

    getCandidateProfile();
  }, [navigate]);
  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };

  // console.log(accepted);
  // console.log(userId);
  // console.log("private candidate",privateCandidate)

  const handleAccept = async () => {

    setLoading(true); // Set loading state to true when API request starts

    if (accepted && userId) {
      const input = {
        userID: userId,
        t_c: accepted,
        email: candidateEmail, // Removed the extra comma here
        fullName: "",
        gender: "",
        mobileNumber: "",
        description: "",
        matchedPercentage: 0,
        workStatus: "",
        currentCity: "",
        educationDetails: "",
        keySkills: "",
        workPreference: "",
        preferredSalary: 0,
        photoURL: "",
        isPublic: true,
        resumeURL: "",
        private_Candidate:privateCandidate
      };

      try {
        const result = await API.graphql(
          graphqlOperation(createCandidateProfile, { input })
        );
        console.log(input);
        console.log(result);

        const profileId = result.data.createCandidateProfile.id;
        localStorage.setItem("candidateProfileId", profileId);
        localStorage.setItem(
          "candidateProfile",
          JSON.stringify(result.data.createCandidateProfile)
        );

        window.location.href = "/candidateProfileReg";
      } catch (error) {
        console.error("Error creating candidate profile:", error);
        setLoading(false); // Reset loading state even if request fails
      }
    } else {
      alert("Please accept the terms and policies to proceed.");
      setLoading(false); // Reset loading state if conditions are not met
    }
  };

  return (
    <div className="container mx-auto mt-8">
      {/* Centered Logo */}
      <div className="flex justify-center  mb-4">
        <img src={logo} alt="Logo" style={{ width: "360px" }} />{" "}
        {/* Adjust the height (h-20) as needed */}
      </div>
      <h2 className="text-2xl font-bold mb-4 " style={{}}>
        Terms and Policy
      </h2>
      <div className="flex flex-col md:flex-row gap-8">
        {/* First Part: Terms and Policy */}

        <div
          className=" p-6 rounded shadow flex-1 "
          style={{
            maxHeight: "60vh",
            overflowY: "auto",
            color: "#ffffff",
            backgroundColor: "transparent",
            border: "1px solid #9900ff",
          }}
        >
          <h3>1. Binding Agreement</h3>
          <p>
            1.1 Varahi Technologies Pvt Ltd (CIN: U72900PN2017PTC170554),
            located at 102, Townhouse 1, K Raheja Viva, Pune ("GetScreened",
            "we", "us", and "our") provides a platform that facilitates
            interactions between job candidates and an AI-powered screening and
            assessment engine. The terms of this Agreement are between you and
            GetScreened, and your access and use of the Platform constitutes
            your consent to this Agreement and its terms.
          </p>
          <p>
            1.2 We may update the terms of this Agreement from time to time. We
            will notify you of such changes either via the email address
            provided by you on registration or through an announcement on the
            Platform. The changes will apply to the access and use of the
            Platform upon such notice. Your continued access and use of the
            Platform after such notice has been given indicates your agreement
            to be bound by the new terms.
          </p>
          <h3>2. Definitions</h3>
          <p>
            "Candidate" means a user who creates a Candidate Profile for
            accessing and using the Platform to participate in an AI-powered
            screening, assessment, and interview process.
          </p>
          <p>
            "Candidate Profile" means a profile created by a Candidate that may
            include personal details, resume, photo, video, skills, and
            professional interests.
          </p>
          <p>
            "Intellectual Property Rights" means all patents, trade names, trade
            marks, service marks, logos, design rights, copyrights, trade
            secrets, database rights, rights in technology, knowhow, or other
            intellectual property rights (whether registered or unregistered)
            that are protected under any applicable law, and all applications,
            renewals and extensions of the same.
          </p>
          <p>
            "Personal Data" means data, whether true or not, about an individual
            who can be identified (a) from that data; or (b) from that data and
            other information to which we have or are likely to have access.
          </p>
          <p>
            "Platform" means the GetScreened platform and any service or feature
            provided thereon by GetScreened or its affiliates or agents, whether
            directly or indirectly, including through application programming
            interface (API) integration and/or hyperlinks.
          </p>
          <p>
            "User Content" means all information, including but not limited to
            data, text, videos, photographs, graphics, messages, music, or other
            materials submitted, posted, displayed or otherwise provided by
            users on or through the Platform, and includes Candidate Profiles.
          </p>
          {/* Registration */}
          <h3>3. Registration</h3>
          <p>
            3.1 You must be at least 18 years of age to register an account on
            the Platform.
          </p>
          <p>
            3.2 On registration, you will be asked to create a Candidate
            Profile. You must ensure that the details provided by you on
            registration or at any time thereafter are accurate, up to date, and
            complete.
          </p>
          <p>
            3.3 We reserve the right to delete your account and all of your
            information after a period of inactivity, but in any case only after
            we have provided you with notice accordingly.
          </p>
          <p>
            3.4 You may cancel your registration with the Platform at any time
            by informing us in writing. Upon cancellation of your registration,
            you agree to stop accessing and using the Platform.
          </p>

          {/* Password and Security */}
          <h3>4. Password and Security</h3>
          <p>
            4.1 You agree to keep your login details confidential and not to
            disclose it to any third party.
          </p>
          <p>
            4.2 You are strictly liable and responsible for all uses of your
            account and password, whether or not authorised by you.
          </p>
          <p>
            4.3 You shall notify us immediately if you know or suspect that your
            account has been compromised.
          </p>
          <p>
            4.4 If we have reason to believe that there is likely a breach of
            your account or misuse of the Platform using your account, we may
            require you to reset your password or may suspend your account.
          </p>
          <p>
            4.5 As a result of your loss of your login details or the misuse of
            the Platform, you shall:
          </p>
          <ul>
            <li>a) bear all losses or damage incurred thereby; and</li>
            <li>
              b) fully indemnify GetScreened should GetScreened suffer any loss
              or damage.
            </li>
          </ul>

          {/* Use of Platform */}
          <h3>5. Use of Platform</h3>
          <p>
            5.1 GetScreened hereby grants you a limited, terminable,
            non-exclusive right to access and use the Platform for your
            personal, non-commercial purposes as follows:
          </p>
          <ul>
            <li>
              a) participating in AI-powered screening, assessment, and
              interview processes; and
            </li>
            <li>
              b) any other purposes reasonably related to the purposes specified
              above.
            </li>
          </ul>
          <p>
            5.2 Your permission to access and use the Platform is personal to
            you and is non-transferrable and non-assignable. As part of the
            screening, assessment, and interview processes, you may be required
            to participate in activities such as video and audio interviews,
            psychometric assessments, and gaming tests. You acknowledge and
            agree that your participation in such activities on the Platform may
            be recorded, and you hereby consent to such recording, including the
            use of your name, likeness, voice and persona in connection with
            your use of the Platform, and to GetScreened's access to and use of
            any such recordings and your Candidate Profile in accordance with
            Clause 9 (Personal Data).
          </p>
          <p>5.3 You may not access or use the Platform:</p>
          <ul>
            <li>a) for any unlawful or fraudulent purpose;</li>
            <li>
              b) to disseminate or publish any content that is false,
              inaccurate, unlawful, harassing, defamatory, misleading, abusive,
              threatening, harmful, violent, obscene, vulgar, or otherwise
              objectionable;
            </li>
            <li>
              c) to modify, adapt, translate, reverse engineer, decompile, or
              disassemble any part of the Platform without our prior consent;
            </li>
            <li>
              d) to interfere with any other person's access to or use of the
              Platform;
            </li>
            <li>e) to post any content on behalf of another party;</li>
            <li>
              f) to access information not intended for you, or to access a
              server or account which you are not authorised to access;
            </li>
            <li>
              g) to interfere with or disrupt the operation of the Platform, or
              the servers or networks used to make the Platform available or
              violate any requirements, procedures, policies or regulations of
              such networks;
            </li>
            <li>
              h) to create a database by systematically downloading and storing
              content available on the Platform;
            </li>
            <li>i) to sell, promote or advertise products or services; and</li>
            <li>j) in any manner in breach of this Agreement.</li>
          </ul>
          <p>
            5.4 We reserve the right to waive any of the conditions in Clause
            5.4 either generally or in specific instances.
          </p>
          <p>
            5.5 We may, but are not obliged to, remove, edit, suspend, block,
            and/or monitor user accounts that we determine, in our sole
            discretion, violates the terms of this Agreement or any applicable
            law.
          </p>
          {/* Candidate Data */}
          <h3>6. Candidate Data</h3>
          <p>
            6.1 As part of the screening, assessment, and interview processes,
            you may be asked to participate in activities which may be
            customized such as video and audio interviews, psychometric
            assessments, and gaming tests, on the Platform. Your participation
            in these activities will be recorded, processed, and analyzed as
            Candidate Data by GetScreened.
          </p>
          <p>
            6.2 GetScreened will no longer have access to your Candidate Data
            if:
          </p>
          <ul>
            <li>a) your Candidate Profile is deleted or otherwise removed;</li>
            <li>b) your account is deleted or otherwise removed; or</li>
            <li>
              c) you withdraw from the screening, assessment, and interview
              processes.
            </li>
          </ul>

          {/* Availability of Services */}
          <h3>7. Availability of Services</h3>
          <p>
            7.1 We will apply reasonable efforts to ensure that the Platform is
            available at all times. However, we cannot guarantee that the
            Platform or any part or feature thereof will always be available or
            error-free although we will attempt to correct the problem as soon
            as reasonably possible.
          </p>
          <p>
            7.2 Access to the Platform may be restricted from time to time to
            allow for the repair, maintenance, or update of the Platform. We
            will attempt to restore access to the Platform as soon as we
            reasonably can.
          </p>

          {/* Intellectual Property */}
          <h3>8. Intellectual Property</h3>
          <p>
            Subject to Clause 8 (User Content), all Intellectual Property Rights
            in the Platform and all of the text, pictures, videos, graphics,
            user interfaces, visual interfaces, trademarks, logos, applications,
            programs, computer code, and other content made available on it are
            owned by GetScreened. You may not reproduce, modify, distribute, or
            otherwise use for commercial purposes any such content without our
            written consent, except as provided in this Agreement or permitted
            by law.
          </p>

          {/* User Content */}
          <h3>9. User Content</h3>
          <p>
            9.1 We do not claim ownership of any User Content that you provide
            on or through our Platform. You represent and warrant that you own
            or are otherwise authorised to use in any manner such User Content
            provided by you.
          </p>
          <p>
            9.2 We do not represent, endorse, or guarantee the accuracy,
            veracity, or reliability of User Content (whether provided by you or
            other persons) or derivative works therefrom. Any reliance on User
            Content will be at your own risk.
          </p>
          <p>
            9.3 You acknowledge and understand that all User Content is the sole
            responsibility of the person from whom the User Content originated.
          </p>
          <p>
            9.4 Any User Content that you upload or otherwise provide on or to
            the Platform may not contain:
          </p>
          <ul>
            <li>
              a) names, logos, or trademarks of unaffiliated persons, unless the
              necessary consent has been obtained from them; and
            </li>
            <li>b) information that is false, misleading, or inaccurate.</li>
          </ul>
          <p>
            9.5 By submitting, posting, displaying, or otherwise providing User
            Content on or through the Platform, you grant GetScreened a
            worldwide, non-exclusive, royalty-free license to reproduce, adapt,
            distribute, and publish the User Content in its sole discretion and
            in accordance with Clause 9 (Personal Data).
          </p>
          <p>
            9.6 We may review and remove any User Content that we determine, in
            our sole discretion, violates the terms of this Agreement or any
            applicable law.
          </p>
          {/* Personal Data */}
          <h3>10. Personal Data</h3>

          {/* Collection of Personal Data */}
          <h4>10.1 Collection of Personal Data</h4>
          <p>
            10.1.1 We may collect your Personal Data in the following instances
            for which you hereby consent to:
          </p>
          <ul>
            <li>
              a) when you provide it to us (e.g., by registering an account with
              the Platform or updating your Candidate Profile);
            </li>
            <li>
              b) automatically when you access or use the Platform; and from
              third parties, but only where we have made reasonable efforts to
              verify that the third parties either have your consent or are
              otherwise legally permitted or required to disclose your Personal
              Data to us.
            </li>
          </ul>
          <p>
            10.1.2 The Personal Data that we may collect includes, but is not
            limited to:
          </p>
          <ul>
            <li>
              a) name, gender, date of birth, nationality, country and city of
              birth;
            </li>
            <li>
              b) mailing address, telephone numbers, email address, and other
              contact details;
            </li>
            <li>
              c) resume, educational and professional qualifications and
              certifications, and employment references;
            </li>
            <li>d) employment and training history;</li>
            <li>e) professional interests;</li>
            <li>f) photographs and videos;</li>
            <li>g) usage data from your access and use of the Platform;</li>
            <li>
              h) interview recordings and information you provide in online
              assessments conducted using the Platform.
            </li>
          </ul>

          {/* Purpose of collecting and using Personal Data */}
          <h4>10.2 Purpose of collecting and using Personal Data</h4>
          <p>
            10.2.1 Your Personal Data may be collected and used by us for the
            following purposes:
          </p>
          <ul>
            <li>
              a) to operate, maintain, enhance, develop, test, provide, and
              monitor the effectiveness of the Platform;
            </li>
            <li>
              b) to verify your identity and manage your registration with the
              Platform;
            </li>
            <li>
              c) to facilitate the screening, assessment, and interview
              processes;
            </li>
            <li>
              d) to personalize the Platform for your preferences and interests;
            </li>
            <li>
              e) to respond to, handle, and process queries, requests,
              applications, complaints, and feedback from you;
            </li>
            <li>f) to contact you for administrative purposes;</li>
            <li>
              g) to understand and analyze the usage trends, user preferences,
              and interests;
            </li>
            <li>h) to diagnose and troubleshoot errors on the Platform;</li>
            <li>i) to detect and monitor illegal activity;</li>
            <li>
              j) any other incidental business purposes related to or in
              connection with the above;
            </li>
            <li>k) where we are required to do so by law.</li>
          </ul>

          {/* Withdrawal of consent */}
          <h4>10.3 Withdrawal of Consent</h4>
          <p>
            10.3.1 The consent that you provide for the collection, use, and
            disclosure of your Personal Data in accordance with Clause 10.2 will
            remain valid until such time as it is withdrawn by you in writing.
          </p>

          {/* Access to and correction of Personal Data */}
          <h4>10.4 Access to and correction of Personal Data</h4>
          <p>
            10.4.1 Should you wish to access a copy of the Personal Data which
            we hold about you or correct any of your Personal Data which we hold
            about you, you may submit a written request to our Data Protection
            Officer at the contact details provided below.
          </p>

          {/* Security of Personal Data */}
          <h4>10.6 Security of Personal Data</h4>
          <p>
            10.6.1 To safeguard your Personal Data from unauthorized access,
            collection, use, disclosure, copying, modification, disposal, or
            similar risks, we will use reasonably appropriate administrative,
            physical, and technical measures to secure all storage and
            transmission of Personal Data by us.
          </p>

          {/* Data Protection Inquiry */}
          <h4>10.8 Data Protection Inquiry</h4>
          <p>
            You may contact our Data Protection team if you have any inquiries
            or feedback on our Personal Data protection policies and procedures,
            or if you wish to make any request, in the following manner:
          </p>
          <p>Email: getscreened@varahitechnologies.com</p>

          {/* Third Party Websites */}
          <h3>11. Third Party Websites</h3>
          <p>
            11.1 The Platform may contain links to other online resources and
            locations. We are not responsible for, and do not endorse, the
            content of these external resources or locations.
          </p>
          <p>
            11.2 You may create a link to the Platform subject to the following
            conditions:
          </p>
          <ul>
            <li>
              a) the link is not presented in a way that is misleading or that
              may suggest any form of endorsement, approval, or association by
              us that does not exist, or is harmful to our reputation.
            </li>
          </ul>
        </div>

        {/* Second Part: Images */}
        <div className="hidden md:block flex-1">
          <img src={TandCImage} alt="T&C" className="w-full h-auto" />
        </div>
      </div>
      <div
        className="flex flex-col items-start mt-4 mb-20"
        style={{ marginBottom: "20px" }}
      >
        <label className="flex items-center mb-2">
          <input
            type="checkbox"
            checked={accepted}
            onChange={handleCheckboxChange}
            className="form-checkbox h-5 w-5 text-indigo-600"
          />
          <span className="ml-2 text-white">
            I accept the terms and policies
          </span>
          <button
            style={{
              border: "1px solid #ffffff",
              color: "#ffffff",
            }}
            onClick={handleAccept}
            // disabled={!accepted}
            disabled={!accepted || loading}
            className={`ml-4  hover:bg-blue-700  font-bold py-2 px-4 rounded ${
              !accepted ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? 'Loading...' : 'Accept'}
          </button>
        </label>
      </div>
    </div>
  );
};

export default TermsPolicy;
