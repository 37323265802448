import React from 'react'
import { useEffect } from 'react';
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-FH8ZCP75PW'; // Replace with your tracking ID
// ReactGA.initialize(TRACKING_ID);

const GoogleAnalytics = () => {

    useEffect(()=>{

    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    },[])
    
  return null;
}

export default GoogleAnalytics
