import React, { useEffect, useState } from "react";
import { API, graphqlOperation, Amplify, Auth } from "aws-amplify";
import { listCandidateProfiles } from "../graphql/queries";
import "./WhoAreYou.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
// import Student1 from '../../public/Student.svg'
// import candidate1 from '../../public/candidate.svg'
import Student from "../assets/Student.svg"
import candidate from '../assets/candidate.svg'
import Header from "../components/header/Header";
// import { useHistory } from 'react-router-dom';

const WhoAreYou = () => {
  const [candidateProfileExists, setCandidateProfileExists] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState({
    company: false,
    student: false,
    varahi: false
  });
  // const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);



  const navigate = useNavigate();
  useEffect(() => {
    checkcandidateProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const candidateProfileAuthID = localStorage.getItem(
      "candidateProfileAuthID"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSignUpClick = () => {
    navigate("/TermsPolicy");
  };
  const CandidateDashboardClick = () => {
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );

    // Check if the email domain is @gmail.com
    console.log(candidateProfileEmailID)
    if (
      // candidateProfileEmailID &&
      candidateProfileEmailID.endsWith("@gmail.com")
    ) {
      // Disable navigation for users with @gmail.com email
      // alert(
      //   "Sorry, users with Gmail accounts cannot access the company dashboard."
      // );
      navigate("/TermsPolicy")
    } else {
      // Navigate to the company dashboard
      // navigate("/CompanyDashboard");
      navigate("/TermsPolicy")
      // alert(
      //   "Sorry, users with Gmail accounts cannot access the company dashboard."
      // );
      //  alert(
      //   "Candidate Section"
      // );
      
       
    }
  };

  const CompanyDashboardClick = () => {
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );

    // Check if the email domain is @gmail.com
    console.log(candidateProfileEmailID)
    if (
      // candidateProfileEmailID &&
      !candidateProfileEmailID.endsWith("@gmail.com")
    ) {
      // Disable navigation for users with @gmail.com email
      // alert(
      //   "Sorry, users with Gmail accounts cannot access the company dashboard."
      // );
      navigate("/CompanyRegisterForm");
      // navigate("/CompanyRegisterForm")
    } else {
      
      alert(
        "Sorry, Personal Email users accounts cannot access the company dashboard."
      );
      
       
    }
  };

 

  async function checkcandidateProfile() {
    try {
      let nextToken = null;
      let candidateProfile = null;

      do {
        const candidateProfileData = await API.graphql(
          graphqlOperation(listCandidateProfiles, {
            filter: {
              userID: {
                eq: localStorage.getItem("candidateProfileAuthID"),
              },
            },
            limit: 100, // Adjust the limit as per your requirement
            nextToken: nextToken,
          })
        );

        const items = candidateProfileData.data.listCandidateProfiles.items;
        if (items.length > 0) {
          candidateProfile = items[0];
          setCandidateProfileExists(true);
          localStorage.setItem("candidateProfileId", candidateProfile.id);
          localStorage.setItem(
            "candidateProfile",
            JSON.stringify(candidateProfile)
          );
          break; // Exit the loop since a profile is found
        } else {
          // Continue to next iteration if there are more records to fetch
          nextToken = candidateProfileData.data.listCandidateProfiles.nextToken;
        }
      } while (nextToken);

      // If no profile found after exhausting all pages
      if (!candidateProfile) {
        console.log("No profile found for this candidate ID.");
        setCandidateProfileExists(false);
      }
    } catch (error) {
      console.error("Error fetching candidate profile", error);
      setCandidateProfileExists(false);
    }
  }


  // checkbox code


  // const handleCheckboxChange = (option) => {
  //   setSelectedOptions({
  //     ...selectedOptions,
  //     [option]: !selectedOptions[option]
  //   });
  // };


  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // // Capture the selected options and perform necessary actions (e.g., navigating to a route)
  //   // const selected = Object.keys(selectedOptions).filter(option => selectedOptions[option]);
  //   // console.log("Selected options:", selected);
  //   // // Example: Navigate to a route based on the selected options
  //   // history.push(`/result?selected=${selected.join(',')}`);
  //   if (selectedOptions.company === true) {
  //     console.log("comapany")
  //   }
  //   else if (selectedOptions.company === true) {

  //   }
  //   else {

  //   }
  // };


  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  // console.log(selectedOptions)
  // console.log(selectedOption)

  // const handleSubmit = (event) => {
  //   event.preventDefault();
   
  //   if (selectedOption === "company") {
  //     console.log("its is comapany")
  //     navigate('/CompanyRegisterForm')
  //   }
  //   else if (selectedOption === "student") {
  //     console.log("its is student")
  //     navigate('/TermsPolicy')
  //   }
  //   else {
  //     console.log("its is varahi")
  //   }

   
  // };


  return (
    <div>
      
      <Header/>
    
    <div className="main-container">
      {/* <header className="header">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </header> */}
      {/* <div className="sections">
        <div className="section">
          <div className="content">
            <div className="icon-container">
              Add your icon or illustration
            </div>
            <h2>Share Your Expertise</h2>
            <p>
              Are you ready to inspire and educate? Join our platform to share
              your knowledge and experience through interviews. Connect with
              eager learners and make an impact!
            </p>
            <button className="btn" onClick={handleSignUpClick}>
              Start Sharing
            </button>
          </div>
        </div>
        <div className="section">
          <div className="content">
            <div className="icon-container">
              Add your icon or illustration
            </div>
            <h2>Discover Insights</h2>
            <p>
              Explore a world of knowledge and inspiration. Watch insightful
              interviews conducted by experts in various fields. Expand your
              horizons and learn something new today!
            </p>
            <button className="btn" onClick={CompanyDashboardClick}>
              Start Exploring
            </button>
          </div>
        </div>
      </div> */}

     {/* second */}

      {/* <div className=" flex flex-col items-center gap-4 text-center">

        <h1>Get started with GetScreened</h1>

        <div className="flex  justify-between">
          <form onSubmit={handleSubmit}>
            <div className=" py-2 flex gap-3  w-[100px] ">
              <input

                type="radio"
                value="company"
                checked={selectedOption === "company"}
                onChange={handleOptionChange}
              />
              <label>
                Company
              </label>
            </div>
    
            <div className="py-2  flex gap-3  w-[100px]" >

              <input

                type="radio"
                value="student"
                checked={selectedOption === "student"}
                onChange={handleOptionChange}
              />
              <label>
                Candidate
              </label>
            </div>

            <div className="py-2  flex gap-3  w-[100px] " >
              <input
              
                type="radio"
                value="varahi"
                checked={selectedOption === "varahi"}
                onChange={handleOptionChange}
              />
              <label>
                Varahi
              </label>
            </div>

           
            <button className="justify-center mt-7 px-8 py-2 border-1 rounded-lg hover:bg-[#6610f2]" type="submit">Submit</button>
          </form>

        </div>


      </div> */}

      {/* third page */}

      <div className="bg-transparentS min-h-screen ">
      <div className="container mx-auto p-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
          {/* Candidate Column */}
          <div className="bg-transparent rounded-lg shadow-md  overflow-hidden border-1 ">
            <div className="p-6">
              <div className="text-center">
                <img src={candidate}alt="Candidate" className="h-[400px] w-100 mx-auto mb-4" />
              </div>
              <h2 className="text-2xl font-semibold text-center mb-4">Candidate</h2>
              <ul className="list-disc pl-8">
                <li>Experience AI interviews focused on your skills, ensuring fairness.</li>
                <li>Get evaluated on your true abilities, not just your resume.</li>
                <li>Receive immediate insights to improve your interview skills.</li>
                <li>Your expertise attracts employers, exposing profiles to top companies.</li>
                <li>With AI algorithms, job opportunities match you that fit your unique skills.</li>
              </ul>
              <div className="text-center">
                <button onClick={CandidateDashboardClick} className=" mt-6 py-2 px-7 rounded-lg border-1 hover:bg-purple-600 font-bold ">Candidate</button>
              </div>
            </div>
          </div>
          {/* Recruiter Column */}
          <div className="bg-transparent rounded-lg shadow-md overflow-hidden border-1">
            <div className="p-6">
              <div className="text-center">
                <img src={Student} alt="Recruiter" className="h-[400px] w-100  mx-auto mb-4" />
              </div>
              <h2 className="text-2xl font-semibold text-center mb-4">Company</h2>
              <ul className="list-disc pl-8">
                <li>Automates screening, presenting only top-tier candidates.</li>
                <li>Customizable Interviews: Tailor questions to company-specific needs.</li>
                <li>Efficiency: Drastically cuts down time-to-hire.</li>
                <li>Enhances candidate quality through skill-based matching.</li>
                <li>HR Integration: Seamless workflow with existing HR systems.</li>
              </ul>
              <div className="text-center">
                {/* <button className="mt-6 py-2 px-7 rounded-lg border-1 hover:bg-purple-600 font-bold">Company</button> */}
                <button onClick={CompanyDashboardClick} className="mt-6 py-2 px-7 rounded-lg border-1 hover:bg-purple-600 font-bold">Company</button>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
      



    </div>

    </div>
  );
};

export default WhoAreYou;
