import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
// import "./CandidateProfile.css"; // Import your CSS file for styling
import img from "../assets/profileimg.png";
import logo from "../assets/logo.svg";

function CandidateProfile() {
  return (
    <div>
      {/* Header Section */}
      <header style={{ backgroundColor: "#0f1221", padding: "10px 0" }}>
        {" "}
        {/* Set the background color and padding */}
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <img src={logo} alt="Company Logo" style={{ height: "64px" }} />
            </div>
            <div className="col-auto">
              <a href="/editprofile" className="mr-5">
                <FontAwesomeIcon icon={faEdit} style={{ color: "#fff" }} />
              </a>
              <a
                href="/signuppage.html"
                className="btn  sign-out-btn"
                style={{ backgroundColor: "#9900ff", color: "#fff" }}
              >
                Sign Out
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* Main Section */}
      <main>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-center">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginBottom: "20px", marginTop: "20px" }}
              >
                <img
                  src={img}
                  alt="Profile Image"
                  className="profile-image"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    border: "0.5px solid #9900ff",
                  }}
                />
              </div>
              <div
                className="d-flex justify-content-center align-items-center flex-column"
                style={{
                  border: "2px solid #9900ff",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <h2>Sachin G</h2>
                <p>
                  <span>Educational Details :</span>
                  {"  Bachelor of Technology in Computer Science "}
                </p>
                <p>
                  <span>Educational Details :</span>
                  {"  Bachelor of Technology in Computer Science "}
                </p>
                <p>
                  <span>Educational Details :</span>
                  {"  Bachelor of Technology in Computer Science "}
                </p>
                <p>
                  <span>Educational Details :</span>
                  {"  Bachelor of Technology in Computer Science "}
                </p>
                {/* Add other information about the candidate here */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default CandidateProfile;
