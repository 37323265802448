import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listAdminAddCandidateContexts,
  listInterviewEvaluations,
  listCandidateProfiles,
} from "../graphql/queries";
import { updateCandidateProfile } from "../graphql/mutations";
import logo from "../assets/logo.svg"; // Adjusted import path for logo.svg
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons"; // Importing user circle icon
import "./CandidateResumeDetails.css"; // Import CSS file for styling
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import profilephoto from '../assets/profile.png'



const fetchDataFromAPIs = async (
  candidateProfileEmailId,
  setProfiles,
  setInterviewEvaluations,
  setCandidateProfile,
  setPrivateCandidate
) => {
  try {
    // Fetch data from the first API (listAdminAddCandidateContexts)
    let nextToken = null;
    let adminContextItems = [];
    do {
      const adminContextResponse = await API.graphql(
        graphqlOperation(listAdminAddCandidateContexts, {
          filter: {
            candidateEmail: { eq: candidateProfileEmailId },
          },
          limit: 100, // Adjust the limit as per your requirement
          nextToken: nextToken,
        })
      );
      adminContextItems = [
        ...adminContextItems,
        ...adminContextResponse.data.listAdminAddCandidateContexts.items,
      ];
      nextToken =
        adminContextResponse.data.listAdminAddCandidateContexts.nextToken;
    } while (nextToken);
    const filteredProfiles = adminContextItems;
    setProfiles(filteredProfiles);

    // Fetch data from the second API (listInterviewEvaluations)
    nextToken = null;
    let interviewEvaluationsItems = [];
    do {
      const interviewEvaluationsResponse = await API.graphql(
        graphqlOperation(listInterviewEvaluations, {
          filter: {
            candidateEmail: { eq: candidateProfileEmailId },
          },
          limit: 100, // Adjust the limit as per your requirement
          nextToken: nextToken,
        })
      );
      interviewEvaluationsItems = [
        ...interviewEvaluationsItems,
        ...interviewEvaluationsResponse.data.listInterviewEvaluations.items,
      ];
      nextToken =
        interviewEvaluationsResponse.data.listInterviewEvaluations.nextToken;
    } while (nextToken);
    setInterviewEvaluations(interviewEvaluationsItems);

    // Fetch candidate profile data (listCandidateProfiles)
    nextToken = null;
    let candidateProfileItem = null;
    do {
      const candidateProfileResponse = await API.graphql(
        graphqlOperation(listCandidateProfiles, {
          filter: {
            email: { eq: candidateProfileEmailId },
          },
          limit: 100, // Adjust the limit as per your requirement
          nextToken: nextToken,
        })
      );
      const items = candidateProfileResponse.data.listCandidateProfiles.items;

      if (items.length > 0) {
        candidateProfileItem = items[0];
        break; // Exit the loop since a profile is found
      } else {
        // Continue to next iteration if there are more records to fetch
        nextToken =
          candidateProfileResponse.data.listCandidateProfiles.nextToken;
      }
    } while (nextToken);

    setCandidateProfile(candidateProfileItem);
    setPrivateCandidate(candidateProfileItem.private_Candidate)
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};




const Showmyskill = (signOut) => {
  const [profiles, setProfiles] = useState([]);
  const candidateProfileEmailId = localStorage.getItem(
    "candidateProfileEmailID"
  );
  const [candidateProfile, setCandidateProfile] = useState(null); // State to hold candidate profile details
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [candidateEmail, setCandidateEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const navigate = useNavigate();
  const [gettingdata, setGettingdata] = useState(false)
  const [updateprivate, setUpdateprivate] = useState(false)
  const [privateCandidate, setPrivateCandidate] = useState(false)



  const [isPublic, setIsPublic] = useState(
    // candidateProfile ? candidateProfile.isPublic : true
    candidateProfile && candidateProfile.isPublic === true ? true : false
  );

  console.log("is private ###################", candidateProfile)
  console.log("is private canndiadte ###################", privateCandidate)



  const handleTogglePublic = () => {
    const newIsPublicValue = !isPublic;
    setIsPublic(newIsPublicValue);
    updateIsPublicInDatabase(newIsPublicValue);
  };

  const TogglePublic = ({ isPublic, handleTogglePublic }) => {
    return (
      <div className="toggle-public mb-7 flex flex-col gap-3">
        {/* <label className="toggle-label">
        <input
          type="checkbox"
          checked={isPublic}
          onChange={handleTogglePublic}
          className="toggle-checkbox"
          value={isPublic}
        />
        <span className="toggle-button"></span>
        <span className="toggle-text">{isPublic ? "Public" : "Private"}</span>
        <span className="company-label">
          {isPublic ? "Company for Public" : "Company for Private"}
        </span>
      </label> */}

        {/* <button className="" onClick={changestate}>
        {   
          candidateProfile.isPublic ? <span className="bg-green-600 p-2 capitalize">Public</span> : <span className="bg-red-400 p-2 capitalize rounded-sm">private</span>
        }
      </button> */}
        <div>
          <t5 className="capitalize font-medium">control who can view your profile</t5>
        </div>
        <div>

          <button
            onClick={changestate}
            className={`p-2 capitalize ${candidateProfile.isPublic ? 'bg-green-600 rounded-lg uppercase' : 'bg-red-400 rounded-lg uppercase'}`}
          >
            {candidateProfile.isPublic ? <FaLockOpen /> : <FaLock />}
          </button>


          <span className="ml-3 text-gray-500">
            {
              candidateProfile.isPublic === true ? "Visible to everyone" : "visible for editor"
            }
          </span>


        </div>

      </div>
    );
  };


  // true false 

  const changestate = () => {
    console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", candidateProfile.isPublic)
    const change = !candidateProfile.isPublic
    setGettingdata(change)
    // setIsPublic(change)
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", change)
    updateIsPublicInDatabase(change)
  }
  console.log(">>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<", isPublic)
  // Fetch candidate profile details from localStorage on component mount
  useEffect(() => {
    const candidateProfileEmailId = localStorage.getItem(
      "candidateProfileEmailID"
    );
    if (candidateProfileEmailId) {
      const storedProfile = JSON.parse(
        localStorage.getItem("candidateProfile")
      );
      setCandidateProfile(storedProfile);
    }
  }, []);

  console.log("Inter view evalution data", interviewEvaluations)
  console.log(typeof (interviewEvaluations))

  useEffect(() => {
    fetchDataFromAPIs(
      candidateProfileEmailId,
      setProfiles,
      setInterviewEvaluations,
      setCandidateProfile,
      setPrivateCandidate
    );
  }, [candidateProfileEmailId, gettingdata, updateprivate]);

  // console.log("profiles test1", profiles);
  // console.log("interviewEvaluations test2 responce from namdev sir", interviewEvaluations);
  // console.log("candidateProfile test3",candidateProfile)

  // Function to handle showing profile info after interview completion


  useEffect(() => {
    // Retrieve the candidateProfileEmailID from local storage
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );

    // Update the content of the span element with the candidateProfileEmailID
    setCandidateEmail(candidateProfileEmailID);
  }, []);
  // Function to toggle modal


  const updateIsPublicInDatabase = async (isPublicValue) => {
    try {
      const input = {
        id: candidateProfile.id,
        isPublic: isPublicValue,
      };

      setUpdateprivate(true)

      const result = await API.graphql(
        graphqlOperation(updateCandidateProfile, { input })
      );

      console.log(
        "Updated isPublic:",
        result.data.updateCandidateProfile.isPublic
      );

    } catch (error) {
      console.error("Error updating isPublic:", error);
      updateprivate(false)
    }
  };






  return (
    <div>
      {/* Header Section */}
      <Header />

      {/* Container for centering content */}
      <div className="container" style={{ color: "#fff", width: "80%" }}>
        {" "}
        {/* Increased width to 80% */}
        {/* Your JSX code to display candidate details */}
        {profiles &&
          profiles.map((profile, index) => (
            <div className="card" key={index} style={{ marginTop: "50px" }}>
              <div className="card-body">
                <div className="profile-header">
                  <img
                    src={candidateProfile.photoURL || profilephoto}
                    alt="dev"
                    className="profile-img"
                  />
                  <div>
                    <h5 className="card-title" style={{ color: "#fff" }}>
                      {`${candidateProfile.fullName}`}
                    </h5>
                    <strong style={{ color: "#fff" }}>
                      {" "}
                      Work Status: {candidateProfile.workStatus}
                    </strong>

                    {/* Assuming workStatus represents experience */}
                    <br />
                  </div>
                </div>
                <br />
                <p className="card-text" style={{ color: "#fff" }}>
                  {candidateProfile.description}
                </p>

                <div className="skills items-center gap-1 mt-10">
                  <strong style={{ color: "#fff" }}>Skills :</strong>
                  <br />
                  {candidateProfile.keySkills &&
                    candidateProfile.keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}
                </div>

                <TogglePublic
                  isPublic={isPublic}
                  handleTogglePublic={handleTogglePublic}
                />

                <h5 className="card-title" style={{ color: "#fff" }}>
                  Interview Video
                </h5>
                <video
                  controls
                  style={{ width: "100%", border: "2px solid #ccc" }}
                  controlsList="nodownload" // Add controlsList attribute here
                >
                  <source src={profile.candidateVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="transcript-container">
                  <CustomizedResumeText
                    candidateContextTextFile={profile.candidateContextTextFile}
                  />
                </div>
                <div className="transcript-container">
                  <CustomizedResumeRating
                    candidateContextTextFile={profile.candidateContextTextFile}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      {/*  */}
      <div className="container" style={{ color: "#fff", width: "80%" }}>
        {" "}
        {/* Increased width to 80% */}
        {/* Your JSX code to display candidate details */}
        {interviewEvaluations &&
          interviewEvaluations.map((evaluation, index) => (
            <div className="card" key={index} style={{ marginTop: "50px" }}>
              <div className="card-body">
                <div className="profile-header">
                  <img
                    src={candidateProfile.photoURL || profilephoto}
                    alt="sachin"
                    className="profile-img"
                  />
                  <div>
                    <h5 className="card-title" style={{ color: "#fff" }}>
                      {`${candidateProfile.fullName}`}
                    </h5>
                    <strong style={{ color: "#fff" }}>
                      {" "}
                      Work Status: {candidateProfile.workStatus}
                    </strong>

                    {/* Assuming workStatus represents experience */}
                    <br />
                  </div>
                </div>
                <br />
                <p className="card-text" style={{ color: "#fff" }}>
                  {candidateProfile.description}
                </p>

                {/* <div className="skills">
                  <strong style={{ color: "#fff" }}>Skills</strong>
                  <br />
                  {candidateProfile.keySkills &&
                    candidateProfile.keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}
                </div> */}


                <div className="skills items-center gap-1 mt-10">
                  <strong style={{ color: "#fff" }}>Skills :</strong>
                  <br />
                  {candidateProfile.keySkills &&
                    candidateProfile.keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}
                </div>



                <TogglePublic
                  isPublic={isPublic}
                  handleTogglePublic={handleTogglePublic}
                />
                {
                  // privateCandidate !== true ? (
                  //   <div>

                  //     <h5 className="card-title" style={{ color: "#fff" }}>
                  //       Interview Video
                  //     </h5>
                  //     <video
                  //       controls
                  //       style={{ width: "100%", border: "2px solid #ccc" }}
                  //     >
                  //       <source src={evaluation.videoURL} type="video/mp4" />
                  //       Your browser does not support the video tag.
                  //     </video>
                  //     <h5
                  //       className="card-title"
                  //       style={{ color: "#fff", margin: "1rem" }}
                  //     >
                  //       Interview Transcript
                  //     </h5>
                  //     <div className="transcript-container">
                  //       {evaluation.transcript &&
                  //         JSON.parse(evaluation.transcript).map((item, idx) => (
                  //           <div key={idx}>
                  //             <p>
                  //               <strong>{item.speaker}:</strong> {item.message}
                  //             </p>
                  //           </div>
                  //         ))}
                  //     </div>
                  //     <h5
                  //       className="card-title"
                  //       style={{ color: "#fff", margin: "1rem" }}
                  //     >
                  //       Interview Evaluation
                  //     </h5>
                  //     <div className="transcript-container">
                  //       {evaluation.evaluation &&
                  //         Object.entries(JSON.parse(evaluation.evaluation)).map(
                  //           ([criterion, data], idx) => (
                  //             <div key={idx}>
                  //               <p>
                  //                 <strong>{criterion}:</strong>{" "}
                  //                 {data.score ? data.score : "N/A"} /{" "}
                  //                 {data.max_score ? data.max_score : "N/A"}
                  //               </p>
                  //               <p>
                  //                 <strong>Comments:</strong>{" "}
                  //                 {data.comments ? data.comments : "N/A"}
                  //               </p>
                  //             </div>
                  //           )
                  //         )}
                  //       {/* Add more evaluation criteria as needed */}
                  //     </div>

                  //   </div>
                  // ) : (
                  //   <div className="border border-gray-300 rounded p-4" style={{ width: '100%', border: '0.5px solid #fff9' }}>
                  //     <div className="flex flex-col items-center mb-1">
                  //       <div className="w-8 h-8 flex items-center justify-center mr-2">
                  //         <p className="text-xs font-semibold" style={{ marginBottom: '0.5rem', padding: '0% 6%' }}>Note</p>
                  //       </div>
                  //       {/* <h1 className="" style={{ fontSize: '2.5rem', marginBottom: '20px', fontWeight: '100', color: '#fff9', textAlign: 'justify', lineHeight: '1.75rem', justifyContent: 'end', textAlign: '-webkit-center' }}>
                  //         You are a private screening candidate. Your evaluation results will be shared with the company.
                  //       </h1> */}
                  //       <h1 className=" text-[#fff9] font-light text-lg mb-2 lg:text-center lg:justify-end">
                  //         You are a private screening candidate. Your evaluation results will be shared with the company.
                  //       </h1>
                  //     </div>
                  //   </div>
                  // )
                }
                {
                  privateCandidate !== true ? (
                    <div>
                      <h5 className="card-title" style={{ color: "#fff" }}>
                        Interview Video
                      </h5>
                      <video
                        controls
                        style={{ width: "100%", border: "2px solid #ccc" }}
                        controlsList="nodownload" // Add controlsList attribute here
                      >
                        <source src={evaluation.videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <h5
                        className="card-title"
                        style={{ color: "#fff", margin: "1rem" }}
                      >
                        Interview Transcript
                      </h5>
                      <div className="transcript-container">
                        {evaluation.transcript &&
                          JSON.parse(evaluation.transcript).map((item, idx) => (
                            <div key={idx}>
                              <p>
                                <strong>{item.speaker}:</strong> {item.message}
                              </p>
                            </div>
                          ))}
                      </div>
                      <h5
                        className="card-title"
                        style={{ color: "#fff", margin: "1rem" }}
                      >
                        Interview Evaluation
                      </h5>
                      <div className="transcript-container">
                        {evaluation.evaluation &&
                          Object.entries(JSON.parse(evaluation.evaluation)).map(
                            ([criterion, data], idx) => (
                              <div key={idx}>
                                <p>
                                  <strong>{criterion}:</strong>{" "}
                                  {data.score ? data.score : "N/A"} /{" "}
                                  {data.max_score ? data.max_score : "N/A"}
                                </p>
                                <p>
                                  <strong>Comments:</strong>{" "}
                                  {data.comments ? data.comments : "N/A"}
                                </p>
                              </div>
                            )
                          )}
                        {/* Add more evaluation criteria as needed */}
                      </div>
                    </div>
                  ) : privateCandidate === true ? (
                    <div className="border border-gray-300 rounded p-4" style={{ width: '100%', border: '0.5px solid #fff9' }}>
                      <div className="flex flex-col items-center mb-1">
                        <div className="w-8 h-8 flex items-center justify-center mr-2">
                          <p className="text-xl font-semibold text-red-600" style={{ marginBottom: '0.5rem', padding: '0% 6%' }}>Note</p>
                        </div>
                        <h1 className="text-[#fff9] font-light text-lg mb-2 lg:text-center lg:justify-end">
                          You are a private screening candidate. Your evaluation results will be shared with the company.
                        </h1>
                      </div>
                    </div>
                  ) : (
                    <div className="text-white">
                      Not yet screened
                    </div>
                  )
                }
                {/* original code start */}
                {/* <h5 className="card-title" style={{ color: "#fff" }}>
                  Interview Video
                </h5>
                <video
                  controls
                  style={{ width: "100%", border: "2px solid #ccc" }}
                >
                  <source src={evaluation.videoURL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h5
                  className="card-title"
                  style={{ color: "#fff", margin: "1rem" }}
                >
                  Interview Transcript
                </h5>
                <div className="transcript-container">
                  {evaluation.transcript &&
                    JSON.parse(evaluation.transcript).map((item, idx) => (
                      <div key={idx}>
                        <p>
                          <strong>{item.speaker}:</strong> {item.message}
                        </p>
                      </div>
                    ))}
                </div>
                <h5
                  className="card-title"
                  style={{ color: "#fff", margin: "1rem" }}
                >
                  Interview Evaluation
                </h5>
                <div className="transcript-container">
                  {evaluation.evaluation &&
                    Object.entries(JSON.parse(evaluation.evaluation)).map(
                      ([criterion, data], idx) => (
                        <div key={idx}>
                          <p>
                            <strong>{criterion}:</strong>{" "}
                            {data.score ? data.score : "N/A"} /{" "}
                            {data.max_score ? data.max_score : "N/A"}
                          </p>
                          <p>
                            <strong>Comments:</strong>{" "}
                            {data.comments ? data.comments : "N/A"}
                          </p>
                        </div>
                      )
                    )}
                  
                </div> */}
                {/* original code end */}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeText = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);

        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>TranScript</h5>
        <pre>{filteredResumeText}</pre>
      </div>
    </>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeRating = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);
        // console.log("text",text)
        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>Evaluation</h5>
        <pre>{candidateRating}</pre>
      </div>
    </>
  );
};

export default withAuthenticator(Showmyskill);

