// Code 1  for show interviewslot by email its showed all data not by date  that why created another code Code 2

// import React, { useState } from "react";
// import { API, graphqlOperation } from "aws-amplify";
// import { listInterviewSlots } from "../../graphql/queries";

// const CandidateInterviewSlotList = () => {
//   const [query, setQuery] = useState("");
//   const [interviewSlots, setInterviewSlots] = useState([]);

//   const handleInputChange = (event) => {
//     setQuery(event.target.value);
//   };

//   const handleSearch = async () => {
//     try {
//       let nextToken = null;
//       let interviewSlots = [];

//       do {
//         const searchInformation = await API.graphql(
//           graphqlOperation(listInterviewSlots, {
//             filter: {
//               candidateEmail: { eq: query },
//             },
//             limit: 100, // Fetch maximum 100 items at a time
//             nextToken: nextToken,
//           })
//         );

//         const { items, nextToken: newToken } =
//           searchInformation.data.listInterviewSlots;
//         interviewSlots = [...interviewSlots, ...items];
//         nextToken = newToken;
//       } while (nextToken);

//       // Update interviewSlots state with fetched data
//       setInterviewSlots(interviewSlots);
//     } catch (error) {
//       console.error("Error fetching candidate data:", error);
//     }
//   };

//   return (
//     <div className="container mx-auto">
//       <div>
//         <h1 className="text-3xl font-bold mb-4">
//           Candidate Interview Slot List
//         </h1>
//         <div className="flex mb-4">
//           <input
//             type="text"
//             value={query}
//             onChange={handleInputChange}
//             placeholder="Search..."
//             className="text-black px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring focus:border-blue-300"
//           />
//           <button
//             onClick={handleSearch}
//             className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-r-md"
//           >
//             Search
//           </button>
//         </div>
//       </div>
//       <div className="">
//         {/* Map through interviewSlots and render each slot in a table */}
//         <table className="min-w-full divide-y divide-gray-200">
//           <thead className="bg-gray-50">
//             <tr>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//               >
//                 Date
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//               >
//                 Start Time
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//               >
//                 End Time
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//               >
//                 Interview URL
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {interviewSlots.map((slot) => (
//               <tr key={slot.id} className="bg-white text-blue-800">
//                 <td className="px-6 py-4 whitespace-nowrap">{slot.date}</td>
//                 <td className="px-6 py-4 whitespace-nowrap">
//                   {slot.startTime}
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap">{slot.endTime}</td>
//                 <td className="px-6 py-4 whitespace-nowrap">
//                   <a
//                     href={slot.meetingLinkURL}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="text-blue-500 hover:underline"
//                   >
//                     {slot.meetingLinkURL}
//                   </a>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default CandidateInterviewSlotList;

//above code is original

// // testing code 1
// import React, { useState } from "react";
// import { API, graphqlOperation } from "aws-amplify";
// import { listInterviewSlots } from "../../graphql/queries";

// const CandidateInterviewSlotList = () => {
//   const [dateQuery, setDateQuery] = useState("");
//   const [interviewSlots, setInterviewSlots] = useState([]);

//   const handleDateInputChange = (event) => {
//     setDateQuery(event.target.value);
//   };

//   const handleSearch = async () => {
//     try {
//       let nextToken = null;
//       let slots = [];

//       do {
//         const searchInformation = await API.graphql(
//           graphqlOperation(listInterviewSlots, {
//             filter: {
//               date: { eq: dateQuery },
//             },
//             limit: 100, // Fetch maximum 100 items at a time
//             nextToken: nextToken,
//           })
//         );

//         const { items, nextToken: newToken } =
//           searchInformation.data.listInterviewSlots;
//         slots = [...slots, ...items];
//         nextToken = newToken;
//       } while (nextToken);

//       // Sort the slots by date in descending order
//       slots.sort((a, b) => (a.date > b.date ? -1 : 1));

//       // Update interviewSlots state with fetched and sorted data
//       setInterviewSlots(slots);
//     } catch (error) {
//       console.error("Error fetching interview slots:", error);
//     }
//   };

//   return (
//     <div className="container mx-auto">
//       <div>
//         <h1 className="text-3xl font-bold mb-4">
//           Candidate Interview Slot List
//         </h1>
//         <div className="flex mb-4">
//           <input
//             type="date"
//             value={dateQuery}
//             onChange={handleDateInputChange}
//             className="text-black px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring focus:border-blue-300"
//           />
//           <button
//             onClick={handleSearch}
//             className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-r-md"
//           >
//             Search
//           </button>
//         </div>
//       </div>
//       <div className="">
//         {/* Map through interviewSlots and render each slot in a table */}
//         <table className="min-w-full divide-y divide-gray-200">
//           <thead className="bg-gray-50">
//             <tr>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//               >
//                 Date
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//               >
//                 Start Time
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//               >
//                 End Time
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//               >
//                 Interview URL
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {interviewSlots.map((slot) => (
//               <tr key={slot.id} className="bg-white text-blue-800">
//                 <td className="px-6 py-4 whitespace-nowrap">{slot.date}</td>
//                 <td className="px-6 py-4 whitespace-nowrap">
//                   {slot.startTime}
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap">{slot.endTime}</td>
//                 <td className="px-6 py-4 whitespace-nowrap">
//                   <a
//                     href={slot.meetingLinkURL}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="text-blue-500 hover:underline"
//                   >
//                     {slot.meetingLinkURL}
//                   </a>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default CandidateInterviewSlotList;

// Code 2  for show interviewslot by Date

import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import Header from "../../components/header/Header";
import {
  listInterviewSlots,
  listCandidateProfiles,
} from "../../graphql/queries";
import { useNavigate } from "react-router-dom";
import { RiseLoader } from "react-spinners";

// import { useHistory } from 'react-router-dom';

const CandidateInterviewSlotList = () => {
  const [dateQuery, setDateQuery] = useState("");
  const [interviewSlots, setInterviewSlots] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(); // State to hold selected candidate info for modal
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleDateInputChange = (event) => {
    setDateQuery(event.target.value);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      let nextToken = null;
      let slots = [];

      do {
        const searchInformation = await API.graphql(
          graphqlOperation(listInterviewSlots, {
            filter: {
              date: { eq: dateQuery },
            },
            limit: 100,
            nextToken: nextToken,
          })
        );

        const { items, nextToken: newToken } =
          searchInformation.data.listInterviewSlots;
        slots = [...slots, ...items];
        nextToken = newToken;
      } while (nextToken);

      slots.sort((a, b) => (a.date > b.date ? -1 : 1));
      setInterviewSlots(slots);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const handleViewCandidateInfo = async (email) => {
    try {
      let nextToken = null;
      let candidateData = null;
      do {
        // Make a GraphQL query to fetch candidate info based on email
        const candidateInfoResponse = await API.graphql(
          graphqlOperation(listCandidateProfiles, {
            filter: {
              email: { eq: email },
            },
            limit: 100, // Fetch maximum 100 items at a time
            nextToken: nextToken,
          })
        );
        // Update candidateData with fetched data
        if (!candidateData) {
          candidateData =
            candidateInfoResponse.data.listCandidateProfiles.items;
        } else {
          candidateData = [
            ...candidateData,
            ...candidateInfoResponse.data.listCandidateProfiles.items,
          ];
        }
        nextToken = candidateInfoResponse.data.listCandidateProfiles.nextToken;
      } while (nextToken);
      // Set selectedCandidate state with fetched data
      setSelectedCandidate(candidateData);
      console.log("row data", candidateData);
      console.log("Selected candidate information:", selectedCandidate);
    } catch (error) {
      console.error("Error fetching candidate info:", error);
    }
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedCandidate(null); // Reset selectedCandidate state to close the modal
  };

  console.log("data from interview list", interviewSlots);

  const getEmail = (
    email,
    fullName,
    photoURL,
    workStatus,
    description,
    keySkills
  ) => {
    //original code start

    // navigate(`/CompanyCandidateViewProfile/${email}`, {
    //   state: { email, fullName, photoURL, workStatus, description, keySkills },
    // });

    // original code end


    // trial 1 code start

    // window.open(`/CompanyCandidateViewProfile/${email}`, '_blank');

    // trial 1  code end

    // trial 2 code start

    
    const url = `/AdminCandidateViewProfile`;
    const queryString = new URLSearchParams({
      email,
      fullName,
      photoURL,
      workStatus,
      description,
      keySkills,
    }).toString();

   
    window.open(`${url}?${queryString}`);

    // trial 2  code end

    
  };

  const convertTo12HourFormat = (timeString) => {
    // Parse the time string to get the hours and minutes
    const [hours, minutes] = timeString.split(":");

    // Convert hours to a number
    let parsedHours = parseInt(hours, 10);

    // Determine if it's AM or PM
    const period = parsedHours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    parsedHours = parsedHours % 12 || 12;

    // Format the time in 12-hour format
    return `${parsedHours}:${minutes} ${period}`;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col py-3">
          <Header />
          <div className="mt-6">
            <h1 className="text-3xl font-bold mb-4 text-center">
              Candidate Interview Slot List
            </h1>
            <div className="flex mb-4 justify-center">
              <input
                type="date"
                value={dateQuery}
                onChange={handleDateInputChange}
                className="text-black px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring focus:border-blue-300"
              />
              <button
                onClick={handleSearch}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-r-md"
              >
                Search
              </button>
            </div>
          </div>
          <div className="overflow-x-auto">
            {loading ? (
              <div className="flex justify-center items-center">
                <RiseLoader
                  color={"#ffffff"}
                  loading={loading}
                  css={override}
                  size={40}
                  aria-label="Loading Spinner"
                />
              </div>
            ) : (
              <>
                {interviewSlots.length === 0 ? (
                  <div className="flex justify-center items-center">
                    <p>No interview slots scheduled</p>
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Start Time
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          End Time
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Interview URL
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Candidate Info
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {interviewSlots
                        .sort((a, b) => a.startTime.localeCompare(b.startTime))
                        .map((slot) => (
                          <tr
                            key={slot.id}
                            className="bg-slate-700 text-white border-1 "
                          >
                            <td className="px-6 py-4 whitespace-nowrap">
                              {slot.date}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {slot.candidateEmail}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {convertTo12HourFormat(slot.startTime)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {convertTo12HourFormat(slot.endTime)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <a
                                href={slot.meetingLinkURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                              >
                                {slot.meetingLinkURL}
                              </a>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <button
                                onClick={() =>
                                  handleViewCandidateInfo(slot.candidateEmail)
                                }
                                className=" border-1  hover:bg-[#9900FF] text-white capitalize px-4 py-1 rounded-md"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>

          {/* Modal to display candidate info */}

          {selectedCandidate && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center text-blue-700 "
              style={{ overflowY: "auto" }}
            >
              <div className="bg-white p-6 rounded-lg">
                <h2 className="text-2xl font-semibold mb-4 text-center">
                  Candidate Info
                </h2>
                {selectedCandidate.map((candidate) => (
                  <div key={candidate.id}>
                    <p>Candidate Name: {candidate.fullName}</p>
                    <p>Candidate Email: {candidate.email}</p>
                    <p>Phone: {candidate.mobileNumber}</p>
                    <p>Apply for: {candidate.roleApplyFor}</p>
                    <p>
                      Resume:{" "}
                      <a href={candidate.resumeURL} target="_blank">
                        {candidate.resumeURL}
                      </a>
                    </p>
                    <hr className="my-4" /> {/* Separator between candidates */}
                  </div>
                ))}
                <button
                  onClick={() =>
                    getEmail(
                      selectedCandidate[0].email,
                      selectedCandidate[0].fullName,
                      selectedCandidate[0].photoURL,
                      selectedCandidate[0].workStatus,
                      selectedCandidate[0].description,
                      selectedCandidate[0].keySkills
                    )
                  }
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md mt-4"
                >
                  View Profile
                </button>
                <button
                  onClick={handleCloseModal}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md mt-4 ml-7"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateInterviewSlotList;
