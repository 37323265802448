import React, { useState, useEffect } from "react";
import butterfly from "../../assets/butterfly-1.svg";
import cartoon from "../../assets/cartoon.gif";
import { getCompanyProfile } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import PaymentComponent from "./PaymentComponent"; // Import PaymentComponent

const BuyPremium = ({ closeModal }) => {
  const [companySubscriptionEndDate, setCompanySubscriptionEndDate] =
    useState(null);
  const [isSubscriptionValid, setIsSubscriptionValid] = useState(true);
  const [pointValue, setPointValue] = useState(300);
  const pricePerPoint = 200;
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isPayment, setIsPayment] = useState(false);

  const CompanyEmailID = localStorage.getItem("CompanyEmailID");

  const isAuthorizedUser =
    // CompanyEmailID === "dattatray+ui@varahitechnologies.com";
    CompanyEmailID === "sachin@varahitechnologies.com";

  const handlePackageSelect = (packageInfo) => {
    const price =
      packageInfo.title === "TopUp"
        ? pointValue * pricePerPoint
        : packageInfo.price;
    setSelectedPackage({ ...packageInfo, price: price });
  };

  const handleRangeChange = (event) => {
    setPointValue(parseInt(event.target.value));
  };

  const handlePayment = () => {
    if (selectedPackage && !isPayment) {
      setIsPayment(true); // Set the payment state to true to render PaymentComponent
    }
  };

  const handlePaymentClose = () => {
    setIsPayment(false);
    setSelectedPackage(null);
  };

  // Use useEffect to handle the state update completion for selectedPackage
  useEffect(() => {
    if (selectedPackage && !isPayment) {
      setIsPayment(true);
    }
  }, [selectedPackage]);

  const fetchCompanyProfile = async () => {
    try {
      const storedCompanyID = localStorage.getItem("CompanyProfileID");
      const companyProfileData = await API.graphql(
        graphqlOperation(getCompanyProfile, { id: storedCompanyID })
      );

      if (companyProfileData.data.getCompanyProfile) {
        const companyProfile = companyProfileData.data.getCompanyProfile;
        const endDate = new Date(companyProfile.CompanySubscriptionEndDate);
        setCompanySubscriptionEndDate(endDate);

        // Check if current date is greater than subscription end date
        const currentDate = new Date();
        if (currentDate > endDate) {
          setIsSubscriptionValid(false);
        }
      } else {
        console.error("Company profile not found");
      }
    } catch (error) {
      console.error("Error fetching company profile:", error);
    }
  };

  useEffect(() => {
    fetchCompanyProfile();
  }, []);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="rounded-lg shadow-lg p-8"
          style={{ background: "linear-gradient(135deg, #b58aff, #d19dfc)" }}
        >
          <div className="flex justify-end mb-4">
            <button
              onClick={closeModal}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <h2 className="text-2xl font-bold mb-6 text-center text-black">
            Buy Premium
          </h2>
          <h2 className=" text-black block">
            1 screening : <span className="line-through">300 RS</span> 200 Rs
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            {[
              {
                title: "Monthly",
                JD: "Unlimited",
                screening: 30,
                points: 300,
                price: 6000,
                strikethrough: 9000,
              },
              {
                title: "Half Yearly",
                JD: "Unlimited",
                screening: 180,
                points: 1500,
                price: 36000,
                strikethrough: 54000,
              },
              {
                title: "Yearly",
                JD: "Unlimited",
                screening: 360,
                points: 3000,
                price: 72000,
                strikethrough: 108000,
              },
            ].map((packageInfo, index) => (
              <div
                key={index}
                className="bg-[#F7DBA7] border border-[#BC9B3C] rounded-lg p-4"
                style={{
                  background: "linear-gradient(-125deg, #9c6fd3cc, #7b47f1)",
                }}
              >
                <div className="pricing-table">
                  <div className="table-header first d-flex align-items-center justify-content-center text-center">
                    <h5 className="ml-3 text-center text-white mb-4">
                      {packageInfo.title}
                    </h5>
                  </div>
                  <div className="table-content text-center">
                    <div className="price-amount text-white mb-2 font-bold">
                      <span>Benefits</span>
                    </div>
                  </div>
                  <div className="table-content text-center">
                    <ul className="list-none text-white mb-4">
                      <li>
                        <span>No of JD: {packageInfo.JD}</span>
                      </li>
                      <li>
                        <span>No of screening: {packageInfo.screening}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="table-header pt-0 pb-0">
                    <div className="price-amount text-center bottom">
                      <span className="text-sm text-white mt-8 line-through block">
                        Rs {packageInfo.strikethrough}
                      </span>
                      <span className="text-2xl text-white  block">
                        Rs {packageInfo.price}
                      </span>
                    </div>
                  </div>
                  <div className="table-footer mt-6 text-center">
                    <button
                      type="button"
                      className="text-white font-regular py-2 px-4 rounded"
                      style={{
                        background:
                          "linear-gradient(128deg, rgba(17, 15, 20, 1), rgba(58, 46, 99, 1))",
                      }}
                      onClick={() => handlePackageSelect(packageInfo)}
                      disabled={!isAuthorizedUser}
                    >
                      {packageInfo.title === "TopUp" ? "TopUp" : "SUBSCRIBE"}
                    </button>

                    {packageInfo.title !== "TopUp" && (
                      <div className="mt-4">
                        <input
                          type="text"
                          placeholder="Enter Coupon Code"
                          className="border border-gray-400 rounded-lg px-4 py-2"
                        />
                        <button
                          className=" text-white font-regular py-2 px-4 rounded ml-2"
                          style={{
                            background:
                              "linear-gradient(128deg, rgba(17, 15, 20, 1), rgba(58, 46, 99, 1))",
                            marginTop: "16px",
                          }}
                          disabled={!isAuthorizedUser}
                        >
                          Validate
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {/* TopUp Package */}
            <div
              className="bg-[#F7DBA7] border border-[#BC9B3C] rounded-lg p-4"
              style={{
                background:
                  "linear-gradient(-125deg, rgba(156, 111, 211, 0.8), rgb(123, 71, 241))",
              }}
            >
              <div className="pricing-table">
                <div className="table-header first d-flex align-items-center justify-content-center text-center">
                  <h5 className="ml-3 text-center text-white mb-4">TopUp</h5>
                </div>
                <div className="table-content text-center">
                  <div className="price-amount text-white mb-2 font-bold">
                    <span>Benefits</span>
                  </div>
                </div>
                <div className="table-body mt-3 mb-3 text-center">
                  <div>
                    <input
                      type="range"
                      id="pointRange"
                      name="pointRange"
                      min="0"
                      max="50"
                      step="5"
                      value={pointValue}
                      onChange={handleRangeChange}
                      disabled={!isAuthorizedUser}
                    />
                    <div
                      style={{
                        fontSize: "24px",
                        color: "#fff",
                      }}
                    >
                      {pointValue} Points
                      <br />
                      Rs. {pointValue * pricePerPoint}
                    </div>
                  </div>
                </div>
                <div className="table-header pt-0 pb-0">
                  <div className="price-amount text-center bottom">
                    <span className="text-2xl text-white mt-8 block"></span>
                  </div>
                </div>
                <div className="table-footer mt-6 text-center">
                  <button
                    type="button"
                    className="text-white font-regular py-2 px-4 rounded"
                    style={{
                      background:
                        "linear-gradient(128deg, rgba(17, 15, 20, 1), rgba(58, 46, 99, 1))",
                    }}
                    onClick={() => {
                      if (!isAuthorizedUser) {
                        alert(
                          "You are in test mode. This action is not available."
                        );
                      } else if (!isSubscriptionValid) {
                        alert(
                          "Your validity is over. Please subscribe to a package to extend your validity."
                        );
                      } else {
                        handlePackageSelect({
                          title: "TopUp",
                          points: pointValue, // Replace with your actual points value
                        });
                      }
                    }}
                  >
                    TopUp
                  </button>

                  <div className="mt-4">
                    <input
                      type="text"
                      placeholder="Enter Offer Code"
                      className="border border-gray-400 rounded-lg px-4 py-2"
                    />
                    <button
                      className=" text-white font-regular py-2 px-4 rounded ml-2"
                      style={{
                        background:
                          "linear-gradient(128deg, rgba(17, 15, 20, 1), rgba(58, 46, 99, 1))",
                        marginTop: "16px",
                      }}
                      disabled={!isAuthorizedUser}
                    >
                      Validate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPayment && selectedPackage && (
        <PaymentComponent
          packageInfo={selectedPackage}
          onPaymentClose={handlePaymentClose}
          companySubscriptionEndDate={companySubscriptionEndDate}
        />
      )}
    </div>
  );
};

export default BuyPremium;
