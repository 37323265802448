import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const CompanyPrivateRoute = () => {


    const email = localStorage.getItem(
        "CompanyEmailID"
      );

      const hremail = localStorage.getItem(
        "candidateProfileEmailID"
      );

      console.log("10100101010101010101010101010101010101010101010101010101010",email)
      // Original code start
          //  email  ? <Outlet />:<Navigate to="/" />
      // original code ned
      // email === "getscreened@varahitechnologies.com" || email === "hr@varahitechnologies.com"  ? <Outlet />:<Navigate to="/" />
      
      return hremail === "getscreened@varahitechnologies.com" || hremail === "hr@varahitechnologies.com" || email ? <Outlet />:<Navigate to="/" />
}

export default CompanyPrivateRoute
