// File path: /src/components/CompanyDashboard.js

import React, { useState, useEffect, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listCandidateProfiles } from "../graphql/queries";
import { useNavigate } from "react-router-dom";

import "./CompanyDashboard.css";
import logo from "../assets/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import profilephoto from "../../src/assets/profile.png";

const CompanyDashboard = () => {
  const [profiles, setProfiles] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchProfiles = useCallback(async (token = null) => {
    setLoading(true);
    try {
      const { data } = await API.graphql(
        graphqlOperation(listCandidateProfiles, {
          limit: 20,
          nextToken: token,
        })
      );
      const newProfiles = data.listCandidateProfiles.items;
      setProfiles((prevProfiles) => [...prevProfiles, ...newProfiles]);
      setNextToken(data.listCandidateProfiles.nextToken);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      >= document.documentElement.offsetHeight && nextToken && !loading
    ) {
      fetchProfiles(nextToken);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const viewProfile = (profile) => {
    const { email, fullName, photoURL, workStatus, description, keySkills } = profile;
    navigate(`/CompanyCandidateViewProfile/${email}`, {
      state: { email, fullName, photoURL, workStatus, description, keySkills },
    });
  };

  return (
    <div>
      {/* Header Section */}
      <div className="d-flex justify-content-between align-items-center dashboard-header border-bottom pb-2" style={{ marginBottom: "20px" }}>
        <div>
          <img src={logo} alt="Company Logo" style={{ height: "64px" }} />
        </div>
        <div>
          <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: "1.5rem", cursor: "pointer" }} />
        </div>
      </div>
      <div className="container">
        <div className="row">
          {profiles.map((profile, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={index}>
              <div className="profile-card">
                <div className="profile-header">
                  <img
                    src={profile.photoURL || profilephoto}
                    alt={profile.fullName}
                    className="profile-image"
                  />
                  <div className="profile-info">
                    <h3>{profile.fullName}</h3>
                    <p>
                      Work Status:{" "}
                      {profile.workStatus.length > 20
                        ? profile.workStatus.substring(0, 20) + "..."
                        : profile.workStatus}
                    </p>
                    <p>
                      Apply for:{" "}
                      {profile.roleApplyFor && profile.roleApplyFor.length > 30
                        ? profile.roleApplyFor.substring(0, 30) + "..."
                        : profile.roleApplyFor}
                    </p>
                  </div>
                </div>
                <div className="profile-body">
                  <p>
                    Description:{" "}
                    {profile.description.length > 50
                      ? profile.description.substring(0, 50) + "..."
                      : profile.description}
                  </p>
                  <div className="skills">
                    {profile.keySkills
                      .split(",")
                      .slice(0, 5)
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}
                  </div>
                  <button
                    onClick={() => viewProfile(profile)}
                    className="view-profile-btn"
                  >
                    View Profile
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {loading && <p>Loading more profiles...</p>}
      </div>
    </div>
  );
};

export default CompanyDashboard;
