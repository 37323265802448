import React, { useEffect, useState } from "react";
import "./Spot.css"
import { API, Storage, graphqlOperation } from "aws-amplify";
import { createSpotJD } from "../../graphql/mutations";
import { listSpotJDS } from "../../graphql/queries";
// import profile from "C:/Users/Admin/Documents/First/ReactGetScreened/src/assets/profile.png";
// from feat1
import profile from "../../assets/profile.png"

const Spot = () => {
  const [isChatboxOpen, setIsChatboxOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [activeTab, setActiveTab] = useState("JD");
  const [activeCandidateTab, setActiveCandidateTab] = useState("AI Picked");
  const [showTable, setShowTable] = useState(false);
  const [targetTable, setTargetTable] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("candidates"); // Initial screen
  const [nextArray, setNextArray] = useState(["candidates"]);
  const [index, setIndex] = useState(1);
  const names = [
    {
      id: 1,
      name: "John Doe",
      email: "john@example.com",
      phone: "123-456-7890",
      position: "Developer",
    },
    { name: "Bob Smith", id: "2" },
    { name: "Carol Williams", id: "3" },
    { name: "David Brown", id: "4" },
    { name: "Eve Davis", id: "5" },
    { name: "Frank Miller", id: "6" },
  ];
  // const names = [
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     email: "john@example.com",
  //     phone: "123-456-7890",
  //     position: "Developer",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     email: "jane@example.com",
  //     phone: "987-654-3210",
  //     position: "Designer",
  //   },
  // ];

  // Spot code start
  const [jdFile, setJdFile] = useState(null);
  const [localCompanyEmail, setLocalCompanyEmail] = useState("")
  const [spotJDSItems, setSpotJDSItems] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [spotJD, setSpotJD] = useState({})
  const [serverEmails, setServerEmails] = useState([])

  const array = [...Array(6)].map((_, index) => names[index]);

  const handleChatboxToggle = () => {
    setIsChatboxOpen((prevState) => !prevState);
  };

  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleCloseProfile = () => {
    setSelectedCandidate(null);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCandidateTabClick = (tab) => {
    setActiveCandidateTab(tab);
  };

  const handleNextClick = () => {
    setShowTable(true);
    setCurrentScreen(`candidates${index}`);
    // testing start
    if (activeTab === "JD") {
      setActiveTab("Resume")
    }
    // testing end
    console.log("sssssss:", currentScreen);
    setNextArray((prevArray) => [...prevArray, `candidates${index}`]);
    setIndex((prevIndex) => prevIndex + 1);
  };
  const TargetClick = () => {
    setTargetTable(!targetTable);
    // setCurrentScreen(`candidates${index}`);
    setCurrentScreen(!targetTable ? `candidates${index}` : "candidates");
    console.log("sssssss:", currentScreen);
    // setNextArray((prevArray) => [...prevArray, `candidates${index}`]);
    // setIndex((prevIndex) => prevIndex + 1);
  };

  const handleBackClick = () => {
    console.log("this page:r", nextArray);
    console.log("this page:999999999999999999", nextArray.length);
    setNextArray((prevArray) => prevArray.slice(0, -1));

    // testing start
    if (activeTab === "Resume") {
      setActiveTab("JD")
    }
    // testing end

    if (currentScreen === "showTable") {
      setShowTable(false);
      setCurrentScreen("candidates");
    } else {
      setShowTable(false);
      setCurrentScreen("candidates");
      // Handle other back navigation logic if necessary
    }
    if (nextArray.length == 1) {
      setIndex(1);
    }
    console.log("showTable", showTable);
  };
  const getNavButtonStyle = (currentScreen) => ({
    padding: "10px 20px",
    // background:
    //   "linear-gradient(135deg, rgb(102 126 234 / 52%), rgb(136 89 184 / 52%))",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "4px",
    background:
      currentScreen === "candidates"
        ? "linear-gradient(135deg, rgb(102 126 234 / 52%), rgb(136 89 184 / 10%))"
        : "linear-gradient(135deg, rgb(102 126 234 / 52%), rgb(136 89 184 / 100%))", // Gradient for other screens
  });
  console.log("ssssssS:", nextArray);
  console.log("currentScreen:", currentScreen);
  const breadcrumbnav = (currentScreen) => ({
    background: nextArray.includes(currentScreen)
      ? "linear-gradient(135deg, #667eea, #8859b8)"
      : "",

    fontWeight: "bold",
    color: "#ffffff",
    padding: "5px 10px",
    borderRadius: "4px",
    margin: "4px",
  });


  //   Spot Code start

  useEffect(() => {
    const storedEmail = localStorage.getItem('CompanyEmailID');
    if (storedEmail) {
      setLocalCompanyEmail(storedEmail);
    }


    const fetchSpotJDS = async () => {
      let spotJDSItemsList = [];
      let currentNextToken = nextToken; // Initialize with state token

      do {
        const response = await API.graphql(
          graphqlOperation(listSpotJDS, {
            filter: {
              companyEmail: { eq: storedEmail }, // Filter by email
            },
            limit: 100, // Adjust the limit as needed
            nextToken: currentNextToken, // Use the current token
          })
        );

        spotJDSItemsList = [
          ...spotJDSItemsList,
          ...response.data.listSpotJDS.items,
        ];

        currentNextToken = response.data.listSpotJDS.nextToken;
      } while (currentNextToken); // Continue until no more tokens

      setSpotJDSItems(spotJDSItemsList);
      setNextToken(currentNextToken); // Update the state with the last token
      console.log("**************************", spotJDSItemsList)
      if (spotJDSItemsList.length > 0) {
        setSpotJD(spotJDSItemsList[0])
      } else {
        console.log('No data available.');
      }
    };

    fetchSpotJDS();


  }, [])

  const handleJdChange = (e) => {
    // Update state with the selected file
    setJdFile(e.target.files[0]);
  };

  const handleJdUpload = async (e) => {
    e.preventDefault()
    if (!jdFile) return; // Check if file is selected
    // console.log("------------------",videoFile.name)
    // console.log("******************",videoFile)
    console.log("------------------", jdFile.name)
    console.log("******************", jdFile)
    const yourS3BucketName = "getscreenedstoragebucketf24ed-devget";
    const folderPath = 'SpotJD/';
    try {
      //   let videoURL = "33333333333333333333333333333333";
      let companyEmail = localCompanyEmail

      let videoURL = null;
      if (jdFile) {
        const videoKey = await Storage.put(
          // original code start
          // videoFile.name,
          // original code end
          `${folderPath}${jdFile.name}`, // Adjusted to include folder path
          jdFile,
          {
            contentType: "video/*",
          }
        );
        // original code start
        // videoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;
        // original code end
        videoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;
      }
      //   console.log("hhhhhhhhhhhhhhhhhhhhhh",candidateId)
      //   console.log("oooooooooooooooooooooo",interviewEvaluations.videoURL)
      const input = {
        jdURL: videoURL, // or whatever the URL is
        companyEmail: companyEmail
      };
      const response = await API.graphql(graphqlOperation(createSpotJD, {
        input
      }));
      console.log(response)

      // window.location.reload();

      const { data: { createSpotJD: { id } } } = response;

      console.log("kkkkkkkkkkkkkkk", id)


      // Py Send Data

      const data = {
        id: id,
      };


      try {
        // Make the POST request
        const res = await fetch('https://a747-20-70-140-142.ngrok-free.app/extract-job-details', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });


        // Check if the request was successful
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }

        // Parse the JSON response
        const result = await res.json();
        console.log("kkkkkkkkkkkkkkkkkk", result)
        window.location.reload();
        //   setResponse(result);
      } catch (error) {
        console.error('Error:', error);
      }


    } catch (error) {
    }
  };

  const handleSelectJD = async (id, companyEmail) => {
    try {

      const data = {
        id: id,
      };

      try {
        // Make the POST request
        const res = await fetch('https://a747-20-70-140-142.ngrok-free.app/shortlist-candidates', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });


        // Check if the request was successful
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }

        // Parse the JSON response
        const result = await res.json();
        console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeee", result.detailed_profiles)
        setServerEmails(result.detailed_profiles)
        // window.location.reload();
        //   setResponse(result);
      } catch (error) {
        console.error('Error:', error);
      }



    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }




  console.log(";;;;;;;;;;;;;;;;;;;;", serverEmails)

  return (
    <div style={styles.body}>
      {/* Header Section */}
      <div style={styles.header}>
        <div style={styles.username}> 👨‍🦰 Username</div>
        <div style={styles.steps}>
          <span style={styles.SpanSteps} className="spanSteps">
            📇{" "}
            <span style={styles.spot} className="spot">
              S
            </span>
            pecify{" "}
          </span>{" "}
          &gt;{" "}
          <span style={breadcrumbnav("candidates1")} className="spanSteps">
            📑
            <span style={styles.spot2} className="spot2">
              P
            </span>
            repare{" "}
          </span>{" "}
          &gt;{" "}
          <span style={breadcrumbnav("candidates2")} className="spanSteps">
            🔎
            <span style={styles.spot2} className="spot2">
              O
            </span>
            utreach{" "}
          </span>{" "}
          &gt;{" "}
          <span className="spanSteps">
            🎯
            <span style={styles.spot2} className="spot2">
              T
            </span>
            arget
          </span>
          <span style={styles.Target} onClick={TargetClick}>
            {targetTable ? "❌ Close" : "🎯 Targeted Candidate"}
          </span>
        </div>
      </div>

      <div style={styles.container}>
        {/* Main Content */}
        <div style={styles.mainContent} className="mainContent">
          {/* JD Section */}
          <div style={styles.jdSection} className="jdSection">
            {activeTab === "JD" && (
              <>
                <div style={styles.jdUpload}>
                  {/* <button style={styles.uploadButton}>📂 Upload JD</button> */}
                  <input type="file" accept=".pdf" onChange={handleJdChange} />
                  <button onClick={handleJdUpload} style={styles.uploadButton}>Upload PDF</button>
                </div>
                {/* original code start */}
                {/* <div style={styles.jdList}>
                  {spotJDSItems.map((value,index) => (
                    <div style={styles.jdCard} key={index}>
                      <div style={styles.jdContent}>
                        <div>{value.jdName}</div>
                        <input
                          type="radio"
                          name="select-jd"
                          style={styles.radio}
                        />
                      </div>
                      <div style={styles.jdContent}>
                        <span style={styles.jdDiscription}>
                          {value.summary}
                        </span>
                      </div>
                      <div style={styles.jdSkillContent}>
                        <span style={styles.jdSkill}>HTML</span>
                        <span style={styles.jdSkill}>CSS</span>
                        <span style={styles.jdSkill}>JS</span>
                      </div>
                      <div style={styles.iconContainer}>
                        <button style={styles.iconButton}>✖️</button>
                        <button style={styles.iconButton}>🔗</button>
                      </div>

                  <button
                    onClick={() => handleSelectJD(value.id, value.companyEmail)}
                  >
                    Select
                  </button>
                      
                    </div>
                  ))}
                </div> */}
                {/* original code end */}

                <div style={styles.jdList} className="text-black">
                  {spotJDSItems.map((value, index) => {
                    // Inline JSON parsing and mapping
                    let skillsArray = [];
                    let languagesArray = [];

                    try {
                      skillsArray = JSON.parse(value.skills);
                      console.log()
                    } catch (error) {
                      console.error("Error parsing skills JSON:", error);
                    }

                    try {
                      languagesArray = JSON.parse(value.languages);
                    } catch (error) {
                      console.error("Error parsing languages JSON:", error);
                    }

                    return (
                      <div style={styles.jdCard} key={index}>
                        <div style={styles.jdContent}>
                          <div>{value.jdName}</div>
                          <input
                            type="radio"
                            name="select-jd"
                            style={styles.radio}
                          />
                        </div>
                        <div style={styles.jdContent}>
                          <span style={styles.jdDiscription}>
                            {value.summary}
                          </span>
                        </div>
                        <div style={styles.jdSkillContent}>
                          {skillsArray.length > 0 ? (
                            skillsArray.map((skill, skillIndex) => (
                              <span key={skillIndex} style={styles.jdSkill}>{skill}</span>
                            ))
                          ) : (
                            <span>No skills available</span>
                          )}
                        </div>
                        <div style={styles.jdSkillContent}>
                          {languagesArray.length > 0 ? (
                            languagesArray.map((language, langIndex) => (
                              <span key={langIndex} style={styles.jdSkill}>{language}</span>
                            ))
                          ) : (
                            <span>No languages available</span>
                          )}
                        </div>
                        <div style={styles.iconContainer}>
                          <button style={styles.iconButton}>✖️</button>
                          <button style={styles.iconButton}>🔗</button>
                        </div>
                        <button
                          onClick={() => handleSelectJD(value.id, value.companyEmail)}
                          className="p-2 rounded-lg"
                        >
                          Select
                        </button>
                      </div>
                    );
                  })}
                </div>

              </>
            )}

            {activeTab === "Resume" && (
              <>
                <div style={styles.jdUpload}>
                  <button style={styles.uploadButton}>Upload Resume ➕</button>
                </div>
                <div style={styles.jdList}>
                  {[...Array(6)].map((_, index) => (
                    <div style={styles.jdCard} key={index}>
                      <div style={styles.jdContent}>
                        <div>Resume 504 {index + 1}</div>
                        <input
                          type="radio"
                          name="select-resume"
                          style={styles.radio}
                        />
                      </div>
                      <div style={styles.iconContainer}>
                        <button style={styles.iconButton}>✖️</button>
                        <button style={styles.iconButton}>🔗</button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {/* <button style={styles.addButton} onClick={handleChatboxToggle}>
            🧜‍♂️Ask Ai
          </button> */}
          </div>

          {/* Conditional Rendering for Candidate Section or Profile View */}
          {selectedCandidate ? (
            <div style={styles.profileView}>
              <button style={styles.closeButton} onClick={handleCloseProfile}>
                ✖️
              </button>
              <div style={styles.profileImage}>👤</div>
              <div style={styles.profileName}>{selectedCandidate.name}</div>
              <div style={styles.profileAbout}>
                About* {selectedCandidate.name}
              </div>
            </div>
          ) : (
            <div style={styles.candidateSection}>
              <div style={styles.tabs}>
                <button
                  style={
                    activeCandidateTab === "AI Picked"
                      ? styles.activeTab
                      : styles.tab
                  }
                  onClick={() => handleCandidateTabClick("AI Picked")}
                >
                  AI Picked
                </button>
                <button
                  style={
                    activeCandidateTab === "Your Candidate"
                      ? styles.activeTab
                      : styles.tab
                  }
                  onClick={() => handleCandidateTabClick("Your Candidate")}
                >
                  Your Candidate
                </button>
                <button
                  style={
                    activeCandidateTab === "Database"
                      ? styles.activeTab
                      : styles.tab
                  }
                  onClick={() => handleCandidateTabClick("Database")}
                >
                  Database
                </button>
              </div>
              {/* Conditional Rendering for Candidate Section or Table View */}
              {showTable ? (
                <div style={styles.tableContainer}>
                  <table style={styles.table}>
                    <thead>
                      <tr>
                        <th style={styles.tableHeader}>Name</th>
                        <th style={styles.tableHeader}>Email</th>
                        <th style={styles.tableHeader}>Profile Image</th>
                        <th style={styles.tableHeader}>Phone Number</th>
                        <th style={styles.tableHeader}>Position</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...Array(6)].map((_, index) => (
                        <tr
                          key={index}
                          style={styles.tableRow}
                          onClick={() =>
                            handleCandidateClick({
                              profileImage: `https://via.placeholder.com/50`,
                              name: `Name ${index + 1}`,
                              email: `name${index + 1}@example.com`,
                              phone: `123-456-789${index}`,
                              position: `Position ${index + 1}`,
                            })
                          }
                        >
                          <td style={styles.tableCell}>
                            <img
                              src="https://via.placeholder.com/50"
                              alt="Profile"
                              style={styles.profileImageTable}
                            />
                          </td>
                          <td style={styles.tableCell}>Name 607 {index + 1}</td>
                          <td style={styles.tableCell}>
                            name{index + 1}@example.com
                          </td>
                          <td style={styles.tableCell}>123-456-789{index}</td>
                          <td style={styles.tableCell}>Position {index + 1}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : currentScreen === "candidates" ? (
                <div style={styles.candidates} className="candidates">
                  {serverEmails.map((arr, index) => (
                    <div
                      className="text-black"
                      style={styles.candidateCard}
                      key={index}
                      onClick={(e) => {
                        // Prevent triggering the profile view if the radio button is clicked
                        if (e.target.tagName !== "INPUT") {
                          handleCandidateClick({ name: `Name ${index + 1}` });
                        }
                      }}
                    >
                      {/* <div style={styles.candidateImage}>{arr.photoURL}</div> */}
                      <div style={styles.candidateImage}>
                        <img
                          src={arr.photoURL || profile } // Use default image if photoURL is null or undefined
                          alt={arr.name || profile}
                          style={{ width: '100px', height: '100px' }} // Adjust dimensions as needed
                          className="rounded-full"
                        />
                      </div>
                      <div style={styles.candidateName}>
                        Name 633 :{arr.name} {index + 1}
                      </div>
                      <div style={styles.iconContainer}>
                        <button style={styles.iconButton}>🔗</button>
                        <input
                          type="checkbox"
                          name={`select-resume-${index}`}
                          style={styles.checkbox}
                          onClick={(e) => e.stopPropagation()} // Prevents checkbox from triggering parent onClick
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : targetTable ? (
                <div style={styles.candidates} className="candidates">
                  {array.map((arr, index) => (
                    <div
                      style={styles.candidateCard2}
                      key={index}
                      onClick={(e) => {
                        // Prevent triggering the profile view if the radio button is clicked
                        if (e.target.tagName !== "INPUT") {
                          handleCandidateClick({ name: `Name ${index + 1}` });
                        }
                      }}
                    >
                      <div style={styles.candidateImage}>👤</div>
                      <div style={styles.candidateName}>
                        {arr.name} {index + 1}
                      </div>
                      <div style={styles.candidateName}>
                        <h1 style={styles.candidateJob}> Job Title</h1>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              {currentScreen === "profile" && selectedCandidate && (
                <div>
                  <h2>Profile of {selectedCandidate.name}</h2>
                  <p>Details about {selectedCandidate.name}...</p>
                </div>
              )}
              <div style={styles.navigation}>
                <button style={styles.navButton}>More</button>
                <div style={styles.navigationsub}>
                  <button
                    style={getNavButtonStyle(currentScreen)}
                    onClick={handleBackClick}
                  >
                    Back
                  </button>
                  {/* <button style={styles.navButton}  onClick={handleNextClick}>
                    Next
                  </button> */}
                  <button style={styles.navButton} onClick={handleNextClick}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Chatbox */}
        {isChatboxOpen && (
          <div style={styles.chatbox}>
            <div style={styles.chatboxHeader}>
              <span>Chat with AI</span>
              <button style={styles.chatboxClose} onClick={handleChatboxToggle}>
                ✖️
              </button>
            </div>
            <div style={styles.chatboxContent}>
              <p>Hi! How can I assist you today?</p>
            </div>
            <div style={styles.chatboxInputContainer}>
              <input
                type="text"
                placeholder="Enter your prompt..."
                style={styles.chatboxInput}
              />
              <button style={styles.searchButton}>Search</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
// Internal CSS styles as JavaScript objects

const styles = {
  body: {
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    // height: "97vh",
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px",
  },
  container: {
    maxWidth: "100%",
    margin: "43px 20px",
    // padding: "20px",
    backgroundColor: "#ffffff",
    // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    fontFamily: "Arial, sans-serif",
    position: "relative",
    // background: "linear-gradient(135deg, #667eea, #8859b8)",
    background: "transparent",
    borderRadius: "8px",
    // height: "73vh",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    background: "linear-gradient(168deg, #272c58, #41245e)",
    padding: " 20px",
    borderRadius: "4px",
  },
  username: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#ffffff",
  },
  steps: {
    fontSize: "18px",
    color: "#ffffff",
  },
  SpanSteps: {
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    fontWeight: "bold",
    color: "#ffffff",
    padding: "5px 10px",
    borderRadius: "4px",
    margin: "4px",
    // fontSize: "12px",
  },
  mainContent: {
    display: "flex",
    gap: "20px",
  },
  jdSection: {
    // width: "25%",
    background: "#ddc8ffd6",
    padding: "13px",
    borderRadius: "12px",
  },
  jdUpload: {
    marginBottom: "10px",
  },
  uploadButton: {
    width: "100%",
    padding: "15px 10px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "20px",
  },
  Target: {
    width: "100%",
    padding: "8px 4px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "20px",
    margin: "1rem",
    marginLeft: "2rem",
  },
  jdList: {
    // display: "flex",
    flexWrap: "wrap",

    // height: "63vh",
    height: "60vh",
    overflowY: "auto", // Enable vertical scrolling
    scrollbarWidth: "none",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  jdCard: {
    width: "fit-content", // Adjusted for 2 JD boxes in a row
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
    gap: "0rem",
    // display: "grid",
    margin: "4px",

    alignItems: "flex-end",
  },
  jdContent: {
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  jdDiscription: {
    textAlign: "justify",
    fontSize: "12px",
  },
  jdSkillContent: {
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "flex-start",
    fontSize: "12px",
  },
  jdSkill: {
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
  },
  radio: {
    marginTop: "5px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    background: "#ddc8ffd6",

    borderRadius: "6px",
    height: "2rem",
    top: " 2rem",

    position: "sticky",
  },
  iconButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "18px",
  },
  addButton: {
    width: "100%",
    padding: "10px",
    border: "none",
    borderRadius: "4px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    cursor: "pointer",
    marginTop: "1rem",
  },
  candidateSection: {
    // width: "74%",
    background: "#ccbbfd",
    padding: "19px",
    borderRadius: "10px",
    width: "-webkit-fill-available",
  },
  tabs: {
    display: "flex",
    gap: "10px",
    marginBottom: "10px",
  },
  tab: {
    flex: 1,
    padding: "10px",
    backgroundColor: "#e1e1e1",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  activeTab: {
    flex: 1,
    padding: "10px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  candidates: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
  },
  candidateCard: {
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
    cursor: "pointer",
    transition: "transform 0.2s",
  },
  candidateCard2: {
    padding: "15px",
    // border: "1px solid #ddd",
    borderRadius: "4px",
    background:
      "linear-gradient(135deg, rgb(102, 126, 234), rgb(136, 89, 184))",

    color: "#fff",
    textAlign: "center",
    cursor: "pointer",
    transition: "transform 0.2s",
  },
  candidateCardHover: {
    transform: "scale(1.05)",
  },
  candidateImage: {
    fontSize: "40px",
    marginBottom: "10px",
  },
  candidateName: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  navigationsub: {
    display: "flex",
    justifyContent: "space-between",
  },
  navButton: {
    padding: "10px 20px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  // navButton2: {
  //   padding: "10px 20px",
  //   // backgroundColor: "#007bff",
  //   background:
  //     "linear-gradient(135deg, rgb(102 126 234 / 52%), rgb(136 89 184 / 52%))",
  //   color: "white",
  //   border: "none",
  //   borderRadius: "4px",
  //   cursor: "pointer",
  //   marginRight: "4px",
  //   backgroundColor: currentScreen === "candidates" ? "gray" : "#007bff", // Change background color based on screen
  // },
  profileView: {
    width: "68%",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
  profileImage: {
    fontSize: "50px",
    marginBottom: "10px",
  },
  profileName: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  profileAbout: {
    fontSize: "16px",
    color: "#666",
  },
  chatbox: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "300px",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    overflow: "hidden",
  },
  chatboxHeader: {
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chatboxClose: {
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    fontSize: "18px",
    cursor: "pointer",
  },
  chatboxContent: {
    padding: "10px",
  },
  chatboxInputContainer: {
    display: "flex",
    padding: "10px",
    borderTop: "1px solid #ddd",
  },
  chatboxInput: {
    flex: 1,
    padding: "8px",
    border: "1px solid #ddd",
    borderRadius: "4px",
  },
  searchButton: {
    padding: "8px 10px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  jdToggle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  jdToggleButton: {
    flex: 1,
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    cursor: "pointer",
    transition: "background-color 0.2s",
    marginRight: "10px",
    textAlign: "center",
  },
  activeToggleButton: {
    flex: 1,
    padding: "10px",
    // backgroundColor: "#007bff",
    background: "linear-gradient(135deg, #667eea, #8859b8)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    textAlign: "center",
    marginRight: "10px",
  },
  tableContainer: {
    width: "100%",
    overflowY: "auto", // Enable vertical scrolling
    scrollbarWidth: "none",
    height: "55vh",
    borderRadius: "8px",
    border: "1px solid rgba(221, 200, 255, 0.84)",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "#ffffff",
  },
  tableHeader: {
    background: "linear-gradient(168deg, #272c58, #41245e)",
    color: "#ffffff",
    padding: "10px",
    position: "sticky",
  },
  tableCell: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "10px",
  },
  profileImageTable: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  spot: {
    // background: "#34295d",
    width: "5%",
    /* display: contents; */
    // padding: "2px 8px",
    borderRadius: "3px",
    fontSize: "20px",
    // color: "#34295d",
    color: "#bc97ff",
  },
  spot2: {
    // background: "linear-gradient(135deg, #667eea, #8859b8)",
    width: "5%",
    /* display: contents; */
    // padding: "2px 8px",
    borderRadius: "3px",
    // margin: "2px",
    color: "#bc97ff",
    fontSize: "20px",
  },
  /* Styles for circular checkboxes */
  checkbox: {
    // appearance: "none",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "2px solid #007BFF",
    outline: "none",
    cursor: "pointer",
    position: "relative",
    marginRight: "10px",
  },
  checkboxChecked: {
    backgroundColor: "#007BFF",
    border: "2px solid #007BFF",
  },
  checkboxCheckedAfter: {
    content: '""',
    width: "10px",
    height: "10px",
    backgroundColor: "white",
    borderRadius: "50%",
    position: "absolute",
    top: "3px",
    left: "3px",
  },
  candidateJob: {
    fontSize: "16px",
  },
};

export default Spot;
