import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { API, graphqlOperation, Amplify, Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import { listCandidateProfiles } from "./graphql/queries";
import { RiseLoader } from "react-spinners";
// Import your components here

import InterviewSlotBook from "./pages/admin/InterviewSlotBook.js";

import CandidateDashboard from "./pages/CandidateDashboard.js";
import CandidateProfileReg from "./pages/CandidateProfileReg";
import CandidateSchedule from "./pages/CandidateSchedule";
import CandidateResumeDetails from "./pages/CandidateResumeDetails.js";
import TermsPolicy from "./pages/TermsPolicy";
import CandidateProfile from "./pages/CandidateProfile.js";
import EditCandidateProfileReg from "./pages/EditCandidateProfileReg .js";
import AdminAddcontext from "./pages/AdminAddcontext.js";
import StudentDashbord from "./pages/StudentDashbord";
import WhoAreYou from "./pages/WhoAreYou";
import CompanyDashboard from "./pages/CompanyDashboard";
import CompanyPage from "./pages/CompanyPage.js";
import logo from "./assets/logogetscreened.svg";
import CandidateInterviewSlotList from "./pages/admin/CandidateInterviewSlotList.js";
import Header1 from "./components/Header1/Header1.js";
import CompanyCandidateViewProfile from "./pages/Company/CompanyCandidateViewProfile.js";
import CompanyRegistarFrom from "./pages/Company/CompanyRegistarFrom.js";
import CompanyTerms from "./pages/Company/CompanyTerms.js";
import CompanyHome from "./pages/Company/CompanyHome.js";
import SendMail from "./pages/Company/SendMail.js";
import CompanyProfile from "./pages/Company/CompanyProfile.js";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute.js";
import { listCompanyProfiles } from "./graphql/queries";
import CompanyJdform from "./components/company/CompanyJdform.js";
import BuyPremium from "./components/company/BuyPremium.js";
import PaymentComponent from "./components/company/PaymentComponent.js";
import Compensationbenefits from "./pages/Compensationbenefits.js";
import JobDetailsList from "./pages/Company/JobDetailsList .js";
import JobPostDetails from "./pages/Company/JobPostDetails.js";
import JobDetails from "./components/company/JobDetails.js";
import AdminCandidateViewProfile from "./pages/admin/AdminCandidateViewProfile.js";
import Showmyskill from "./pages/Showmyskill.js";
import ShowMySkills from "./pages/share/ShowMySkills.js";
import ProfilePage from "./pages/ProfilePage.js";
import AdminPrivateRoute from "./pages/admin/AdminPrivateRoute.js";
import CompanyPrivateRoute from "./pages/Company/CompanyPrivateRoute.js";
import CompanyGlobal from "./pages/Company/CompanyGlobal.js";
import UpdateCandidate from "./pages/admin/UpdateCandidate.js";
import Spot from "./pages/Company/Spot.js";
import SpotSearch from "./pages/Company/SpotSearch.js";
import SpotGPT from "./pages/Company/SpotGPT.js";
Amplify.configure(awsExports);

const HeaderWithLogo = () => (
  <div
    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
  >
    <img src={logo} alt="Logo" style={{ width: "450px" }} />
    {/* <h3 style={{ textAlign: "center" }}>Welcome to GetScreened App</h3> */}
    {/* <p style={{ textAlign: "center" }}>Powered by Varahi Technologies</p> */}
  </div>
);

function Footer() {
  return (
    <div
      style={{ textAlign: "center", marginTop: "20px", marginBottom: "10px" }}
    >
      <p>Powered by Varahi Technologies</p>
    </div>
  );
}
function App() {
  const [userProfileExists, setUserProfileExists] = useState(null);
  const [companyUserExists, setCompanyUserExists] = useState(null);
  //private route code start here

  const [email, setEmail] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({});

  //private route code end here

  useEffect(() => {
    checkUserProfile();
  }, []);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  //Private route code start here

  useEffect(() => {
    const fetchData = async () => {
      try {
        const candidateProfileEmailID = localStorage.getItem(
          "candidateProfileEmailID"
        );
        setEmail(candidateProfileEmailID);
        console.log("Email id", candidateProfileEmailID);

        if (!candidateProfileEmailID) {
          // Handle case where candidate email is not found
          return;
        }

        const { data } = await API.graphql(
          graphqlOperation(listCompanyProfiles, {
            filter: {
              email: { eq: candidateProfileEmailID },
            },
            limit: 100, // Limit the number of items per page, adjust as needed
            nextToken: nextToken, // Pass nextToken if available
          })
        );
        console.log("compay data from app", data);
        setCompany(data.listCompanyProfiles.items[0]);
        setCompanyUserExists(true);

        if (data && data.listCompanyProfiles.items) {
          const { items, nextToken } = data.listCompanyProfiles;
          setCompanyList((prevList) => [...prevList, ...items]); // Merge new items with existing list
          setNextToken(nextToken); // Set the nextToken for pagination
          setLoading(false); // Set loading to false after data is fetched
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setCompanyUserExists(false);
        // Handle error
      }
    };

    setTimeout(() => {
      fetchData();
    }, 2000);
  }, [nextToken]); // Fetch data again when nextToken changes

  //private code

  async function checkUserProfile() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();
      const userId = userInfo.attributes.sub;
      const userEmail = userInfo.attributes.email.toLowerCase();
      localStorage.setItem("candidateProfileEmailID", userEmail);
      localStorage.setItem("candidateProfileAuthID", userId);

      let nextToken = null;
      let userProfile = null;

      do {
        const userProfileData = await API.graphql(
          graphqlOperation(listCandidateProfiles, {
            filter: {
              // original getscreened code start
              // userID: {
              //   eq: userId,
              // }
              // original getscreened code end
              email: {
                eq: userEmail,
              },
            },
            limit: 100, // Adjust the limit as per your requirement
            nextToken: nextToken,
          })
        );

        const items = userProfileData.data.listCandidateProfiles.items;
        if (items.length > 0) {
          userProfile = items[0];
          localStorage.setItem("candidateProfileId", userProfile.id);
          localStorage.setItem("candidateProfile", JSON.stringify(userProfile));
          setUserProfileExists(true);
          break; // Exit the loop since a profile is found
        } else {
          // Continue to next iteration if there are more records to fetch
          nextToken = userProfileData.data.listCandidateProfiles.nextToken;
        }
      } while (nextToken);

      // If no profile found after exhausting all pages
      if (!userProfile) {
        console.log("No profile found for this user ID.");
        setUserProfileExists(false);
      }
    } catch (error) {
      console.error("Error fetching user profile", error);
      setUserProfileExists(false);
    }
  }
  console.log("11111111111111111111111111111111111111", userProfileExists);
  console.log("222222222222222222222222222222222222", companyUserExists);
  if (userProfileExists === null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <RiseLoader
          color={"#ffffff"}
          loading={!userProfileExists}
          css={override}
          size={40}
          aria-label="Loading Spinner"
        />
      </div>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              // userProfileExists ? <CandidateDashboard /> : <WhoAreYou />
              // userProfileExists ? <SendMail /> : <WhoAreYou />
              // userProfileExists && companyUserExists ? <PrivateRoute/> : <WhoAreYou />
              company || userProfileExists ? <PrivateRoute /> : <WhoAreYou />
              // userProfileExists ? <WhoAreYou /> : <TermsPolicy />
              // userProfileExists ? <CandidateResumeDetails  /> : <TermsPolicy />
            }
          />
          <Route
            path="/CandidateProfileReg"
            element={<CandidateProfileReg />}
          />
          <Route path="/TermsPolicy" element={<TermsPolicy />} />

          <Route path="/CompanyPage" element={<CompanyPage />} />
          <Route path="/CandidateSchedule" element={<CandidateSchedule />} />
          <Route path="/CandidateProfile" element={<CandidateProfile />} />
          <Route path="/StudentDashboard" element={<StudentDashbord />} />

          {/* <Route path="/InterviewSlotBook" element={<InterviewSlotBook />} /> */}

          <Route path="/PaymentComponent" element={<PaymentComponent />} />
          <Route path="/CompanyJdform" element={<CompanyJdform />} />

          <Route path="/JobPostDetails" element={<JobPostDetails />} />

          <Route path="/CompanyProfile" element={<CompanyProfile />} />
          <Route path="/Showmyskill" element={<Showmyskill />} />
          <Route path="/ShowMySkills" element={<ShowMySkills />} />
          <Route path="/ProfilePage" element={<ProfilePage />} />

          {/* Admin Private Route start */}

          <Route element={<AdminPrivateRoute />}>

            <Route path="/AdminAddcontext" element={<AdminAddcontext />} />
            <Route path="/InterviewSlotBook" element={<InterviewSlotBook />} />
            <Route path="/CandidateInterviewSlotList" element={<CandidateInterviewSlotList />} />
            <Route path="/CompanyDashboard" element={<CompanyDashboard />} />
            <Route path="/AdminCandidateViewProfile" element={<AdminCandidateViewProfile />}/>
            <Route path="/UpdateCandidate" element={<UpdateCandidate/>}/>

          </Route>
              
          {/* Admin Private Route end */}

          {/* Company Private Route start */}

          <Route element={<CompanyPrivateRoute />}>

            <Route path="/JobDetailsList" element={<JobDetailsList />} />
            <Route path="/JobDetails" element={<JobDetails />} />
            <Route path="/SendMail" element={<SendMail />} />
            <Route path="/CompanyCandidateViewProfile/:email" element={<CompanyCandidateViewProfileWrapper />}/>
            <Route path="/Spot" element={<Spot/>} />
            <Route path="/SpotSearch" element={<SpotSearch/>}/>
            <Route path="/SpotGPT" element={<SpotGPT/>}/>

          </Route>
          {/* Company Private Route end */}
          
          <Route path="/CompanyGlobal" element={<CompanyGlobal/>}/>

          <Route
            path="/Compensationbenefits"
            element={<Compensationbenefits />}
          />
          {/* commented for im not getting props alter net got below from ooriginal  */}
          {/* <Route path="/CompanyCandidateViewProfile" element={<CompanyCandidateViewProfile />} /> */}
          
          <Route
            path="/CompanyRegisterForm"
            element={<CompanyRegistarFrom />}
          />
          <Route path="/CompanyTerms" element={<CompanyTerms />} />
          <Route path="/CompanyHome" element={<CompanyHome />} />
          <Route path="/PrivateRoute" element={<PrivateRoute />} />
          <Route path="/BuyPremium" element={<BuyPremium />} />
          

          {/* <Route path="/Header" element={<Header1 />} /> */}

          <Route
            path="/CandidateResumeDetails"
            element={<CandidateResumeDetails />}
          />
          <Route
            path="/EditCandidateProfileReg"
            element={<EditCandidateProfileReg />}
          />
          {/* Redirect all other paths to dashboard or terms based on the profile existence */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

const CompanyCandidateViewProfileWrapper = () => {
  const { email } = useParams();
  const location = useLocation();
  return <CompanyCandidateViewProfile email={email} {...location.state} />;
};

export default withAuthenticator(App, {
  components: { Header: HeaderWithLogo, Footer: Footer },
});
