import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation, Auth,Storage } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  listAdminAddCandidateContexts,
  listCandidateProfiles,
  listInterviewSlots,
  listInterviewEvaluations,
} from "../graphql/queries";
import { RiseLoader } from "react-spinners";
import logo from "../assets/logo.svg";
// import ProfileModal from "./ProfileModal";
import Header from "../components/header/Header";
import { updateCandidateProfile } from "../graphql/mutations";
// Modal component

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

// upload resume code start

const Modal = ({ isOpen, onClose, onUpload, handleFileChange,resumeUploadLoading }) => {
  if (!isOpen) return null;

  return (
    // <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
    //   <div className="bg-white p-6 rounded-lg shadow-lg w-80">
    //     <h2 className="text-xl font-semibold mb-4">Upload Your Resume</h2>
    //     <form onSubmit={onUpload}>
    //       <input
    //         type="file"
    //         accept=".pdf"
    //         className="block w-full mb-4 border border-gray-300 p-2 rounded"
    //         required
    //       />
    //       <button
    //         type="submit"
    //         className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
    //       >
    //         Upload
    //       </button>
    //     </form>
    //     <button
    //       onClick={onClose}
    //       className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
    //     >
    //       Close
    //     </button>
    //   </div>
    // </div>

    // <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex items-center justify-center z-50">
    //   <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full mx-4">
    //     <h2 className="text-2xl font-bold mb-4 text-gray-800">System Upgrade Notice</h2>
    //     <p className="text-gray-700 mb-6">
    //       We have recently upgraded our system. Please kindly upload your resume to
    //       ensure your information is up-to-date.
    //     </p>
    //     <form onSubmit={onUpload} className="space-y-4">
    //       <input
    //         type="file"
    //         accept=".pdf"
    //         className="block w-full border border-gray-300 rounded-lg p-3 text-gray-800 focus:border-blue-500 focus:outline-none"
    //         required
    //       />
    //       <button
    //         type="submit"
    //         className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
    //       >
    //         Upload
    //       </button>
    //     </form>
    //     <button
    //       onClick={onClose}
    //       className="mt-6 w-full bg-red-600 text-white py-2 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
    //     >
    //       Close
    //     </button>
    //   </div>
    // </div>

    // <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex items-center justify-center z-50 "style={{ width: 'max-content', position: 'absolute' }} >
    //   <div className="bg-white p-8 rounded-lg shadow-lg w-96  mx-4 box-border">
    //     <h2 className="text-2xl font-bold mb-4 text-gray-800">System Upgrade Notice</h2>
    //     <p className="text-gray-700 mb-6">
    //       We have recently upgraded our system. Please kindly upload your resume to
    //       ensure your information is up-to-date.
    //     </p>
    //     <form onSubmit={onUpload} className="space-y-4">
    //       <input
    //         type="file"
    //         accept=".pdf"
    //         className="block w-full border border-gray-300 rounded-lg p-3 text-gray-800 focus:border-blue-500 focus:outline-none"
    //         required
    //       />
    //       <button
    //         type="submit"
    //         className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
    //       >
    //         Upload
    //       </button>
    //     </form>
    //     <button
    //       onClick={onClose}
    //       className="mt-6 w-full bg-red-600 text-white py-2 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
    //     >
    //       Close
    //     </button>
    //   </div>
    // </div>

    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-70 flex items-center justify-center "
      style={{ width: '100vw', height: '100vh',width: 'max-content', position: 'absolute'  }} // Ensure the overlay covers the entire viewport
    >
      <div
        className="bg-white p-8 rounded-lg shadow-lg mx-4 box-border"
        style={{ 
          width: '90%', // Default width for smaller screens
          maxWidth: '600px', // Max width to avoid too large modals on bigger screens
          position: 'relative', // Use relative positioning for the inner container
          left: '-11rem',  // Adjust position from the left
          top: '-6rem', 
        }}
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">System Upgrade Notice</h2>
        <p className="text-gray-700 mb-6">
          We have recently upgraded our system. Please kindly upload your resume to
          ensure your information is up-to-date.
        </p>
        <form onSubmit={onUpload} className="space-y-4">
          <input
            type="file"
            accept=".pdf"
            className="block w-full border border-gray-300 rounded-lg p-3 text-gray-800 focus:border-blue-500 focus:outline-none"
            required
            onChange={handleFileChange}
          />
          <button
            type="submit"
            disabled={resumeUploadLoading}
            className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {resumeUploadLoading ? 'Uploading...' : 'Upload'}
          </button>
        </form>
        {/* <button
          onClick={onClose}
          className="mt-6 w-full bg-red-600 text-white py-2 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Close
        </button> */}
      </div>
    </div>

  );
};
   
// upload resume code end

function CandidateDashboard({ signOut }) {
  const [isLoading, setIsLoading] = useState(true);
  const rootElementRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const [hasScheduledInterview, setHasScheduledInterview] = useState(false);
  const [hasCompletedProfile, setHasCompletedProfile] = useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [showEmail, setShowEmail] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateProfile, setCandidateProfile] = useState(null);
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const scheduled = location.state ? location.state.scheduled : false;

  const [candiadteResume, setCandiadteResume] = useState("")
  const [isStagging, setIsStagging] = useState(false);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [candidateID,setCandidateID]=useState("")
  const [resumeUploadLoading,setResumeUploadLoading]=useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Check if user is authenticated
        const userInfo = await Auth.currentAuthenticatedUser();
        const userId = userInfo.attributes.sub;
        const userEmail = userInfo.attributes.email.toLowerCase();

        // Fetch user profile with pagination
        let nextToken = null;
        let userProfileList = [];
        do {
          const userProfileResponse = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              // original code start
              // filter: { userID: { eq: userId } },
              //  original code end
              filter: { email: { eq: userEmail } },
              limit: 100,
              nextToken: nextToken,
            })
          );
          userProfileList.push(
            ...userProfileResponse.data.listCandidateProfiles.items
          );
          nextToken = userProfileResponse.data.listCandidateProfiles.nextToken;
        } while (nextToken);

        // Set user profile details
        if (userProfileList.length > 0) {
          setUserFullName(userProfileList[0].fullName);
          setHasCompletedProfile(userProfileList[0].fullName.trim() !== "");
          setCandiadteResume(userProfileList[0].resumeURL)
          setCandidateID(userProfileList[0].id)
          // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz", userProfileList[0])
          if (userProfileList[0].resumeURL.includes('staging')) {
            setIsStagging(true);
          } else {
            setIsStagging(false);
          }
        } else {
          console.log("User profile not found.");
          setHasCompletedProfile(false);
        }

        // Fetch candidate profile and interview evaluations
        const candidateEmail = localStorage.getItem("candidateProfileEmailID");
        if (candidateEmail) {
          // Fetch candidate contexts with pagination
          nextToken = null;
          let adminContexts = [];
          do {
            const response = await API.graphql(
              graphqlOperation(listAdminAddCandidateContexts, {
                limit: 100,
                nextToken: nextToken,
              })
            );
            adminContexts.push(
              ...response.data.listAdminAddCandidateContexts.items
            );
            nextToken = response.data.listAdminAddCandidateContexts.nextToken;
          } while (nextToken);

          const foundProfile = adminContexts.some(
            (profile) => profile.candidateEmail === candidateEmail
          );
          setCandidateProfile(foundProfile);
          // Fetch interview evaluations with pagination
          nextToken = null;
          let evaluationsItems = [];
          do {
            const response = await API.graphql(
              graphqlOperation(listInterviewEvaluations, {
                filter: { candidateEmail: { eq: candidateEmail } },
                limit: 100,
                nextToken: nextToken,
              })
            );
            evaluationsItems.push(
              ...response.data.listInterviewEvaluations.items
            );
            nextToken = response.data.listInterviewEvaluations.nextToken;
          } while (nextToken);

          setInterviewEvaluations(evaluationsItems.length > 0);
        }

        // Check for scheduled interviews with pagination
        if (candidateEmail) {
          nextToken = null;
          let interviewSlots = [];
          do {
            const interviewSlotsResponse = await API.graphql(
              graphqlOperation(listInterviewSlots, {
                filter: { candidateEmail: { eq: candidateEmail } },
                limit: 100,
                nextToken: nextToken,
              })
            );
            interviewSlots.push(
              ...interviewSlotsResponse.data.listInterviewSlots.items
            );
            nextToken =
              interviewSlotsResponse.data.listInterviewSlots.nextToken;
          } while (nextToken);

          setHasScheduledInterview(interviewSlots.length > 0);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    setIsMounted(true);
    fetchData();
  }, []);

  // resume upload code start
  useEffect(() => {
    if (isStagging) {
      setIsResumeModalOpen(true); // Automatically open the modal if not in staging
    }
  }, [isStagging]);

  const handleOpenModal = () => setIsResumeModalOpen(true);
  const handleCloseModal = () => setIsResumeModalOpen(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    // Handle file upload logic here
    // console.log('**************************** File uploaded **********************',selectedFile);
    setResumeUploadLoading(true)
    const yourS3BucketName = "getscreenedstoragebucketf24ed-devget"; // Adjust with your S3 Bucket name
    let valuesresumeURL = null;

    try {
   
    if (selectedFile) {
      try {
        const { key: resumeKey } = await Storage.put(
          `${Date.now()}_${selectedFile.name}`,
          selectedFile,
          { contentType: "application/pdf" } // or candidateProfile.resume.type for more specific types
        );
        valuesresumeURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${resumeKey}`;
      } catch (error) {
        console.error("Error uploading resume:", error);
        return;
      }
    }

    
    const input = {
      id: candidateID,
      resumeURL: valuesresumeURL, // or whatever the URL is
    };

    const response = await API.graphql(graphqlOperation(updateCandidateProfile, { 

      input

     }));

     console.log(response)


    handleCloseModal();
  } catch (error) {
    setResumeUploadLoading(false)
  }


  };

  //  resume upload code end

  // console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq", candidateProfile)

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col py-3">
          {/* Header */}
          <Header />
          {/* Centered content */}
          {isLoading ? ( // Conditional rendering for loader
            <div className="flex justify-center items-center h-screen">
              <RiseLoader
                color={"#ffffff"}
                loading={isLoading}
                css={override}
                size={40}
                aria-label="Loading Spinner"
              />
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center py-3">
              <div className="row position-relative">
                <div className="col-md-12 mb-4 position-relative d-flex">
                  <div
                    className="p-3 border rounded animate__animated animate__fadeInRight d-flex flex-column justify-content-between align-items-center"
                    style={{
                      height: "500px",
                      width: "100%",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {hasCompletedProfile ? (
                      <>
                        {hasScheduledInterview ||
                          candidateProfile ||
                          interviewEvaluations ? (
                          <>
                            {candidateProfile || interviewEvaluations ? (
                              <div
                                className="text-center mt-5"
                                style={{ position: "relative", zIndex: "1" }}
                              >
                                <h2 style={{ fontStyle: "italic" }}>
                                  View Your Profile
                                </h2>
                                <p style={{ fontStyle: "italic" }}>
                                  You can view your profile details below.
                                </p>
                                <button
                                  style={{
                                    border: "2px solid #9900FF",
                                    backgroundColor: "#9900ff4c",
                                    borderRadius: "8px",
                                    padding: "12px",
                                    color: "#FFFFFF",
                                  }}
                                  onClick={() => {
                                    // Navigate to the profile view page
                                    window.location.href =
                                      "/CandidateResumeDetails";
                                  }}
                                >
                                  View Profile
                                </button>
                                <div>
                                  {/* {!isStagging ? (
                                    <p>The URL contains the word "dev".</p>
                                  ) : (
                                    <div>
                                      <p>The URL does not contain the word "dev".</p>
                                      <button
                                        // onClick={handleOpenModal}
                                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                                      >
                                        Add Resume
                                      </button>
                                    </div>
                                  )} */}
                                </div>
                                {/* resume upload code start */}
                                <Modal
                                  isOpen={isResumeModalOpen}
                                  onClose={handleCloseModal}
                                  onUpload={handleUpload}
                                  handleFileChange={handleFileChange}
                                  resumeUploadLoading={resumeUploadLoading}
                                />
                                {/* resume upload code end */}
                              </div>
                            ) : (
                              <div style={{ paddingTop: "15vh", zIndex: "1" }}>
                                <h2 style={{ fontStyle: "italic" }}>
                                  Congratulations!
                                </h2>
                                <p style={{ fontStyle: "italic" }}>
                                  Your interview slot is booked. Scheduled
                                  interview details have been sent to your
                                  email. Please kindly check your email and join
                                  on time.
                                </p>
                                <p style={{ fontStyle: "italic" }}>
                                  We are currently working on some other
                                  features, which will be available shortly.
                                </p>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="container">
                            <div className="row justify-content-center mt-5">
                              <div className="col-md-8">
                                <div className="text-center">
                                  <h2 style={{ fontStyle: "italic" }}>
                                    Schedule Your Interview Slot
                                  </h2>
                                  <p style={{ fontStyle: "italic" }}>
                                    You haven't scheduled your interview slot
                                    yet. Click below to schedule your interview.
                                  </p>
                                  <button
                                    style={{
                                      position: "relative",
                                      zIndex: "1",
                                      border: "2px solid #9900FF",
                                      backgroundColor: "#9900ff4c",
                                      borderRadius: "8px",
                                      padding: "12px",
                                      color: "#FFFFFF",
                                    }}
                                    className="btn btn-primary"
                                    onClick={() =>
                                    (window.location.href =
                                      "/candidateSchedule")
                                    }
                                  >
                                    Schedule Interview
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="container" style={{ zIndex: 1 }}>
                        <div className="row justify-content-center mt-5">
                          <div className="col-md-10">
                            <div className="text-center">
                              <h2 style={{ fontStyle: "italic" }}>
                                Complete Your Profile
                              </h2>
                              <p style={{ fontStyle: "italic" }}>
                                First, complete your profile to proceed further.
                              </p>
                              <button
                                className="btn btn-primary"
                                style={{
                                  border: "2px solid #9900FF",
                                  backgroundColor: "#9900ff4c",
                                  borderRadius: "8px",
                                  padding: "12px",
                                  color: "#FFFFFF",
                                  zIndex: "1",
                                }}
                                onClick={() =>
                                (window.location.href =
                                  "/candidateProfileReg")
                                }
                              >
                                Complete Profile
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="diagonal-split"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(CandidateDashboard);
