import React, { useState, useEffect } from "react";
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";
import Header from "../../components/header/Header";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { createPrivateScreening } from "../../graphql/mutations";
import { listPrivateScreenings } from "../../graphql/queries";
import { listCompanyProfiles } from "../../graphql/queries";
import {
  updateCompanyProfile,
  deletePrivateScreening,
} from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { listCandidateProfiles } from "../../graphql/queries";
import { useLocation } from "react-router-dom";

//animation premium
// import { useSpring, animated } from "react-spring";

const SendMail = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [privateScreenings, setPrivateScreenings] = useState([]);
  const [deletPrivateScreenings, setDeletPrivateScreenings] = useState([]);
  const location = useLocation();
  const { jobId } = location.state || {};
  console.log("jobId", jobId);

  // company state

  const [companyList, setCompanyList] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({});
  const [companyemail, setCompanyemail] = useState("");
  const [point, setPoint] = useState(0);
  const [reming, setReming] = useState({
    remainingCredit: point,
  });
  const [companyID, setCompanyID] = useState("");

  // premium show

  const [showModels, setShowModels] = useState(false);

  const [profiles, setProfiles] = useState([]);
  const navigate = useNavigate();
  const [userFullName, setUserFullName] = useState();
  const [hasCompletedProfile, setHasCompletedProfile] = useState(false);
  const [fullName, setFullName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [workStatus, setWorkStatus] = useState("");
  const [description, setDescription] = useState("");
  const [keySkills, setKeySkills] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [githubURL, setGithubURL] = useState("");
  const [linkedinURL, LinkedinURL] = useState("");

  const [wait, setWait] = useState(false);
  const [waitingEmail, setWaitingEmail] = useState(null);
  // const storedCompanyID = localStorage.getItem("CompanyProfileID");
  const storedCompanyID = localStorage.getItem("candidateProfileEmailID");
  const requiredEmail = "hr@varahitechnologies.com";

  // useEffect(() => {

  //   const fetchData = async () => {
  //     try {
  //       const candidateProfileEmailID = localStorage.getItem('candidateProfileEmailID');
  //       setCompanyemail(candidateProfileEmailID);
  //       console.log("Email id from send mail", candidateProfileEmailID)

  //       if (!candidateProfileEmailID) {
  //         // Handle case where candidate email is not found
  //         return;
  //       }

  //       const { data } = await API.graphql(
  //         graphqlOperation(listCompanyProfiles, {
  //           filter: {
  //             email: { eq: candidateProfileEmailID },
  //           },
  //           limit: 100, // Limit the number of items per page, adjust as needed
  //           nextToken: nextToken // Pass nextToken if available
  //         })
  //       );
  //       console.log("data from send mail", data)
  //       setCompany(data.listCompanyProfiles.items[0])
  //       setPoint(company.remainingCredit)
  //       console.log("direct data from ", data.listCompanyProfiles.items[0])
  //       console.log("*************from send mail company from*************", company)

  //       if (data && data.listCompanyProfiles.items) {
  //         const { items, nextToken } = data.listCompanyProfiles;
  //         setCompanyList(prevList => [...prevList, ...items]); // Merge new items with existing list
  //         setNextToken(nextToken); // Set the nextToken for pagination
  //         setLoading(false); // Set loading to false after data is fetched
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       // Handle error
  //     }
  //   };

  //   fetchData();

  // }, [nextToken])

  useEffect(() => {
    const storedCompanyEmail = localStorage.getItem("CompanyEmailID");
    const storedCompanyID = localStorage.getItem("CompanyProfileID");

    setCompany(storedCompanyEmail);
    setCompanyID(storedCompanyID);

    const fetchData = async () => {
      try {
        const candidateProfileEmailID = localStorage.getItem(
          "candidateProfileEmailID"
        );
        setCompanyemail(candidateProfileEmailID);

        if (!candidateProfileEmailID) {
          // Handle case where candidate email is not found
          return;
        }

        const { data } = await API.graphql(
          graphqlOperation(listCompanyProfiles, {
            filter: {
              email: { eq: candidateProfileEmailID },
            },
            limit: 100, // Limit the number of items per page, adjust as needed
            nextToken: nextToken, // Pass nextToken if available
          })
        );

        if (
          data &&
          data.listCompanyProfiles.items &&
          data.listCompanyProfiles.items.length > 0
        ) {
          const companyData = data.listCompanyProfiles.items[0];
          setCompany(companyData);
          setPoint(companyData.remainingCredit);

          // Save company email and ID to local storage

          // Note: company and companyID won't have the updated values immediately after setCompany/setCompanyID

          const { items, nextToken: newNextToken } = data.listCompanyProfiles;
          setCompanyList((prevList) => [...prevList, ...items]);
          setNextToken(newNextToken);
          setLoading(false);
        } else {
          setLoading(false); // Set loading to false if no data is found
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error
        setLoading(false); // Set loading to false on error
      }
    };

    fetchData();
  }, [nextToken]);

  console.log("*********company**********", company);
  console.log("*********company**********", company);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create an SES client
      const sesClient = new SESClient({
        region: "ap-south-1",
        credentials: {
          // accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          // secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
        },
      });
      console.log(`email`, email);
      // Define the email parameters
      const emailParams = {
        Source: "getscreened@varahitechnologies.com",
        Destination: {
          ToAddresses: [email], // Update with the verified email address
        },
        Message: {
          Subject: {
            Data: `Congratulations! You've Been Selected for ${company.companyName}`,
          },
          Body: {
            Text: {
              Data: `Dear ${name},

              Greetings of the Day!
              I am delighted to inform you that you have been selected for further consideration at our Company ${company.companyName}. Your application has impressed us, and we believe your skills and experience are a great fit for our team.
              As the next step in our hiring process, we would like to invite you for a private screening interview for the position of ${message}. To streamline the process, we have set up an online portal for the interview. You can access the interview portal using the following link: https://www.getscreened.in. We have also provided a YouTube video for your reference: https://www.youtube.com/watch?si=r94pgS7GIIy-p6j-&v=3rMe3qx9Amc&feature=youtu.be.
              Please select a suitable time slot from the available options in the portal. If none of the provided times work for you, please let us know, and we will do our best to accommodate your schedule.
              During the interview, we will discuss the role of ${message} in more detail, delve into your qualifications, and explore how your expertise aligns with our company's goals and values.
              After the interview, if you have any questions or require further information, please feel free to reach out to us. Additionally, once the interview is completed, our team will follow up with you to provide feedback and discuss the next steps in the hiring process.
              Congratulations once again on being selected! We look forward to meeting with you and exploring the possibility of you joining our team.
              Best regards,
              ${company.contactPersonName}
              ${company.contactPersonDesignation}
              ${company.contactPersonEmail}
              ${company.contactPersonNumber}
              ${company.email}`,
            },
          },
        },
      };

      // Send the email using the SendEmailCommand
      const sendEmailCommand = new SendEmailCommand(emailParams);
      await sesClient.send(sendEmailCommand);

      console.log("Email sent successfully");

      // Reset the form after successful submission
      setName("");
      setEmail("");
      setMessage("");

      // updating the point

      const updateCompanyPoint = async () => {
        try {
          // Declare variables
          let candidateProfileEmailID, data, companyData, items, newNextToken;

          // Fetch candidate profile email ID from local storage
          candidateProfileEmailID = localStorage.getItem(
            "candidateProfileEmailID"
          );
          setCompanyemail(candidateProfileEmailID);

          // Check if candidate email is available
          if (!candidateProfileEmailID) {
            // Handle case where candidate email is not found
            return;
          }

          // setPoint(point-30)

          let currentRemainingCredit = point;
          let updatedRemainingCredit = currentRemainingCredit - 30;

          setPoint(updatedRemainingCredit);

          const updateInput = {
            id: company.id, // Assuming you have the company ID available
            remainingCredit: updatedRemainingCredit,
          };

          // Fetch company profiles data
          const result = await API.graphql(
            graphqlOperation(updateCompanyProfile, {
              input: updateInput,
            })
          );

          console.log(
            "Remaining credit updated successfully:",
            updatedRemainingCredit
          );

          console.log(
            "*************point after deduct*****************",
            point
          );
          console.log(
            "*************point after deduct*****************",
            reming
          );
          // Destructure data from result
          data = result.data;

          // Check if data is available
          if (
            data &&
            data.listCompanyProfiles.items &&
            data.listCompanyProfiles.items.length > 0
          ) {
            // Extract company data
            companyData = data.listCompanyProfiles.items[0];
            setCompany(companyData);
            setPoint(companyData.remainingCredit);

            // Save company email and ID to local storage

            // Note: company and companyID won't have the updated values immediately after setCompany/setCompanyID

            // Extract items and newNextToken
            items = data.listCompanyProfiles.items;
            newNextToken = data.listCompanyProfiles.nextToken;

            // Update company list and next token
            setCompanyList((prevList) => [...prevList, ...items]);
            setNextToken(newNextToken);
            setLoading(false);
          } else {
            setLoading(false); // Set loading to false if no data is found
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error
          setLoading(false); // Set loading to false on error
        }
      };

      updateCompanyPoint();
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  // Function to create a new PrivateScreening object
  const createPrivateScreeningInvitation = async () => {
    const privatescreeningDetails = {
      companyProfileID: companyID,
      jobDescription: message,
      invitedCandidates: [{ email: email, name: name }],
      // Add any other required fields
    };

    try {
      const response = await API.graphql(
        graphqlOperation(createPrivateScreening, {
          input: privatescreeningDetails,
        })
      );
      console.log(
        "Private screening created:",
        response.data.createPrivateScreening
      );
      // Handle the successful response as needed
    } catch (error) {
      console.error("Error creating private screening:", error);
      // Handle the error as needed
    }
  };

  // Function to fetch PrivateScreening data and filter by companyProfileID
  const fetchPrivateScreenings = async (companyProfileID, jobId) => {
    try {
      let nextToken = null;
      const allScreenings = [];

      // Determine the filter based on presence of jobId
      const filter = jobId
        ? { jdID: { eq: jobId } }
        : { companyProfileID: { eq: companyProfileID } };

      // Fetch data with pagination
      do {
        const response = await API.graphql(
          graphqlOperation(listPrivateScreenings, {
            filter: filter,
            limit: 100, // Adjust the limit as needed
            nextToken: nextToken,
          })
        );
        const { items, nextToken: newToken } =
          response.data.listPrivateScreenings;
        allScreenings.push(...items);
        nextToken = newToken;
      } while (nextToken);

      // Assuming setPrivateScreenings is a function to update state or handle data
      setPrivateScreenings(allScreenings);
      console.log("privateScreenings:", allScreenings);
    } catch (error) {
      console.error("Error fetching private screenings:", error);
    }
  };

  useEffect(() => {
    const companyProfileID = localStorage.getItem("CompanyProfileID");
    fetchPrivateScreenings(companyProfileID, jobId);
  }, [deletPrivateScreenings, jobId]);

  const handleDeletePrivateCandidate = async (
    id,
    candidateName,
    candidateEmail
  ) => {
    // Confirm deletion with user, showing candidate's name and email
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the private screening for ${candidateName} (${candidateEmail})?`
    );

    if (!confirmDelete) {
      return; // If user cancels deletion, exit function
    }

    try {
      const input = { id };
      await API.graphql(graphqlOperation(deletePrivateScreening, { input }));

      // Update state after deletion
      const updatedScreenings = privateScreenings.filter(
        (screening) => screening.id !== id
      );
      setPrivateScreenings(updatedScreenings);

      console.log(`Successfully deleted private screening with ID: ${id}`);
    } catch (error) {
      console.error("Error deleting private screening:", error);
    }
  };

  // premium show function

  const handlePremiumClick = () => {
    setShowModels(true);
  };

  // const getEmail = async (email) =>{
  //   console.log("/////////////////////getemail////////////////////",email)

  //   let nextToken = null;
  //   let userProfileList = [];
  //   do {
  //     const userProfileResponse = await API.graphql(
  //       graphqlOperation(listCandidateProfiles, {
  //         filter: { email: { eq: email } },
  //         limit: 100,
  //         nextToken: nextToken,
  //       })
  //     );
  //     console.log("=============================",userProfileResponse)

  //     userProfileList.push(
  //       ...userProfileResponse.data.listCandidateProfiles.items
  //     );
  //     nextToken = userProfileResponse.data.listCandidateProfiles.nextToken;
  //   } while (nextToken);

  //   // Set user profile details
  //   if (userProfileList.length > 0) {
  //     setUserFullName(userProfileList[0].fullName);
  //     // setHasCompletedProfile(userProfileList[0].fullName.trim() !== "");
  //   } else {
  //     console.log("User profile not found.");
  //     // setHasCompletedProfile(false);
  //   }

  // }

  const getEmail = async (email) => {
    try {
      if (email) {
        console.log("/////////////////////getemail////////////////////", email);
        setWait(true);
        setWaitingEmail(email);

        let nextToken = null;
        let userProfileList = [];
        do {
          const userProfileResponse = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              filter: { email: { eq: email } },
              limit: 100,
              nextToken: nextToken,
            })
          );
          console.log("=============================", userProfileResponse);

          userProfileList.push(
            ...userProfileResponse.data.listCandidateProfiles.items
          );
          nextToken = userProfileResponse.data.listCandidateProfiles.nextToken;
        } while (nextToken);

        console.log(
          "###################################################",
          userProfileList[0]
        );
        setUserFullName(userProfileList[0]);
        console.log("#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$##$#$#$#", userFullName);
        // Set user profile details

        if (userProfileList.length > 0) {
          setUserFullName(userProfileList[0]);
          setHasCompletedProfile(userProfileList[0].fullName.trim() !== "");

          const {
            fullName,
            photoURL,
            workStatus,
            description,
            keySkills,
            mobileNumber,
            githubURL,
            linkedinURL,
          } = userProfileList[0];

          navigate(`/CompanyCandidateViewProfile/${email}`, {
            state: {
              email,
              fullName,
              photoURL,
              workStatus,
              description,
              keySkills,
              mobileNumber,
              githubURL,
              linkedinURL,
            },
          });
          // testing code end
        } else {
          console.log("User profile not found.");
          // setHasCompletedProfile(false);
          alert("Candidate Not yet Registered");
          // setWait(false)
          // setWaitingEmail(null)
        }

        setWait(false);
        setWaitingEmail(null);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      // Handle error appropriately, e.g., throw error or return empty array

      setWait(false);
      setWaitingEmail(null);
    }
  };

  const handleClick = async (email) => {
    //  await getEmail(email); // Call getEmail function with the provided email
    if (!wait) {
      getEmail(email);
    }
  };

  // console.log("***********point*************", point);
  console.log("*******private scrning***********", privateScreenings);
  // console.log("***************company ID****************", companyID);

  // console.log("***********point*************", point);
  // console.log("*******private scrning***********", privateScreenings);
  // console.log("***************userfullname****************", userFullName.email);

  return (
    <div>
      {/* <Header /> */}
      {/* <div className="max-w-lg mx-auto">
        <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-10 border-2 border-[#9900FF]">
          <h1 className="text-center">Private screening</h1>
          {point > 0 ? (
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-white text-sm font-bold mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-transparent"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-white text-sm font-bold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-whitetext-white leading-tight focus:outline-none focus:shadow-outline bg-transparent"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-white text-sm font-bold mb-2"
                >
                  Job Description
                </label>
                <textarea
                  id="message"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-transparent"
                  rows="5"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>

              <div className="text-center">
                <button
                  onClick={createPrivateScreeningInvitation}
                  className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Create Private Screening
                </button>
              </div>
            </form>
          ) : (
            <div className="text-center">
              <h6 className="text-gray-700 font-bold">You Run out all point & You want to continue</h6>
              <button
                className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handlePremiumClick}
              >
                Go for premium...!
              </button>

              {/* Show models with popup animation when Premium button is clicked */}
      {/* {showModels && <ModelPopups />} */}
      {/* </div> */}
      {/* )} */}
      {/* </div> */}
      {/* </div> */}

      {/* <table className="w-full mt-8">
        <thead>
          <tr>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Job Description</th>
          </tr>
        </thead>
        <tbody>
          {privateScreenings.map((data, index) => (
            <tr key={index}>
              <td>{data.candidateName}</td>
              <td>{data.candidateEmail}</td>
              <td>{data.jobDescription}</td>
              <td><button onClick={() => handleClick(data.candidateEmail)}>View Profile</button></td>
            </tr>
          ))}
        </tbody>
      </table> */}

      <div className="overflow-x-auto">
        <Header />
        {/* <table className="w-full mt-8 table-auto  ">
          <thead>
            <tr>
              <th className="px-4 py-2 sm:px-6 sm:py-3">Name</th>
              <th className="px-4 py-2 sm:px-6 sm:py-3">Email</th>
              <th className="px-4 py-2 sm:px-6 sm:py-3">Job Description</th>
              <th className="px-4 py-2 sm:px-6 sm:py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {privateScreenings.map((data, index) => (
              <tr key={index} className=" shadow-md rounded-lg mb-4">
                <td className="px-4 py-2 sm:px-6 sm:py-3">{data.candidateName}</td>
                <td className="px-4 py-2 sm:px-6 sm:py-3">{data.candidateEmail}</td>
                <td className="px-4 py-2 sm:px-6 sm:py-3">{data.jobDescription}</td>
                <td className="px-4 py-2 sm:px-6 sm:py-3">
              
                  
                  <button onClick={() => handleClick(data.candidateEmail)} disabled={waitingEmail === data.candidateEmail} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-[150px] sm:py-3 sm:px-6 rounded">
                  {waitingEmail === data.candidateEmail ? 'Processing...' : 'View Profile'}
                  </button>
                  
                </td>
              </tr>
            ))}
          </tbody>
        </table> */}

        {/* <table className="w-full mt-8 table-auto ">
  <thead className="">
    <tr className="w-full text-center">
      <th className="px-4 py-2 sm:px-6 sm:py-3">Name</th>
      <th className="px-4 py-2 sm:px-6 sm:py-3">Email</th>
      <th className="px-4 py-2 sm:px-6 sm:py-3">Job Description</th>
      <th className="px-4 py-2 sm:px-6 sm:py-3">Actions</th>
    </tr>
  </thead>
  <tbody className=" justify-center">
    {privateScreenings.map((data, index) => (
      <tr key={index} className="shadow-md rounded-lg mb-4 text-center">
        <td className="px-4 py-2 sm:px-6 sm:py-3">{data.candidateName}</td>
        <td className="px-4 py-2 sm:px-6 sm:py-3">{data.candidateEmail}</td>
        <td className="px-4 py-2 sm:px-6 sm:py-3">{data.jobDescription}</td>
        <td className="px-4 py-2 sm:px-6 sm:py-3">
          <button onClick={() => handleClick(data.candidateEmail)} disabled={waitingEmail === data.candidateEmail} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded">
            {waitingEmail === data.candidateEmail ? 'Processing...' : 'View Profile'}
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table> */}
        <h1 className="mt-8 text-4xl font-medium text-center">
          Private Candidate List
        </h1>
        <table className="w-full mt-8 table-auto">
          <thead>
            <tr className="w-full text-center">
              <th className="px-4 py-2 sm:px-6 sm:py-3">Name</th>
              <th className="px-4 py-2 sm:px-6 sm:py-3">Email</th>
              <th className="px-4 py-2 sm:px-6 sm:py-3">Job Description</th>
              <th className="px-4 py-2 sm:px-6 sm:py-3">Actions</th>
            </tr>
          </thead>
          <tbody className="justify-center">
            {privateScreenings.map((data, index) => (
              <tr key={index} className="shadow-md rounded-lg mb-4 text-center">
                <td className="px-4 py-2 sm:px-6 sm:py-3">
                  {data.candidateName}
                </td>
                <td className="px-4 py-2 sm:px-6 sm:py-3">
                  {data.candidateEmail}
                </td>
                <td className="px-4 py-2 sm:px-6 sm:py-3">
                  {data.jobDescription}
                </td>
                <td className="px-4 py-2 sm:px-6 sm:py-3">
                  <button
                    onClick={() => handleClick(data.candidateEmail)}
                    disabled={waitingEmail === data.candidateEmail}
                    className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded mr-2"
                  >
                    {waitingEmail === data.candidateEmail
                      ? "Processing..."
                      : "View Profile"}
                  </button>
                  {storedCompanyID === requiredEmail && (
  <button
    onClick={() =>
      handleDeletePrivateCandidate(
        data.id,
        data.candidateName,
        data.candidateEmail
      )
    }
    className="border-1 hover:bg-[#FF0000] text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded"
  >
    Delete
  </button>
)}

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ModelPopups = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 bg-gray-700">
      <ModelPoint value={1000} delay={0} />
      <ModelPoint value={7000} delay={150} />
      <ModelPoint value={15000} delay={300} />
    </div>
  );
};

// ModelPoint component to display points with popup animation
const ModelPoint = ({ value, delay }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Set visibility after a delay to trigger the animation
  setTimeout(() => setIsVisible(true), delay);

  return (
    <div
      className={`bg-gray-100 p-4 rounded-md shadow-md ${
        isVisible ? "scale-in" : "invisible"
      }`}
    >
      <h2 className="text-lg font-bold">Points: {value}</h2>
      {/* Additional styling for the model */}
    </div>
  );
};

export default SendMail;
