import React, { useEffect, useState } from 'react'
import { listInterviewEvaluations } from '../../graphql/queries';
import { API, Storage, graphqlOperation } from "aws-amplify";
import { updateInterviewEvaluation } from '../../graphql/mutations';
import Header from '../../components/header/Header';
import { Navigate, useNavigate } from 'react-router-dom';

const UpdateCandidate = () => {

  const [email, setEmail] = useState('');
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [videoURL, setVideoURL] = useState('');
  const [uploading, setUploading] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [candidateId,setCandidateId] = useState("")
  const navigate = useNavigate()


const fetchInterviewEvaluations = async () => {
  let interviewEvaluationsItems = [];
  let currentNextToken = null; // Use a separate variable for nextToken

  do {
    const interviewEvaluationsResponse = await API.graphql(
      graphqlOperation(listInterviewEvaluations, {
        filter: {
          candidateEmail: { eq: email },
        },
        limit: 100, // Adjust the limit as per your requirement
        nextToken: currentNextToken, // Use currentNextToken here
      })
    );

    interviewEvaluationsItems = [
      ...interviewEvaluationsItems,
      ...interviewEvaluationsResponse.data.listInterviewEvaluations.items,
    ];

    currentNextToken = interviewEvaluationsResponse.data.listInterviewEvaluations.nextToken;
  } while (currentNextToken);

  setInterviewEvaluations(interviewEvaluationsItems);
  // setCandidateId(interviewEvaluationsItems[0].id)
  if(interviewEvaluationsItems.length > 0  && interviewEvaluationsItems[0].id )
  {
    setCandidateId(interviewEvaluationsItems[0].id)
  }else{
    alert('Profile is Not Available');
  }
  setNextToken(null); // Reset nextToken using setNextToken
};

  // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",interviewEvaluations)
  // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",email)

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setVideoFile(file);
  };

  const update = async (e) => {

    e.preventDefault();

    setUploading(true)

    if (!email.trim()) {
      throw alert('Email cannot be empty.');
    }
    if (!videoFile) {
      throw alert('Please select a file to upload.');
    }
         
    console.log(videoFile.name)
    console.log(videoFile)
    const yourS3BucketName = "getscreenedstoragebucketf24ed-devget";
    const folderPath = 'InterviewVideo/';
    try {
       
      // let videoURL = "33333333333333333333333333333333";
      

      let videoURL = null;
      if (videoFile) {
        const videoKey = await Storage.put(
          // original code start
          // videoFile.name,
          // original code end
          `${folderPath}${videoFile.name}`, // Adjusted to include folder path
          videoFile,
          {
            contentType: "video/*",
          }
        );
        // original code start
        // videoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;
        // original code end

        videoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${videoKey.key}`;
 
        
      }



      // console.log("hhhhhhhhhhhhhhhhhhhhhh",candidateId)
      // console.log("oooooooooooooooooooooo",interviewEvaluations.videoURL)

      const input = {
        id: candidateId,
        videoURL: videoURL, // or whatever the URL is
      };

      const response = await API.graphql(graphqlOperation(updateInterviewEvaluation, { 

        input

       }));

       console.log(response)


      window.location.reload();
      
    } catch (error) {
      
    }

    // setUploading(true)
    // console.log("[][][[][][][][][][][]][][][]]]][][][]",videoFile)
    // setUploading(false)
    
  }



  return (


    // Testing code 1 start
    <div>
      <Header/>
    <div className="flex justify-center items-center h-screen ">
      <div className="bg-transparent p-6 rounded shadow-md w-full sm:w-2/3 md:w-1/2 lg:w-1/3 xl:w-1/4 border">
        <h2 className="text-xl font-bold mb-4 text-center">Update Profile</h2>
        {/* <h1 className='text-red-700'>Don't use this feature its Not ready</h1> */}
        <h5>Update Video</h5>

          <label className="block mb-4">
            Email:
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="block w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 text-black"
              // required
            />
          </label>
          <button onClick={fetchInterviewEvaluations} className='bg-red-700 px-3 py-2  rounded-lg text-white'>checkEmail</button>
          {/* start that button above code code wants to run */}
          {
            interviewEvaluations.length > 0 ?  (
              <div>
              <label className="block mb-4">
            Upload Video:
            <input
              type="file"
              accept="video/*"
              onChange={handleFileChange}
              className="block w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              // required
            />
          </label>
          <button
            // type="submit"
            onClick={update}
            disabled={uploading}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full"
          >
            {uploading ? 'Uploading...' : 'Update Profile'}
          </button> 
          </div>
            ) : (<h1></h1>)
          }
   
      </div>
    </div>
    </div>

    //  Testing code 1 end

  )
}

export default UpdateCandidate
